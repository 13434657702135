import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  exportCourseTraineeStatistics,
  getCourseTraineeStatistics,
} from "../../../../../Services/api/courses/courseProvider";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import FilterIcon from "../../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../../assets/icons/home.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";

export default function CourseTraineeStatement({ role }) {
  require("./List.css");

  const { t } = useTranslation();
  const { courseId, id } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [trainees, setTrainees] = useState([]);
  const [course, setCourse] = useState([]);
  const [user, setUser] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [contents, setContents] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  function getFilter() {
    const keys = Object.keys(filter);
    let currentFilter = { ...filter };

    if (currentFilter.grade != undefined && currentFilter.grade != null) {
      const parseValue = JSON.parse(currentFilter.grade);
      currentFilter = {
        ...currentFilter,
        min_grade: parseValue.min,
        max_grade: parseValue.max,
      };

      delete currentFilter.grade;
    }

    function boolToInt(value) {
      return value === "true" ? 1 : value === "false" ? 0 : null;
    }

    return currentFilter;
  }

  const exportHandler = async (type) => {
    try {
      setIsUpdating(true);
      await exportCourseTraineeStatistics(type, courseId, id, getFilter());
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const actionsList = [
    // {
    //   id: "filter",
    //   icon: <img src={FilterIcon} alt="" />,
    //   title: t("filter"),
    //   action: () => {
    //     setIsFilter((val) => !val);
    //   },
    // },
    ...(role !== "partner"
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            title: t(`${t("trainer.quiz.export")} PDF`),
            action: () => exportHandler("pdf"),
          },
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            title: t(`${t("trainer.quiz.export")} Excel`),
            action: () => exportHandler("xlsx"),
          },
        ]
      : []),
  ];

  const columns = (withMark = false, include_view_per = true, include_achivement_per = true) => [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "title",
      name: t("title"),
      key: "title",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.title}</span>,
    },
    {
      id: "chapter",
      name: t("chapter"),
      key: "chapter",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.chapter?.name}</span>,
    },
    {
      id: "subChapter",
      name: t("subChapter"),
      key: "subChapter",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.subChapter?.name}</span>,
    },
    {
      id: "lesson",
      name: t("lesson"),
      key: "lesson",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.lesson?.title}</span>,
    },
    ...(include_view_per
      ? [
          {
            id: "include_view_per",
            name: t("watchingProgressTwo"),
            key: "include_view_per",
            sortable: false,
            cell: ({ rowData }) => (
              <span
                className={`cell-font tw-p-1 tw-rounded ${
                  rowData.watchStatus == 1
                    ? "tw-text-orange-400 tw-bg-orange-400/10"
                    : rowData.watchStatus == 2
                    ? "tw-text-red-400 tw-bg-red-400/10"
                    : "tw-text-gray-400 tw-bg-gray-400/10"
                }`}
              >
                {rowData.watchStatus == 1
                  ? t("trainer.course.lesson.iconCheck")
                  : rowData.watchStatus == 2
                  ? t("trainer.course.lesson.iconNotCheck")
                  : t("not_include_view_per")}
              </span>
            ),
          },
        ]
      : []),
    ...(include_achivement_per
      ? [
          {
            id: "include_achivement_per",
            name: t("achivementProgress"),
            key: "include_achivement_per",
            sortable: false,
            cell: ({ rowData }) => (
              <span
                className={`cell-font tw-p-1 tw-rounded ${
                  rowData.progressStatus == 1
                    ? "tw-text-teal-400 tw-bg-teal-400/10"
                    : rowData.progressStatus == 2
                    ? "tw-text-red-400 tw-bg-red-400/10"
                    : "tw-text-gray-400 tw-bg-gray-400/10"
                }`}
              >
                {rowData.progressStatus == 1
                  ? t("achived")
                  : rowData.progressStatus == 2
                  ? t("not_achived")
                  : t("not_include_achivement_per")}
              </span>
            ),
          },
        ]
      : []),
    ...(withMark
      ? [
          {
            id: "mark",
            name: t("mark"),
            key: "mark",
            sortable: false,
            cell: ({ rowData }) => (
              <span className="cell-font">
                {rowData.mark == undefined || rowData.mark == null ? "-" : `${rowData.mark} (${rowData.percentage}%)`}
              </span>
            ),
          },
        ]
      : []),
  ];

  const filterFields = [
    // name
    ...(role == "trainee"
      ? []
      : [
          {
            id: "name",
            component: "input",
            type: "text",
            placeholder: t("name"),
            label: t("name"),
            name: "name",
            value: filter.name ?? "",
            classes: "col-12 col-md-4",
            onReset: () => filterChangeHandler({ target: { value: "", name: "name" } }),
            onchange: filterChangeHandler,
          },
          // email
          {
            id: "email",
            component: "input",
            type: "text",
            placeholder: t("email"),
            label: t("email"),
            name: "email",
            value: filter.email ?? "",
            classes: "col-12 col-md-4",
            onReset: () => filterChangeHandler({ target: { value: "", name: "email" } }),
            onchange: filterChangeHandler,
          },
          // hide_empty
          {
            id: "hide_empty",
            component: "select",
            placeholder: t("hide_records_by"),
            label: t("hide_records_by"),
            initialValue: t("hide_records_by"),
            name: "hide_empty",
            value: filter.hide_empty ?? "",
            classes: "col-12 col-md-2",
            onchange: filterChangeHandler,
            options: [
              { value: 1, label: t("empty_records") },
              { value: 2, label: t("student_grade") },
            ],
          },
        ]),
    // grade
    {
      id: "grade",
      component: "select",
      placeholder: t("student_grade"),
      label: t("student_grade"),
      initialValue: t("student_grade"),
      name: "grade",
      value: filter.grade ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 60, min: 51 }),
          label: "51 - 60",
        },
        {
          id: "7",
          value: JSON.stringify({ max: 70, min: 61 }),
          label: "61 - 70",
        },
        {
          id: "8",
          value: JSON.stringify({ max: 80, min: 71 }),
          label: "71 - 80",
        },
        {
          id: "9",
          value: JSON.stringify({ max: 90, min: 81 }),
          label: "81 - 90",
        },
        {
          id: "10",
          value: JSON.stringify({ max: 100, min: 91 }),
          label: "91 - 100",
        },
      ],
    },
    // quiz_title
    {
      id: "quiz_title",
      component: "input",
      type: "text",
      placeholder: t("quiz_title"),
      label: t("quiz_title"),
      name: "quiz_title",
      value: filter.quiz_title ?? "",
      classes: "col-12 col-md-4",
      onReset: () => filterChangeHandler({ target: { value: "", name: "quiz_title" } }),
      onchange: filterChangeHandler,
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setFilter({});
        setFilterPayload({});
        if (currentPage == 1) {
          refetchQuiz({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        setFilterPayload(getFilter());
        if (currentPage == 1) {
          refetchQuiz();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchStatement = (params = getFilter()) => {
    params = {
      ...params,
      perPage: 10,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let res = await getCourseTraineeStatistics(courseId, id);
        if (res.status == 200) {
          setCourse(res.data.data.course);
          setUser(res.data.data.user);
          setQuizzes(res.data.data.quizzes);
          setActivities(res.data.data.activities);
          setContents(res.data.data.contents);
          setSurveys(res.data.data.surveys);
          setSessions(res.data.data.sessions);
          setDiscussions(res.data.data.discussions);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  const refetchQuiz = async (params = getFilter()) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        page: currentPage,
      };
      await fetchStatement(customFilter);
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    refetchQuiz();
  }, [currentPage, order]);

  const breadcrumbList =
    role == "trainee"
      ? [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
          {
            id: t("Courseoutline"),
            page: t("Courseoutline"),
            pagePath: `/training-courses/${courseId}/content`,
          },
          {
            id: t("quiz_scores"),
            page: t("quiz_scores"),
            active: true,
          },
        ]
      : [
          ...(role == "admin"
            ? [
                {
                  id: t("admin.label.admin_label"),
                  page: t("admin.label.admin_label"),
                  pagePath: "/admin",
                },
              ]
            : [
                {
                  id: "home",
                  page: <img src={HomeIcon} alt="" />,
                  pagePath: "/",
                },
              ]),
          {
            id: t("sidebar.label.courses"),
            page: t("sidebar.label.courses"),
            pagePath: `/${role}/courses`,
          },
          {
            id: t("Courseoutline"),
            page: t("Courseoutline"),
            pagePath: `/${role}/course/${courseId}`,
          },
          {
            id: "departments-trainers",
            page: t(`department_trainees.${"course_trainees"}`),
            pagePath: `/${role}/course/${courseId}/trainees`,
          },
          {
            id: t("trainer.quiz.exams"),
            page: user?.name,
            active: true,
          },
        ];

  return (
    <>
      <div className="tw-ms-auto"></div>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8">
            <MainBox>
              <MainBoxHead title={user.name} actions={actionsList} />
              {/* {isFilter && <FilterBox fields={filterFields} />} */}
              <div>
                <div className="tw-mb-2 tw-font-bold tw-text-gray-600">{"المحتوى التشاركي"}</div>
                <MainTable
                  columns={columns(true)}
                  data={discussions.map((i) => ({
                    ...i,
                    watchStatus: i.include_view_per ? (user.discussions_watches.includes(i.id) ? 1 : 2) : 3,
                    progressStatus: i.include_achivement_per ? (user.discussions_progress.includes(i.id) ? 1 : 2) : 3,
                    mark: user.discussions_degrees?.find((ans) => ans.discussion_id == i.id)?.mark,
                    percentage: i.total_mark
                      ? Math.round(
                          (user.discussions_degrees?.find((ans) => ans.discussion_id == i.id)?.mark / i.total_mark) * 10000
                        ) / 100
                      : 100,
                  }))}
                  sortAction={sortActionHandler}
                />
              </div>
              <div>
                <div className="tw-mb-2 tw-font-bold tw-text-gray-600">{"الاختبارات"}</div>
                <MainTable
                  columns={columns(true)}
                  data={quizzes.map((i) => ({
                    ...i,
                    watchStatus: i.include_view_per ? (user.quizzes_watches.includes(i.id) ? 1 : 2) : 3,
                    progressStatus: i.include_achivement_per ? (user.quizzes_progress.includes(i.id) ? 1 : 2) : 3,
                    mark: user.quizzes_answers?.find((ans) => ans.quiz_id == i.id)?.mark,
                    percentage: i.total_mark
                      ? Math.round(
                          (user.quizzes_answers?.find((ans) => ans.quiz_id == i.id)?.mark / i.total_mark) * 10000
                        ) / 100
                      : 100,
                  }))}
                  sortAction={sortActionHandler}
                />
              </div>
              <div>
                <div className="tw-mb-2 tw-font-bold tw-text-gray-600">{"الانشطة"}</div>
                <MainTable
                  columns={columns(true)}
                  data={activities.map((i) => ({
                    ...i,
                    watchStatus: i.include_view_per ? (user.activities_watches.includes(i.id) ? 1 : 2) : 3,
                    progressStatus: i.include_achivement_per ? (user.activities_progress.includes(i.id) ? 1 : 2) : 3,
                    mark: user.activities_answers?.find((ans) => ans.activity_id == i.id)?.mark,
                    percentage: i.total_mark
                      ? Math.round(
                          (user.activities_answers?.find((ans) => ans.activity_id == i.id)?.mark / i.total_mark) * 10000
                        ) / 100
                      : 100,
                  }))}
                  sortAction={sortActionHandler}
                />
              </div>
              <div>
                <div className="tw-mb-2 tw-font-bold tw-text-gray-600">{"محتوى الدرس"}</div>
                <MainTable
                  columns={columns()}
                  data={contents.map((i) => ({
                    ...i,
                    watchStatus: i.include_view_per ? (user.contents_watches.includes(i.id) ? 1 : 2) : 3,
                    progressStatus: i.include_achivement_per ? (user.contents_progress.includes(i.id) ? 1 : 2) : 3,
                  }))}
                  sortAction={sortActionHandler}
                />
              </div>
              <div>
                <div className="tw-mb-2 tw-font-bold tw-text-gray-600">{"الاستبيانات"}</div>
                <MainTable
                  columns={columns()}
                  data={surveys.map((i) => ({
                    ...i,
                    watchStatus: i.include_view_per ? (user.surveys_watches.includes(i.id) ? 1 : 2) : 3,
                    progressStatus: i.include_achivement_per ? (user.surveys_progress.includes(i.id) ? 1 : 2) : 3,
                  }))}
                  sortAction={sortActionHandler}
                />
              </div>
              <div>
                <div className="tw-mb-2 tw-font-bold tw-text-gray-600">{"الجلسات"}</div>
                <MainTable
                  columns={columns()}
                  data={sessions.map((i) => ({
                    ...i,
                    watchStatus: i.include_view_per ? (user.sessions_watches.includes(i.id) ? 1 : 2) : 3,
                    progressStatus: i.include_achivement_per ? (user.sessions_progress.includes(i.id) ? 1 : 2) : 3,
                  }))}
                  sortAction={sortActionHandler}
                />
              </div>
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
