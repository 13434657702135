import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useParams } from "react-router";
import SunEditor from "suneditor-react";
import CustomButton from "../../../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../../../Shared/Components/Input/Input";
import InputFile from "../../../../../../Shared/Components/InputFile/InputFile";
import classes from "./activities.module.css";

export default function CommentForm({ handleClose, isComment, addCommentHandler, commentId, discussionId, userId }) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [packageData, setPackageData] = useState("");
  const attachmentPanel = useRef(null);

  const onAttachmentPanelHoverStart = (event) => {
    attachmentPanel.current.classList.add("attachment-panel-dragging-activation");
  };

  const onAttachmentPanelHoverEnd = (event) => {
    attachmentPanel.current.classList.remove("attachment-panel-dragging-activation");
  };

  const onAttachmentPanelHoverDropped = (event) => {
    event.preventDefault();
    let file = event.dataTransfer.files.item(0);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPackageData(file);
      attachmentPanel.current.classList.remove("attachment-panel-dragging-activation");
    };
    reader.onerror = function (error) {
      attachmentPanel.current.classList.remove("attachment-panel-dragging-activation");
    };
  };

  const onAttachmentPanelHoverOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  useEffect(() => {
    if (attachmentPanel.current != null) {
      attachmentPanel.current.addEventListener("dragenter", onAttachmentPanelHoverStart);
      attachmentPanel.current.addEventListener("dragleave", onAttachmentPanelHoverEnd);
      attachmentPanel.current.addEventListener("drop", onAttachmentPanelHoverDropped);
      attachmentPanel.current.addEventListener("dragover", onAttachmentPanelHoverOver);
    }

    return () => {
      if (attachmentPanel.current != null) {
        attachmentPanel.current.removeEventListener("dragenter", onAttachmentPanelHoverStart);
        attachmentPanel.current.removeEventListener("dragleave", onAttachmentPanelHoverEnd);
        attachmentPanel.current.removeEventListener("drop", onAttachmentPanelHoverDropped);
        attachmentPanel.current.removeEventListener("dragover", onAttachmentPanelHoverOver);
      }
    };
  }, [userId]);
  return (
    <>
      <div
        style={{
          // borderTop: "0.5px solid  rgb(229 231 235 / var(--tw-border-opacity))",
          paddingLeft: 20,
          paddingRight: 20,
          marginBottom: 30,
        }}
      >
        <Formik
          initialValues={{
            comment: "",
            file: packageData,
            interactive_content_id: discussionId ? discussionId : id,
            for_user: userId ? userId : null,
            is_private: userId ? 1 : 0,
            ...(!isComment ? { parent_id: commentId } : {}),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            let formData = new FormData();
            Object.keys(values).forEach((field) => {
              if (field === "file") {
                values[field].file && formData.append("file", values[field].file);
              } else {
                formData.append(field, values[field]);
              }
            });
            if (values.for_user === null) {
              formData.delete("for_user");
            }

            try {
              addCommentHandler(formData);
              handleClose();
              // await lessonContentContext.addContent(formData); /// add Comment function
              // await lessonContentContext.fetchLessonContent(id); /// fetch comments after adding
            } catch (error) {
              setSubmitting(false);
              return;
            }
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};

            if (!values.comment) {
              errors.comment = t("crud.errors.required");
            }

            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes["comment_form"]}>
                <div>
                  <label htmlFor="comment">
                    {isComment
                      ? t("trainer.course.lesson.activity.comment")
                      : userId === null
                      ? t("trainer.course.lesson.activity.replay")
                      : t("private_replay")}
                  </label>
                  <SunEditor
                    value={values.comment}
                    defaultValue={values.comment}
                    name="comment"
                    onChange={(comment) => {
                      setFieldValue("comment", comment);
                    }}
                    setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                    setOptions={{
                      height: 200,
                      rtl: i18n.language === "ar" ? true : false,
                      buttonList: [
                        ["removeFormat", "codeView"],
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["link", "audio"],
                      ],
                    }}
                  />
                </div>
                <InputFile
                  name="file"
                  type="document"
                  documentWithoutTitle={true}
                  accept={
                    "application/pdf,.doc,.docx,application/msword,application/vnd.ms-excel,image/*,video/*,audio/*"
                  }
                  label={t("trainer.course.lesson.attachment")}
                  placeholder={t("trainer.course.lesson.attachment")}
                  setFieldValue={setFieldValue}
                  value={values.file}
                  error={errors.file}
                />
              </div>
              <div className={classes["action_button_comment"]}>
                <CustomButton
                  type="submit"
                  value={
                    isComment
                      ? t("trainer.course.lesson.activity.add_comment_button")
                      : t("trainer.course.lesson.activity.add_replay")
                  }
                  loading={isSubmitting}
                  disable={isSubmitting}
                  colors="#036c77"
                />
                {/* <CustomButton
                  action={handleClose}
                  value={t("cancel")}
                  loading={isSubmitting}
                  disable={isSubmitting}
                  colors="#fff"
                /> */}
                <button
                  className={classes["cancel_button"]}
                  style={{
                    backgroundColor: "#fff",
                    color: "#006d77",
                    border: "1px solid #006d77",
                  }}
                  onClick={handleClose}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
