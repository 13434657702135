import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  approveExhibitionByVisitor,
  rejectExhibitionByVisitor,
} from "../../../../Redux/Actions/exhibition/exhibition.action";
import ConfirmationModal from "../../../Shared/Components/ConfirmationModal/ConfirmationModal";
import ContentLoadingSpinner from "../../../Shared/Components/ContentLoadingSpinner/ContentLoadingSpinner";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import classes from "./exhibitionDetails.module.css";
const UsersTable = ({
  setShowPaymentModal,
  setCurrentPage,
  sortAction,
  exhibitionId,
  bankTransferActionHandler,
  activeTab,
  mode,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonError, setCancelReasonError] = useState("");

  const {
    visitorsLoading,
    visitorsError,
    visitorsMeta,
    approveVisitorLoading,
    approveVisitorError,
    approveVisitorMsg,
    approveVisitor,
    visitors,
    rejectVisitorLoading,
    rejectVisitorError,
    rejectVisitorMsg,
    rejectVisitor,
  } = useSelector((state) => state.exhibitionReducer);

  const approveVisitorHandler = (id, order) => {
    const paymentStatus = Number(order.status) === 1 ? "approved" : Number(order.status) === 2 ? "rejected" : "pending";
    if (approveVisitorLoading) return;
    if (order.payment_method === "Bank" || order.payment_method === "البنك") {
      if (paymentStatus === "rejected") {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("Exhibition.errors.rejected_payment_cannot_approve_visitor")}
          </span>,
          {
            autoClose: 5000,
          }
        );
        return;
      }
      if (paymentStatus === "pending") {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("Exhibition.errors.approve_payment_before_approve_visitor")}
          </span>,
          {
            autoClose: 5000,
          }
        );
        return;
      }
    }
    setSelectedRow(id);
    dispatch(
      approveExhibitionByVisitor({
        exhibitionId: exhibitionId,
        visitorId: id,
      })
    );
  };

  const rejectVisitorHandler = (id, order) => {
    const paymentStatus = Number(order.status) === 1 ? "approved" : Number(order.status) === 2 ? "rejected" : "pending";
    if (order.payment_method === "Bank" || order.payment_method === "البنك") {
      if (paymentStatus === "approved") {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("Exhibition.errors.approved_payment_cannot_reject_visitor")}
          </span>,
          {
            autoClose: 5000,
          }
        );
        return;
      }
      if (paymentStatus === "pending") {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("Exhibition.errors.reject_payment_before_reject_visitor")}
          </span>,
          {
            autoClose: 5000,
          }
        );
        return;
      }
    }
    setSelectedRow(id);
    setShowRejectConfirmationModal(true);
  };

  const confirmRejectHandler = () => {
    if (!cancelReason || cancelReasonError) return setCancelReasonError(t("crud.errors.required"));
    dispatch(
      rejectExhibitionByVisitor({
        exhibitionId: exhibitionId,
        visitorId: selectedRow,
        cancelReason,
      })
    );
  };

  useEffect(() => {
    if (!approveVisitor) return;
    toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{approveVisitorMsg}</span>, {
      autoClose: 1000,
    });
  }, [approveVisitor, approveVisitorMsg]);

  useEffect(() => {
    if (!rejectVisitor) return;

    toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{rejectVisitorMsg}</span>, {
      autoClose: 1000,
    });
    setShowRejectConfirmationModal(false);
  }, [rejectVisitor, rejectVisitorMsg]);

  const paymentDisplay = {
    Bank: t("Exhibition.popuppaymentbanktran"),
    البنك: t("Exhibition.popuppaymentbanktran"),
    Wallet: t("Exhibition.popuppaymentwallet"),
    محفظة: t("Exhibition.popuppaymentwallet"),
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },

    {
      id: "photo",
      name: t("Exhibition.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["exhibition-details__table-photo"]}>
          {rowData.user_photo ? (
            <img src={rowData.user_photo} alt="" />
          ) : (
            <i className="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
    },
    {
      id: "user_name",
      name: t("Exhibition.user_name"),
      key: "user_name",
      cell: ({ rowData }) => <>{rowData.name}</>,
      sortable: false,
    },
    {
      id: "email",
      name: t("Exhibition.email"),
      key: "email",
      cell: ({ rowData }) => <>{rowData.email}</>,
      sortable: false,
    },
    {
      id: "phone",
      name: t("Exhibition.phone"),
      key: "phone",
      cell: ({ rowData }) => <>{rowData.mobile === "null" ? "-" : rowData.mobile}</>,
      sortable: false,
    },
    {
      id: "order_date",
      name: t("Exhibition.order_date"),
      key: "order_date",
      cell: ({ rowData }) => <>{rowData?.order_dat ? rowData?.order_dat : "-"}</>,
      sortable: false,
    },
    ...(activeTab === 2
      ? [
          {
            id: "user_rate",
            name: t("Exhibition.rating"),
            key: "user_rate",
            cell: ({ rowData }) => (
              <StarsRating rating={rowData.user_rate || 0} ratingComment={rowData.user_rate_comment} />
            ),
            sortable: false,
          },
        ]
      : []),
    ...(activeTab === 3
      ? [
          {
            id: "reject_reason",
            name: t("Exhibition.reject_reason"),
            key: "reject_reason",
            cell: ({ rowData }) => (
              <div className={classes["user-table__table-description"]}>{rowData?.cancel_reason || "-"}</div>
            ),
            sortable: false,
          },
        ]
      : []),
    ...(mode !== "Free"
      ? [
          {
            id: "payment_method",
            name: t("Exhibition.payment_method"),
            key: "payment_method",
            cell: ({ rowData }) => (
              <div className={classes["exhibition-details__table-payment-method"]}>
                {rowData?.payment_method === "Bank" || rowData?.payment_method === "البنك" ? (
                  <button
                    className={classes["exhibition-details__table-payment-method--banktransfer"]}
                    onClick={() => {
                      bankTransferActionHandler(rowData?.payment);
                    }}
                  >
                    {paymentDisplay[rowData?.payment_method] || "-"}
                  </button>
                ) : (
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    {paymentDisplay[rowData?.payment_method] || "-"}
                  </p>
                )}
              </div>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  return (
    <div className={classes["exhibition-details__tables-users"]}>
      <MainTable columns={columns} data={visitors} isLoading={visitorsLoading} sortAction={sortAction} />
      <Pagination
        count={visitorsMeta?.totalPages || 0}
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="main-pagination"
        onChange={(e, page) => {
          setCurrentPage(page);
        }}
      />
      {showRejectConfirmationModal && (
        <ConfirmationModal
          closeModal={() => {
            setShowRejectConfirmationModal(false);
          }}
          action={() => {
            confirmRejectHandler();
          }}
          type={"reject"}
          onChange={(e) => {
            setCancelReason(e.target.value);

            setCancelReasonError((prev) => {
              return !e.target.value.trim() ? t("crud.errors.required") : "";
            });
          }}
          isLoading={rejectVisitorLoading}
          error={cancelReasonError}
        />
      )}
    </div>
  );
};

export default UsersTable;
