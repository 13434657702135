import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../Services/Timezone";
import {
  getAssessments,
  openExternalLinkApi,
} from "../../../Services/api/exams/ExamsProvider";
import ArrowIcon from "../../../assets/icons/arrow.png";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./ListExams.module.css";

export default function AssessmentsList({ quizable }) {
  require("./List.css");

  const { t } = useTranslation();
  const history = useHistory();
  const { courseId, quizableId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [quizzes, setQuizzes] = useState([]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>
      ),
    },
    // title
    {
      id: "answer_attempts",
      name: t("attempts_number"),
      key: "answer_attempts",
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">
          {rowData.answers_count}/{rowData.answer_attempts}
        </span>
      ),
    },
    // title
    {
      id: "title",
      name: t("quiz_title"),
      key: "title",
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">{rowData.title}</span>
      ),
    },
    // include_achivement_per
    {
      id: "include_achivement_per",
      name: t("include_achivement_per"),
      key: "include_achivement_per",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.include_achivement_per ? "#c6955e" : "#8a9db9",
            backgroundColor: rowData.include_achivement_per
              ? "#f7f0e8"
              : "#eff2f6",
          }}
        >
          {t(rowData.include_achivement_per ? t("yes") : t("no"))}
        </span>
      ),
    },
    // include_view_per
    {
      id: "include_view_per",
      name: t("include_view_per"),
      key: "include_view_per",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.include_view_per ? "#c6955e" : "#8a9db9",
            backgroundColor: rowData.include_view_per ? "#f7f0e8" : "#eff2f6",
          }}
        >
          {t(rowData.include_view_per ? t("yes") : t("no"))}
        </span>
      ),
    },
    // is watched
    {
      id: "is_watched",
      name: t("is_watched"),
      key: "is_watched",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.is_watched ? "#c6955e" : "#8a9db9",
            backgroundColor: rowData.is_watched ? "#f7f0e8" : "#eff2f6",
          }}
        >
          {t(rowData.is_watched ? t("yes") : t("no"))}
        </span>
      ),
    },
    // is_my_answer_corrected
    {
      id: "is_my_answer_corrected",
      name: t("quiz_grading"),
      key: "is_my_answer_corrected",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.is_my_answer_corrected ? "#c6955e" : "#8a9db9",
            backgroundColor: rowData.is_my_answer_corrected
              ? "#f7f0e8"
              : "#eff2f6",
          }}
        >
          {t(
            rowData.is_my_answer_corrected ? t("completed") : t("not_completed")
          )}
        </span>
      ),
    },
    // questions_count
    {
      id: "questions_count",
      name: t("qustion_number"),
      key: "questions_count",
      cell: ({ rowData }) => (
        <span className="cell-font tw-whitespace-nowrap">
          {rowData.monitored ? t("unknown") : rowData.questions_count}
        </span>
      ),
    },
    // success_rate
    {
      id: "success_rate",
      name: t("pass_percentage"),
      key: "success_rate",
      cell: ({ rowData }) => (
        <span className="cell-font tw-whitespace-nowrap">
          {rowData.success_rate}%
        </span>
      ),
    },
    // start_date
    {
      id: "start_date",
      name: t("start_date"),
      key: "start_date",
      sortable: false,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.start_date ? (
            <>
              <div>
                {moment(toLocalTimezone(rowData.start_date)).format(
                  "DD/MM/YYYY"
                )}
              </div>
              <div>
                {moment(toLocalTimezone(rowData.start_date)).format("hh:mm A")}
              </div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // end_date
    {
      id: "end_date",
      name: t("end_date_quiz"),
      key: "end_date",
      sortable: false,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.end_date ? (
            <>
              <div>
                {moment(toLocalTimezone(rowData.end_date)).format("DD/MM/YYYY")}
              </div>
              <div>
                {moment(toLocalTimezone(rowData.end_date)).format("hh:mm A")}
              </div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // actions
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <>
          <div className="tw-flex tw-flex-col tw-items-stretch tw-space-y-2">
            <button
              type="button"
              disabled={
                parseInt(rowData.answers_count) >=
                  parseInt(rowData.answer_attempts) ||
                new Date(toLocalTimezone(rowData.start_date)) > new Date() ||
                new Date(toLocalTimezone(rowData.end_date)) < new Date()
              }
              onClick={
                rowData.monitored
                  ? openExternalLink
                  : () => {
                      history.push({
                        pathname: `/trainees/course/${courseId}/${quizable}/${quizableId}/exam/${rowData.id}`,
                        state: {
                          supChapterId: location?.state?.supChapterId,
                          chapterId: location?.state?.chapterId,
                        },
                      });
                    }
              }
              className={`tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-text-white tw-py-2 tw-px-8 tw-rounded `}
            >
              {t("enter_the_exam")}
            </button>
            <button
              type="button"
              disabled={
                !(
                  parseInt(rowData.answers_count) &&
                  rowData.is_my_answer_corrected &&
                  rowData.can_show_mark
                ) || rowData.monitored
              }
              onClick={() => {
                history.push({
                  pathname: `/trainees/course/${courseId}/${quizable}/${quizableId}/exam/${rowData.id}/result`,
                  state: {
                    supChapterId: location?.state?.supChapterId,
                    chapterId: location?.state?.chapterId,
                  },
                });
              }}
              className={`tw-bg-white tw-border-2 tw-border-teal-700 tw-text-teal-700 disabled:tw-border-teal-700/50 disabled:tw-text-teal-700/50 tw-py-2 tw-px-8 tw-rounded`}
            >
              {t("see_exam_result")}
            </button>
          </div>
        </>
      ),
    },
  ];

  const fetchQuiz = (param = {}) => {
    let params = {
      ...param,
      perPage: 10,
      quizable_type: quizable,
      quizable_id: quizableId,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getAssessments(courseId, params);
        if (response.status == 200) {
          setQuizzes(response.data.data.quizzes);
          setMeta(response.data.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const refetchQuiz = async () => {
    try {
      let customFilter = {
        page: currentPage,
      };
      await fetchQuiz(customFilter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const openExternalLink = async () => {
    try {
      let response = await openExternalLinkApi();
      if (response.status == 200) {
        window.open(response.data.url, "_blank");
      }
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    refetchQuiz();
  }, [currentPage]);

  // const breadcrumbList = [
  //   {
  //     id: "home",
  //     page: <img src={HomeIcon} alt="" />,
  //     pagePath: "/",
  //   },
  //   {
  //     id: t("Courseoutline"),
  //     page: t("Courseoutline"),
  //     pagePath:
  //       quizable == "chapter"
  //         ? `/training-courses/${courseId}/content`
  //         : `/training-courses/${courseId}/content/${location.state.chapterId}/${location.state.supChapterId}/lessons`,
  //   },
  //   {
  //     id: t("trainer.quiz.exams"),
  //     page: t("trainer.quiz.exams"),
  //     active: true,
  //   },
  // ];

  return (
    <>
      <div className="tw-ms-auto"></div>
      <div
        className="container-fluid"
        id={"content-section"}
        // style={{ marginTop: "2rem" }}
      >
        {/* <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div> */}
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8 tw-mt-8">
            <MainBox>
              <div className={classes["back"]} onClick={() => history.goBack()}>
                <div className={classes["back-icon"]}>
                  <img src={ArrowIcon} alt="back" />
                </div>
                <div className={classes["back-text"]}>{t("back")}</div>
              </div>
              <MainBoxHead title={t("trainer.quiz.exams")} actions={[]} />
              <MainTable
                columns={columns}
                data={quizzes}
                sortAction={() => {}}
              />
              <Pagination
                count={meta.totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
