import React, { useEffect, useState } from "react";
import { Bar, Doughnut, Scatter } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QuestionTypeEnum, { QuestionTypeWithOptions } from "../../../../Enums/QuestionTypeEnum";
import { exportTraineeExamAnswers, getTraineeExamAnswers } from "../../../../Services/api/exams/ExamsProvider";
import { exportSurveysStatisticsQuestions } from "../../../../Services/api/survey/SurveyProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./index.module.css";

import { Chart as ChartJS, PointElement } from "chart.js";
ChartJS.register(PointElement);
const colors = [
  "#2e3e5f",
  "#24b3b9",
  "#8599b6",
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#2C73D2",
  "#008E9B",
  "#008F7A",
  "#B39CD0",
  "#FBEAFF",
  "#C34A36",
];

const pickColor = (index) => {
  if (colors.length > index) {
    return colors[index];
  } else {
    return "#FCF8FF";
  }
};

export default function AssessmentStatistics({ role, quizable }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [quiz, setQuiz] = useState({});
  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [levels, setLevels] = useState([]);
  const [yList, setYList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const { courseId, quizableId, quizId } = useParams();

  const generalOptions = {
    indexAxis: "x",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          precision: 0,
        },
        grid: {
          borderWidth: "rgba(0, 0, 0, 0)",
        },
      },
      y: {
        stacked: false,
        ticks: {
          precision: 0,
        },
        grid: {
          borderWidth: "rgba(0, 0, 0, 0)",
        },
      },
    },
  };

  const getOptions = (question, chart) => {
    return {
      ...generalOptions,
      indexAxis: yList.includes(question.id) ? "y" : "x",
      plugins: {
        ...generalOptions.plugins,
        legend: {
          ...generalOptions.plugins.legend,
          position: yList.includes(question.id) ? "right" : "bottom",
        },
      },
      scales: {
        ...generalOptions.scales,
        x: {
          ...generalOptions.scales.x,
          ticks: {
            ...generalOptions.scales.x.ticks,
            callback: function (value, index, ticks_array) {
              let characterLimit = 12;
              let label = question.chart.labels[value];
              if (label.length >= characterLimit) {
                return (
                  label
                    .slice(0, label.length)
                    .substring(0, characterLimit - 1)
                    .trim() + "..."
                );
              }
              return label;
            },
          },
        },
      },
    };
  };

  const encode1DQuestion = (question) => {
    var chart = {};
    var labels = [];
    var datasets = [];
    question = {...question, options: question.options?.sort((a, b) => a.text - b.text)}
    // prepare labels
    question.options.map((option, index) => {
      labels.push(option.text);
    });

    // datasets
    if (question.type_id == QuestionTypeEnum.ordering) {
      // prepare datasets for ordering values
      for (var i = 0; i < question.options.length; i++) {
        datasets.push({
          label: i + 1,
          backgroundColor: pickColor(i),
          data: question.options.map((option) => {
            return question.answers.filter(
              (answer) =>
                !!answer.answers.find((ans) => parseInt(ans.option_id) == option.id && parseInt(ans.answer) == i)
            ).length;
          }),
        });
      }
      chart = {
        label: "",
        datasets,
        labels,
      };
    } else if(question.type_id == QuestionTypeEnum.drag_and_drop) {
      // prepare datasets for ordering values
      for (var i = 0; i < question.options.length; i++) {
        datasets.push({
          label: i + 1,
          backgroundColor: pickColor(i),
          data: question.options.map((option) => {
            return question.answers.filter(
              (answer) =>
                !!answer.answers.find((ans) => parseInt(ans.option_id) == option.id && ans.answer == question.options[i].image)
            ).length;
          }),
        });
      }
      chart = {
        label: "",
        datasets,
        labels,
      };
    } else {
      var data = [];
      question.options.map((option) => {
        data.push(
          question.answers.filter((answer) => answer.answers.find((ans) => parseInt(ans.answer) == option.id)).length
        );
      });

      chart = {
        label: "",
        datasets: [
          {
            label: t("survey.statistics.selectionTimes"),
            backgroundColor: pickColor(0),
            data,
          },
        ],
        labels,
      };
    }
    return chart;
  };

  useEffect(() => {
    var mappedQuestions = [];
    getTraineeExamAnswers(quizId, 0, "")
      .then((res) => {
        setQuiz(res.data.quiz);
        setLevels(res.data.levels);
        setQuestions(res.data.questions);
        res.data.questions
          .filter((question) => QuestionTypeWithOptions.includes(question.type_id))
          .map((question) => {
            if (question.options?.length) {
              // options based questions
              var chart = encode1DQuestion(question);

              // append the mapped question
              if (chart) {
                mappedQuestions.push({
                  id: question.id,
                  title: question.title,
                  type: question.type,
                  responses: question.answers,
                  chart,
                });
              }
            }
          });
        setMappedQuestions(mappedQuestions);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  const exportHandler = async (type) => {
    setIsUpdating(true);
    try {
      await exportTraineeExamAnswers(type, quizId);
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const breadcrumbList = [
    ...(role == "admin"
      ? [
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("sidebar.label.courses"),
      page: t("sidebar.label.courses"),
      pagePath: `/${role}/courses`,
    },
    {
      id: t("Courseoutline"),
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${courseId}`,
    },
    {
      id: t("trainer.quiz.exams"),
      page: t("trainer.quiz.exams"),
      pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`,
    },
    {
      id: quizId,
      page: quiz.title,
      pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}`,
    },
    {
      id: t("statistics"),
      page: t("statistics"),
      active: true,
    },
  ];

  function exportFile(type) {
    setIsUpdating(true);
    exportSurveysStatisticsQuestions(5, type)
      .then((res) => {
        if (res.status && res.status === 200) {
          setIsUpdating(false);
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        setIsUpdating(false);
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
      });
  }
  function ChartCard({ title, children }) {
    return (
      <div className="tw-border !tw-border-gray-200 tw-divide-y tw-divide-gray-200 tw-rounded">
        <div className="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-space-s-2">
            <div className="tw-flex tw-items-center tw-space-s-2 tw-font-bold tw-text-gray-700">
              <div className="tw-flex tw-items-center tw-space-s-2">
                <div className={classes["title_size"]}>{title}</div>
              </div>
            </div>
          </div>
          <button
            type="button"
            // onClick={() => {
            //   let temp = [...yList];
            //   const ind = temp.findIndex((i) => i == question.id);
            //   if (ind < 0) {
            //     temp.push(question.id);
            //   } else {
            //     temp.splice(ind, 1);
            //   }
            //   setYList(temp);
            // }}
            className={`fal fa-poll tw-p-1 tw-rounded tw-bg-gray-600 tw-text-white tw-transition-all tw-duration-500 ${
              "" // yList.includes(question.id) ? "" : "tw-rotate-90"
            }`}
          ></button>
        </div>
        <div className="tw-py-2 tw-px-4">{children}</div>
      </div>
    );
  }

  function getQuestionLevel(question) {
    const pg =
      (question.answers.filter((answer) => parseFloat(answer.grade) / question.degree >= 0.5).length /
        question.answers.length) *
      100;
    const level = levels.find((level) => pg >= level.min && pg <= level.max);
    return level?.id;
  }

  return (
    <div className="container-fluid" id={"content-section"}>
      <div className="row">
        <div className="col-12 sm:tw-px-2 tw-py-8">
          {/*TODO*/}
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div>
      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      {isLoading ? (
        <div className="mt-5" style={{ height: "65vh" }}>
          <SkeletonCard />
        </div>
      ) : (
        <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-10 tw-mb-8">
          <div className="md:tw-flex tw-items-center tw-justify-between">
            <div className="md:tw-flex tw-items-center tw-justify-center tw-space-s-4">
              <div className="tw-space-y-2 tw-py-2">
                <div className="sm:tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-text-lg sm:tw-text-3xl tw-text-teal-700">{quiz.title}</div>
                </div>
              </div>
            </div>
            {role !== "partner" && (
              <div className="tw-flex tw-items-center tw-space-s-6">
                <button
                  type="button"
                  onClick={() => exportHandler("pdf")}
                  className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#b91414] tw-border-none"
                >
                  <img src={PdfIcon} alt="" />
                </button>
                <button
                  type="button"
                  onClick={() => exportHandler("xlsx")}
                  className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#138d5f] tw-border-none"
                >
                  <img src={XlsIcon} alt="" />
                </button>
              </div>
            )}
          </div>
          <>
            <ChartCard title={t(`Lowest, highest and average in the quiz`)}>
              <div className="tw-h-96 tw-grid tw-grid-cols-2 tw-gap-4">
                <div>
                  <Bar
                    options={generalOptions}
                    data={{
                      labels: [t("Lowest mark"), t("Average mark"), t("Highest mark")],
                      datasets: [
                        {
                          label: t("mark"),
                          backgroundColor: [pickColor(0), pickColor(1), pickColor(2)],
                          data: [
                            quiz.users_answers.reduce(
                              (prev, cur) => (cur.grade < prev ? cur.grade : prev),
                              quiz.users_answers[0]?.grade
                            ),
                            quiz.users_answers.reduce((prev, cur) => prev + cur.grade, 0) / quiz.users_answers.length,
                            quiz.users_answers.reduce((prev, cur) => (cur.grade > prev ? cur.grade : prev), 0),
                          ],
                          maxBarThickness: 120,
                          grouped: true,
                        },
                      ],
                    }}
                    key={`question-success`}
                  />
                </div>
                <div>
                  <Doughnut
                    options={generalOptions}
                    data={{
                      labels: [t("pass_percentage"), t("failed_percentage")],
                      datasets: [
                        {
                          label: t("pass_percentage"),
                          backgroundColor: [pickColor(0), "#eeeeee55"],
                          data: [
                            (quiz.users_answers.filter(
                              (ans) => (ans.grade / quiz.total_grade) * 100 >= quiz.success_rate
                            ).length /
                              quiz.users_answers.length) *
                              100,
                            (quiz.users_answers.filter(
                              (ans) => (ans.grade / quiz.total_grade) * 100 < quiz.success_rate
                            ).length /
                              quiz.users_answers.length) *
                              100,
                          ],
                          maxBarThickness: 120,
                          grouped: true,
                        },
                      ],
                    }}
                    key={`question-success`}
                  />
                </div>
              </div>
            </ChartCard>
            {mappedQuestions.map((question, index) => {
              return (
                <ChartCard
                  key={index}
                  title={`${question.title} (${i18n.language == "ar" ? question.type.name_ar : question.type.name_en})`}
                >
                  <div className="tw-h-60 tw-mb-4">
                    <Bar
                      options={getOptions(question, question.chart)}
                      data={{
                        labels: question.chart.labels,
                        datasets: question.chart.datasets.map((dataset) => ({
                          ...dataset,
                          maxBarThickness: 120,
                          grouped: true,
                        })),
                      }}
                      key={`question-${question.id}`}
                    />
                  </div>
                </ChartCard>
              );
            })}
            <ChartCard title={t(`The number of students' correct and incorrect answers and the percentage`)}>
              <div className="tw-h-60">
                <Bar
                  options={{
                    ...generalOptions,
                    scales: {
                      ...generalOptions.scales,
                      x: {
                        ticks: {
                          callback: function (value, index, ticks_array) {
                            let characterLimit = 12;
                            let label = questions[value]?.title;
                            if (label.length >= characterLimit) {
                              return (
                                label
                                  .slice(0, label.length)
                                  .substring(0, characterLimit - 1)
                                  .trim() + "..."
                              );
                            }
                            return label;
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: questions.map((question) => question.title),
                    datasets: [
                      {
                        label: t("survey.answers.correct"),
                        backgroundColor: pickColor(1),
                        data: questions.map(
                          (question) =>
                            question.answers.filter((answer) => parseFloat(answer.grade) / question.degree >= 0.5)
                              .length
                        ),
                        maxBarThickness: 120,
                        grouped: true,
                      },
                      {
                        label: t("survey.answers.wrong"),
                        backgroundColor: pickColor(5),
                        data: questions.map(
                          (question) =>
                            question.answers.filter((answer) => parseFloat(answer.grade) / question.degree < 0.5).length
                        ),
                        maxBarThickness: 120,
                        grouped: true,
                      },
                    ],
                  }}
                  key={`question-success`}
                />
              </div>
              <div className="tw-h-60">
                <Bar
                  options={{
                    ...generalOptions,
                    scales: {
                      ...generalOptions.scales,
                      x: {
                        ticks: {
                          callback: function (value, index, ticks_array) {
                            let characterLimit = 12;
                            let label = questions[value]?.title;
                            if (label.length >= characterLimit) {
                              return (
                                label
                                  .slice(0, label.length)
                                  .substring(0, characterLimit - 1)
                                  .trim() + "..."
                              );
                            }
                            return label;
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: questions.map((question) => question.title),
                    datasets: [
                      {
                        label: t("pass_percentage"),
                        backgroundColor: pickColor(0),
                        data: questions.map(
                          (question) =>
                            (question.answers.filter((answer) => parseFloat(answer.grade) / question.degree >= 0.5)
                              .length /
                              question.answers.length) *
                            100
                        ),
                        maxBarThickness: 120,
                        grouped: true,
                      },
                    ],
                  }}
                  key={`question-success`}
                />
              </div>
            </ChartCard>
            <ChartCard
              title={t(
                `The easiest and most difficult question depends on the students’ correct and incorrect answers`
              )}
            >
              <div className="tw-h-60">
                <Bar
                  options={{
                    ...generalOptions,
                    scales: {
                      ...generalOptions.scales,
                      x: {
                        ...generalOptions.scales.x,
                        ticks: {
                          ...generalOptions.scales.x.ticks,
                          callback: function (value, index, ticks_array) {
                            let characterLimit = 12;
                            let label = questions[value]?.title;
                            if (label.length >= characterLimit) {
                              return (
                                label
                                  .slice(0, label.length)
                                  .substring(0, characterLimit - 1)
                                  .trim() + "..."
                              );
                            }
                            return label;
                          },
                        },
                      },
                      y: {
                        ...generalOptions.scales.y,
                        ticks: {
                          ...generalOptions.scales.y.ticks,
                          stepSize: 1,
                          callback: function (value, index, values) {
                            return value ? levels[value - 1]?.label : "";
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: questions.map((question) => question.title),
                    datasets: [
                      {
                        label: t("Question level when creating"),
                        backgroundColor: pickColor(0),
                        data: questions.map((question, index) => question.level),
                        pointStyle: "rectRot",
                      },
                      {
                        label: t("Question level based on platform analysis"),
                        backgroundColor: pickColor(1),
                        data: questions.map((question, index) => getQuestionLevel(question)),
                        pointStyle: "circle",
                      },
                    ],
                  }}
                  key={`question-levels`}
                />
              </div>
            </ChartCard>
          </>
        </div>
      )}
    </div>
  );
}
