import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import classes from "./programPaymentsReport.module.css";

const ProgramPaymentsReportTable = ({ programPaymentsReport, loading }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    // trainee name , trainee email, payment.is_paid, is_payment_due, is_payment_overdue, payment.payment_status, payment.payment_reject_reason, payment_date, end_date
    {
      id: "trainee_name",
      name: t("payments.trainee_name"),
      key: "trainee_name",
      cell: ({ rowData }) => <>{rowData?.trainee[i18n.language === "en" ? "name_en" : "name_ar"] || "-"}</>,
      sortable: false,
    },
    {
      id: "trainee_email",
      name: t("payments.trainee_email"),
      key: "trainee_email",
      cell: ({ rowData }) => <>{rowData?.trainee?.email || "-"}</>,
      sortable: false,
    },
    {
      id: "is_paid",
      name: t("payments.is_paid"),
      key: "is_paid",
      cell: ({ rowData }) => <>{rowData?.payment?.is_paid ? t("yes") : t("no")}</>,
      sortable: false,
    },
    {
      id: "is_payment_due",
      name: t("payments.is_payment_due"),
      key: "is_payment_due",
      cell: ({ rowData }) => <>{rowData?.is_payment_due ? t("yes") : t("no")}</>,
      sortable: false,
    },
    {
      id: "is_payment_overdue",
      name: t("payments.is_payment_overdue"),
      key: "is_payment_overdue",
      cell: ({ rowData }) => <>{rowData?.is_payment_overdue ? t("yes") : t("no")}</>,
      sortable: false,
    },
    {
      id: "payment_status",
      name: t("payments.payment_status"),
      key: "payment_status",
      cell: ({ rowData }) => <>{rowData?.payment?.payment_status || "-"}</>,
      sortable: false,
    },
    {
      id: "payment_reject_reason",
      name: t("payments.payment_reject_reason"),
      key: "payment_reject_reason",
      cell: ({ rowData }) => <>{rowData?.payment?.payment_reject_reason || "-"}</>,
      sortable: false,
    },
    {
      id: "payment_date",
      name: t("payments.payment_date"),
      key: "payment_date",
      cell: ({ rowData }) =>
        rowData?.payment_date ? (
          <>
            {moment(rowData?.payment_date).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.payment_date)}
          </>
        ) : (
          "-"
        ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("payments.end_date"),
      key: "end_date",
      cell: ({ rowData }) =>
        rowData?.end_date ? (
          <>
            {moment(rowData?.end_date).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.end_date)}
          </>
        ) : (
          "-"
        ),
      sortable: false,
    },
  ];

  return <MainTable columns={columns} data={programPaymentsReport || []} isLoading={loading} />;
};

export default ProgramPaymentsReportTable;
