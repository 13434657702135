import { Box, CircularProgress } from "@material-ui/core";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { LessonContentContext } from "../../../../../Providers/LessonContentProvider";
import { fetchActivityCommentsList } from "../../../../../Redux/Actions/activityComment/activityComment.action";
import { toServerTime } from "../../../../../Services/Timezone";
import { editActivityToLesson } from "../../../../../Services/api/courses/courseProvider";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import FileIcon from "../../../../../assets/icons/file.svg";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import Input from "../../../../Shared/Components/Input/Input";
import InputFile from "../../../../Shared/Components/InputFile/InputFile";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import Select from "../../../../Shared/Components/Select/Select";
import classes from "./discFormModal.module.css";

function DiscFormModal({ chapter, title, dismissModal, getCommentsListHandler, activityId }) {
  require("./discFormModal.css");
  const lessonContentContext = useContext(LessonContentContext);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);
  const [hoursDatePicker, setHoursDatePicker] = useState(null);
  const [minutesDatePicker, setMinutesDatePicker] = useState(null);
  const [hoursDatePickerEnd, setHoursDatePickerEnd] = useState(null);
  const [minutesDatePickerEnd, setMinutesDatePickerEnd] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    }
  };

  useEffect(() => {
    currentDate();
    setIsLoading(false);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div
          className="add_activity_modal"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="add_activity_modal_header">
            <h2 className="add_activity_modal_title">{title}</h2>
            <div className="add_activity_modal_close" onClick={dismissModal}>
              <img src={DeleteIcon} alt="close" />
            </div>
          </div>
          <Formik
            initialValues={{
              name: chapter?.name || "",
              desc: chapter?.desc || "",
              file_size: chapter?.file_size || "",
              interactions_num: chapter?.interactions_num || "",
              interactions_agree: chapter?.interactions_agree == true ? "1" : "0",
              file: Object.keys(chapter?.attachment || {}).length > 0 ? chapter?.attachment : null,
              start_time: chapter?.start_time
                ? new Date(getLocaleTime(new Date(chapter?.start_time)))
                : setHours(setMinutes(new Date(), minutesDatePicker), hoursDatePicker),
              end_time: chapter?.end_time
                ? new Date(getLocaleTime(new Date(chapter?.end_time)))
                : setHours(setMinutes(new Date(), minutesDatePickerEnd), hoursDatePickerEnd),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              // setSubmitting(true);
              // setIsLocalSubmitting(true);
              let formData = new FormData();
              Object.keys(values).forEach((field) => {
                if (values[field] !== "" && values[field] !== null) {
                  if (field === "file") {
                    formData.append("file", values["file"].file);
                  } else if (field === "start_time" || field === "end_time") {
                    formData.append(field, toServerTime(values[field]));
                  } else {
                    formData.append(field, values[field]);
                  }
                }
              });

              if (
                JSON.stringify(values["file"]) === JSON.stringify(chapter?.attachment) ||
                (values["file"] === null && Object.keys(chapter?.attachment).length === 0)
              ) {
                formData.delete("file");
              } else if (values["file"] === null) {
                formData.append("file", "");
                formData.append("delete_file", "1");
              } else {
                formData.append("file", values["file"].file);
              }

              try {
                await editActivityToLesson(formData, activityId);

                getCommentsListHandler();
                dismissModal();
                setIsLocalSubmitting(false);
                setSubmitting(false);
              } catch (error) {
                setIsLocalSubmitting(false);
                setSubmitting(false);
                return;
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.name) {
                errors.name = t("crud.errors.required");
              }

              if (!values.desc) {
                errors.desc = t("crud.errors.required");
              }

              if (!values.file_size) {
                errors.file_size = t("crud.errors.required");
              }

              if (!values.interactions_num) {
                errors.interactions_num = t("crud.errors.required");
              }

              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="add_activity_modal_form">
                <div className="add_activity_modal_form_fields">
                  <Input
                    type="text"
                    placeholder={t("trainer.course.lesson.activity.crud.name")}
                    label={t("trainer.course.lesson.activity.name")}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name}
                    required
                  />

                  <div>
                    <label htmlFor="description">{t("trainer.course.lesson.activity.description")}</label>
                    <SunEditor
                      name="desc"
                      onChange={(desc) => {
                        setFieldValue("desc", desc);
                      }}
                      setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                      value={values.desc}
                      defaultValue={values.desc}
                      setOptions={{
                        height: 200,
                        rtl: i18n.language === "ar" ? true : false,
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize", "formatBlock"],
                          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                          ["removeFormat", "codeView"],
                          ["fontColor", "hiliteColor", "outdent", "indent"],
                          ["align", "horizontalRule", "list", "table"],
                          ["link", "image", "video"],
                        ],
                      }}
                    />
                    <div>
                      {errors && errors.desc ? (
                        <span className={"attachment_panel_input_error"}>{errors.desc}</span>
                      ) : null}
                    </div>
                  </div>

                  <Input
                    type="number"
                    placeholder={t("trainer.course.lesson.activity.crud.rpu")}
                    label={t("trainer.course.lesson.activity.rpu")}
                    name="interactions_num"
                    onChange={handleChange}
                    value={values.interactions_num}
                    error={errors.interactions_num}
                    min={1}
                    required
                  />

                  <Input
                    type="number"
                    placeholder={t("trainer.course.lesson.activity.crud.max_size")}
                    label={t("trainer.course.lesson.activity.max_size")}
                    name="file_size"
                    onChange={handleChange}
                    value={values.file_size}
                    error={errors.file_size}
                    required
                  />

                  <Select
                    name="interactions_agree"
                    label={t("trainer.course.lesson.activity.require_approval")}
                    onchange={handleChange}
                    options={[
                      { label: t("no"), value: 0, id: 0 },
                      { label: t("yes"), value: 1, id: 1 },
                    ]}
                    error={errors.interactions_agree}
                    value={values.interactions_agree}
                    initialValue={t("trainer.course.lesson.activity.require_approval")}
                    required
                  />
                  <InputFile
                    id="file"
                    name="file"
                    type="document"
                    placeholder={t("trainer.course.lesson.attachment")}
                    setFieldValue={setFieldValue}
                    fileIcon={FileIcon}
                    accept={
                      "application/pdf,.doc,.docx,application/msword,application/vnd.ms-excel,image/*,video/*,audio/*"
                    }
                    label={t("trainer.course.lesson.attachment")}
                    // note={`${t("Exhibition.pdf_note")} ${fromKbToMb(getTypeFile(DOC_PDF)[0]?.max_size)} ${t(
                    //   "booth.megabyte"
                    // )}`}
                    error={errors.file}
                    value={values.file}
                    documentWithoutTitle={true}
                  />
                  <div style={{ display: "flex" }}>
                    <div className={`${classes["create_tax__form__field"]} col-12 col-md-6`}>
                      <MainDatePicker
                        label={t("general.start_date_time")}
                        name="start_time"
                        error={errors.start_time}
                        value={values.start_time}
                        onChange={(date) => {
                          setFieldValue("start_time", date);

                          let CurrentDate = new Date();
                          let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                          let DataPickerValue = date.toISOString().split("T")[0];
                          if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                            setHoursDatePicker(0);
                            setMinutesDatePicker(0);
                          } else {
                            currentDate("startDate");
                          }
                        }}
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), minutesDatePicker), hoursDatePicker)}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      />
                      <p className={"form-input-error-space"}>{errors.start_time ? errors.start_time : null}</p>
                    </div>
                    <div className={`${classes["create_tax__form__field"]} col-12 col-md-6`}>
                      <MainDatePicker
                        label={t("general.end_date_time")}
                        name="end_time"
                        error={errors.end_time}
                        value={values.end_time}
                        onChange={(date) => {
                          setFieldValue("end_time", date);

                          let CurrentDate = new Date();
                          let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                          let DataPickerValue = date.toISOString().split("T")[0];
                          if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                            setHoursDatePickerEnd(0);
                            setMinutesDatePickerEnd(0);
                          } else {
                            currentDate("endDate");
                          }
                        }}
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), minutesDatePickerEnd), hoursDatePickerEnd)}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      />
                      <p className={"form-input-error-space"}>{errors.end_time ? errors.end_time : null}</p>
                    </div>
                  </div>
                  <div className="add_activity_actions">
                    <div className={`${classes["create_tax__form__field-actions"]} col-12`}>
                      <button
                        className={classes["create_tax__form__field-actions-submit"]}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress color="white" size={30} />
                          </Box>
                        ) : (
                          t("crud.placeholders.submit")
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default DiscFormModal;
