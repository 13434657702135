import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { getCouponInfo, updateCoupon } from "../../../../Services/api/coupon/couponProvider";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import ManageCoupon from "./ManageCoupon";

const EditCoupon = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [couponDetails, setCouponDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCouponHandler = async () => {
    setIsLoading(true);
    await getCouponInfo(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCouponDetails(res?.data?.response);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg ? error?.response?.data?.msg : "Failure in service"}
          </span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCouponHandler();
  }, [id]);

  const submitHandler = async (values) => {
    let response = await updateCoupon(values, id);
    if (response.status === 200 && response.data.status) {
      history.push(`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/coupon`);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : t("coupon.messages.update_success")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : t("coupon.messages.update_failed")}
        </span>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <ManageCoupon
          type={
            couponDetails?.is_valid
              ? moment(new Date()).isBefore(getLocaleTime(new Date(couponDetails?.end_date)))
                ? "limitedEdit"
                : "cannotEdit"
              : "canEdit"
          }
          submitHandler={submitHandler}
          couponDetails={couponDetails}
        />
      )}
    </>
  );
};

export default EditCoupon;
