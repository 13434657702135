import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getSavedSearchDetails } from "../../../../Redux/Actions/search.action";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SearchTable from "../SearchTable/SearchTable";
import classes from "../search.module.css";

const SearchHistoryDetails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { savedSearchDetailsList, savedSearchDetailsColumns, savedSearchDetailsMeta, savedSearchDetailsLoading,savedSearchDetailsFinished } = useSelector((state) => state.searchReducer)
  const [firstHit, setFirstHit] = useState(true);


  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "dashboard",
      page: t("admin.label.dashboard"),
      pagePath: "/admin",
    },
    {
      id: "search",
      page: t("search.search_reports"),
      pagePath: "/admin/search/history",
    },
    {
      id: "search_history",
      page: t("search.search_history"),
      pagePath: "/admin/search/history",
    },
    {
      id: "search_history_details",
      page: t("search.search_history_details"),
      active: true,
    },
  ];


  const getSavedSearchDetailsHandler = (page = 1) => {
    dispatch(getSavedSearchDetails({
      id,
      page,
      perPage: 10,
      history,
    }))
  }

  useEffect(() => {
    getSavedSearchDetailsHandler()
  }, [id]);


  useEffect(() => {
    if (!firstHit) return
    if (savedSearchDetailsFinished) {
      setFirstHit(false)
    }
  }, [savedSearchDetailsFinished]);

  return (
    <div className={classes["search"]}>
      <div className="container-fluid">
        <div className={classes["search__wrapper"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            {firstHit ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
              <MainBox>
                <MainBoxHead
                  title={t("search.search_history")}
                />
                <SearchTable
                  submitSearchHandler={getSavedSearchDetailsHandler}
                  tableColumns={savedSearchDetailsColumns}
                  tableData={savedSearchDetailsList}
                  isLoading={savedSearchDetailsLoading}
                  totalPages={savedSearchDetailsMeta?.totalPages}
                />
              </MainBox>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default SearchHistoryDetails