import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { getProgramPaymentDate } from "../../../../Services/api/Financial/FinancialProvider";
import { Lang } from "../../../../utils";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function ProgramPaymentDate(props) {
  require("./ProgramPaymentDate.css");
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const query = new URLSearchParams(props.location.search, "search");
  const [currentFilter, setCurrentFilter] = useState({
    program_payment_date: query.get("program_payment_date")
      ? query.get("program_payment_date")
      : "today",
  });
  const [payment, setPayment] = useState([]);
  const [display, setDisplay] = useState(query.get("program_payment_date") == 'all' ? 'block' : 'none');
  const [opacity, setOpacity] = useState(query.get("program_payment_date") == 'all' ? 1 : 0);

  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
    useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [statuses] = useState([
    { text: t("financial.label.past"), value: "past" },
    { text: t("financial.label.today"), value: "today" },
    { text: t("financial.label.future"), value: "future" },
  ]);

  const fetchAllPayment = (params = currentFilter.program_payment_date) => {
    if (query) {
      setCurrentFilter({
        program_payment_date: query.get("program_payment_date"),
      });
    }
    return new Promise((resolve, reject) => {
      setIsUpdating(true)
      getProgramPaymentDate(clearEmptyProp(params))
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setPayment(res.data.payment);
            setIsLoading(false);
            setIsUpdating(false)
            resolve();
          } else {
            reject("Error");
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        });
    });
  };

  useEffect(() => {
    fetchAllPayment(currentFilter);
  }, []);


  return (
    <>
      <div
        className="container-fluid"
        id={"content-section"}
        style={{
          position: "relative",
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 50,
        }}
      >
        <div className="admin_flex" id="content-header-bar">
          <div className="admin_label">
            <NavLink
              to={`${
                localStorage.getItem("type") == "accountant"
                  ? "/accountant"
                  : "/admin"
              }`}
            >
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("financial.label.programPayment")}
          </div>
        </div>
        {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
        {isLoading == false ? (
          <div
            id="content-body-section"
          >
            {isPerformingBackgroundOperation ? (
              <SkeletonCardOverlay borderRadius={10} top={40} />
            ) : null}
            <div
              className={"tajah_table_fade_change"}
              style={{
                opacity: opacity,
                transition: "all 0.5s ease",
                display:display,
              }}
            >
              <Formik
                initialValues={{
                  trainee_name: "",
                  program_payment_date: null,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    // setIsPerformingBackgroundOperation(true);
                    setCurrentFilter(values);
                    await fetchAllPayment(values);
                    // setIsPerformingBackgroundOperation(false);
                  } catch (e) {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {t("failed_fetching")}
                      </span>
                    );
                  }
                }}
                validateOnChange={false}
              >
                {({ values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} className={"table_bank_tran"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        width: "90%",
                        borderBottomWidth: 1,
                        borderBottomColor: "rgba(0,0,0,0.3)",
                        borderBottomStyle: "solid",
                        height: 40,
                      }}
                    >
                      <h5>{t("Filter")}</h5>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "90%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("financial.label.userName")}</label>
                        <input
                          id="trainee_name"
                          type="text"
                          name="trainee_name"
                          className="admin_filter_input"
                          onChange={(e) => {
                            setFieldValue("trainee_name", e.target.value);
                          }}
                          placeholder={t("financial.label.userName")}
                          value={values.trainee_name}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("bank_transfers.filter.status")}</label>
                        <Select
                          name="program_payment_date"
                          id="program_payment_date"
                          options={statuses}
                          getOptionLabel={(option) => option.text}
                          getOptionValue={(option) => option.value}
                          isClearable={true}
                          onChange={(status) => {
                            if (status) {
                              setFieldValue(
                                "program_payment_date",
                                status.value
                              );
                            } else {
                              setFieldValue("program_payment_date", null);
                            }
                          }}
                          placeholder={t("bank_transfers.placeholder.status")}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "90%",
                        marginTop: 20,
                      }}
                    >
                      <button
                        style={{
                          width: "100%",
                        }}
                        type="submit"
                        className="admin_add_button"
                      >
                        {t("crud.placeholders.submit")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            <div
              className="table_bank_tran"
            >
              <div className="tajah_card_title_container">
                <div>{t("financial.label.programPayment")}</div>
                <div className={"tajah_card_title_container_actions"}>
                  <button
                    style={{ borderRadius: 10, padding: 10, borderWidth: 0 }}
                    className={
                      display == "block" ? "btn btn-sm btn-danger" : "btn-bg-filter"
                    }
                    onClick={() => {
                      if (display === 'none') {
                        setDisplay('block')
                        setTimeout(() =>
                          setOpacity(1), 10
                        )
                      }
                      if (display === 'block') {
                        setOpacity(0)
                        setTimeout(() =>
                          setDisplay('none'), 300
                        )
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.search")}
                      content={
                        <i
                          className={
                            "fas fa-filter tajah_ticket_list_table_filter_icon"
                          }
                        />
                      }
                    />
                  </button>
                </div>
              </div>
              {currentFilter.program_payment_date == 'all' && payment.length == 0 ? (
                <p>{t('financial.label.listAllProgramPayment')}</p>
              ):(
              <div
                className="table-responsive"
                style={{
                  width: "90%",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("financial.label.userName")}</th>
                      <th scope="col">{t("financial.label.programName")}</th>
                      <th scope="col">{t("financial.label.price")}</th>
                      {/*<th scope="col">{t("financial.label.payment")}</th>*/}
                      <th scope="col">{t("financial.label.paymentDate")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payment.length == 0 ? (
                      <tr>
                        <td colSpan={6}>{t("no_record")}</td>
                      </tr>
                    ) : (
                      payment.map((payment, index) => {
                        return (
                          <tr key={index}>
                            <td scope="row">{index + 1}</td>
                            <td>
                              {i18n.language === Lang.AR
                                ? payment.trainee_name_ar
                                : payment.trainee_name_en}
                            </td>
                            <td>{payment.program_name}</td>
                            <td>{payment.amount}</td>
                            {/*<td>{payment.payment_num}</td>*/}
                            <td>{payment.payment_date}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
               )}
            </div>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default ProgramPaymentDate;
