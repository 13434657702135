import React, { useContext } from "react";
import "react-circular-progressbar/dist/styles.css";
import { CourseContext } from "../../../../../Services/api/courses/courseContext";
import SkeletonSpinnerOverlay from "../../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import TrainingCourseContentHeader from "./TrainingCourseContentHeader";
import TrainingCourseContentInfo from "./TrainingCourseContentInfo";

function TrainingCoursesContent({ setSubscription, userSCGHS, isProgram, inProgram }) {
  require("./TrainingCourseContent.css");
  const courseDetailsContext = useContext(CourseContext);

  return (
    <>
      <div className="training_wrapper">
        {courseDetailsContext.isLoading ? (
          <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
        ) : (
          <div>
            <TrainingCourseContentHeader
              setSubscription={setSubscription}
              userSCGHS={userSCGHS}
              inProgram={inProgram}
            />
            <TrainingCourseContentInfo isProgram={isProgram} inProgram={inProgram} />
          </div>
        )}
      </div>
    </>
  );
}

export default TrainingCoursesContent;
