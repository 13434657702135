import React, { useEffect, useRef, useState } from "react";

import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { exportRefund, getRefunds } from "../../../../Services/api/Financial/FinancialProvider";
import { exportRefunds } from "../../../../Services/api/RefundList";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./Refund.module.css";
import { FilterActionsRefund } from "./RefundActionList";
import RefundFilter from "./RefundFilter";
import RefundTable from "./RefundTable";

function RefundSettingList({ type }) {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [meta, setMeta] = useState({});
  const [refunds, setRefunds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRefundFilter, setCurrentRefundFilter] = useState({});

  const [filterData, setFilterData] = useState({
    user_name: "",
    user_email: "",
    refundable_name: "",
    refund_date: "",
  });

  //   const { refundsListMeta } = useSelector((state) => state.refundsListReducer);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("admin.label.admin_label"),
      pagePath: "#",
    },
    {
      id: `content_development_refund`,
      page: t(
        `financial.refund.${
          type === "exhibition-visitors"
            ? "exhibition_visitors_refunds"
            : type === "exhibition-booths"
            ? "exhibition_booth_refunds"
            : "refunds"
        }`
      ),
      pagePath: "#",
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      user_name: "",
      user_email: "",
      refundable_name: "",
      refund_date: "",
    });
    setIsResetApplied(true);
  };

  const fetchAllRefund = () => {
    setIsLoading(true);
    const filter = getFilterData();
    return new Promise((resolve, reject) => {
      getRefunds({ page: currentPage, perPage: 10, filter: filter })
        .then((res) => {
          if (res.status && res.status === 200 && res.data.status) {
            setRefunds(res.data.data.refund_requests);
            setMeta(res.data.data.meta);
            setIsLoading(false);
            resolve();
          } else {
            reject("Error");
          }
          setCurrentRefundFilter(filter);
        })
        .catch((err) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
        });
    });
  };

  useEffect(() => {
    fetchAllRefund();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const getFilterData = () => {
    const filter = { page: currentPage, perPage: 10 };
    filterData.user_name && (filter.user_name = filterData.user_name);
    filterData.user_email && (filter.user_email = filterData.user_email);
    filterData.refundable_name && (filter.refundable_name = filterData.refundable_name);
    filterData.refund_date && (filter.refund_date = filterData.refund_date);

    filter.status = activeTab === 1 ? "Waiting" : activeTab === 2 ? "Approved" : activeTab === 3 ? "Refused" : "";

    filter.refundable_type = type === "trainee" ? "Course" : "Exhibition";
    filter.is_sponsor = type === "trainee" ? null : type === "exhibition-visitors" ? 0 : 1;
    return filter;
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);

    try {
      await exportRefunds(currentRefundFilter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  return (
    <div className={classes["refund-list"]}>
      {(isExportLoading || isUpdating) && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["refund-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t(
                    `financial.refund.${
                      type === "exhibition-visitors"
                        ? "exhibition_visitors_refunds"
                        : type === "exhibition-booths"
                        ? "exhibition_booth_refunds"
                        : "refunds"
                    }`
                  )}
                  actions={FilterActionsRefund({
                    showFilter: showFilterHandler,
                    refundSetting: () => {
                      history.push(
                        `/${
                          localStorage.getItem("type") === "accountant" ? "accountant" : "admin"
                        }/refund-setting/list${type !== "trainee" ? `/${type}` : ""}`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <RefundFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={fetchAllRefund}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <RefundTable
                  refunds={refunds}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  getRefunds={fetchAllRefund}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  resetFilterHandler={resetFilterHandler}
                  setIsUpdating={setIsUpdating}
                  type={type}
                />
                <Pagination
                  count={meta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundSettingList;
