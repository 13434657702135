import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SortIcon from "../../../../assets/icons/sort.svg";
import Logo from "../../../../assets/image/BoothPage/logo_user.png";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import classes from "./mainTable.module.css";

const MainTable = ({
  columns,
  data,
  isLoading,
  sortAction,
  type,
  stickyFC = true,
}) => {
  const { t, i18n } = useTranslation();

  let typeDataInsideTable = type || "";

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);
  return (
    <div className={classes["main-table"]}>
      <table className="table ">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                style={{
                  whiteSpace: "nowrap",
                  ...(index == 1 && width > 650
                    ? {
                        position: stickyFC ? "sticky" : null,
                        ...(i18n.language === "en"
                          ? { left: "0" }
                          : { right: "0" }),
                      }
                    : {}),
                }}
                key={index}
                onClick={() => {
                  column.sortable && sortAction(column.key);
                }}
                className={
                  column.align
                    ? classes[`main-table__column--${column.align}`]
                    : ""
                }
              >
                {column.sortable && (
                  <img className={classes["sort-icon"]} src={SortIcon} alt="" />
                )}
                {column.name}
              </th>
            ))}
          </tr>
        </thead>

        <tbody style={{ overflowWrap: "anywhere" }}>
          {!isLoading &&
            !!data.length &&
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, index) => (
                  <td
                    key={index}
                    style={{
                      ...(index == 1 && width > 650
                        ? {
                            position: stickyFC ? "sticky" : null,
                            ...(i18n.language === "en"
                              ? { left: "0" }
                              : { right: "0" }),

                            backgroundColor: "white",
                          }
                        : {}),
                    }}
                    title={column.tooltip && column.tooltip({ rowData: row })}
                    className={
                      column.align
                        ? classes[`main-table__column--${column.align}`]
                        : ""
                    }
                  >
                    {typeDataInsideTable === "review" ? (
                      column.key === "photo" ? (
                        <img
                          className={classes["photo_inside_table"]}
                          src={
                            row?.image?.preview
                              ? row?.image?.preview
                              : `${process.env.REACT_APP_SERVER_PATH}/storage/exhibitions/${row?.image}`
                          }
                          alt={row?.name}
                        />
                      ) : column.cell ? (
                        column.cell({
                          rowData: row,
                          rowIndex: rowIndex,
                        })
                      ) : (
                        row[column.key]
                      )
                    ) : column.cell ? (
                      column.cell({
                        rowData: row,
                        rowIndex: rowIndex,
                      })
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {isLoading && (
        <div className={classes["loading__container"]}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && !data.length && (
        <div className={classes["not-data-container"]}>
          {t("Exhibition.no_data")}
        </div>
      )}
    </div>
  );
};

export default MainTable;
