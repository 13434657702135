import { Pagination } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import toLocalTimezone from "../../../../Services/Timezone";
import {
  deleteQuestionFromQuestionBank,
  exportAssessments,
  exportQuestionBank,
  exportQuestionsFromQuestionBank,
  exportQuiz,
  getQuestionBankItems,
  getQuestionTypes,
  importExam,
  importQuestionBank,
  importQuestionToQuestionBank,
  statusToggleQuestionFromQuestionBank,
} from "../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as CroseIcon } from "../../../../assets/icons/crose.svg";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/import.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import { ReactComponent as PenIcon } from "../../../../assets/icons/pen.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import { ReactComponent as ZipExportIcon } from "../../../../assets/icons/zip-export.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import { utcToLocal } from "../../../../utils/utcToLocal";
import QuestionTypes from "../../../Admin/Assessments/QuestionTypes";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DialogModal from "../../../Shared/Components/DialogModal/DialogModal";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./exhibitionList.module.css";

export default function ListQuestionBank({ quizable, role }) {
  require("./List.css");

  const modalRef = useRef();
  const { t } = useTranslation();
  const { courseId, quizableId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);

  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [keyPrefix, setKeyPrefix] = useState("__default_key__");

  const [ratingRow, setRatingRow] = useState(null);
  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [modalContent, setModalContent] = useState(0);

  const [selectedItemToBeReOpened, setSelectedItemToBeReOpened] = useState(null);

  const { systemRolesList, systemGenders, systemMedicalFields, systemCountries } = useSelector(
    (state) => state.systemUsersListReducer
  );

  const rend = () => Math.random().toString(36);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const statusToggleQuestion = (row) => {
    let isActive = row.active;
    setIsUpdating(true);
    statusToggleQuestionFromQuestionBank(row.id)
      .then(() => {
        refetchQuestionBank();
        setIsUpdating(false);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {!isActive ? t("trainer.quiz.messages.questions.enabled") : t("trainer.quiz.messages.questions.disabled")}
          </span>
        );
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data?.msg}</span>);
        setIsUpdating(false);
      });
  };

  const deleteQuestion = async (questionId) => {
    try {
      setIsUpdating(true);
      const res = await deleteQuestionFromQuestionBank(questionId);
      if (res.status && res.status == 200 && res.data.status) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data?.msg}</span>);
        refetchQuestionBank();
      } else {
        throw res;
      }
    } catch (error) {
      
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data?.msg}</span>);
    }

    setShowDeleteModalByRow(null);
    setIsUpdating(false);
  };

  const exportHandler = async (type) => {
    setIsUpdating(true);
    try {
      await exportQuestionsFromQuestionBank(type, courseId, {});
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const localImportQuestionBank = async (file) => {
    if (!file) return;
    try {
      let formData = new FormData();
      formData.append("file", file);
      setIsUpdating(true);
      await importQuestionBank(courseId, formData);
      refetchQuestionBank();
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("questions_bank_imported_successfully")}</span>
      );
    } catch (err) {
      setIsUpdating(false);

      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response?.data?.msg ?? t("failed_to_import_questions_bank")}
          </span>
          <ul>
            {Object.keys(err.response?.data?.errors ?? {}).map((key) => {
              return (
                <li
                  style={{
                    direction: i18n.language == Lang.AR ? "rtl" : "ltr",
                  }}
                >
                  <span
                    style={{
                      fontSize: 11,
                    }}
                  >
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  const localExportQuestionBank = async () => {
    setIsUpdating(true);
    try {
      await exportQuestionBank(courseId);
    } catch (err) {
      
    }
    setIsUpdating(false);
  };

  const fetchQuestionTypes = () => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestionTypes()
        .then((res) => {
          setQuestionTypes(res.data.types);
          resolve();
        })
        .catch((error) => {
          
          reject();
        });
    });
  };

  function getFilter() {
    const keys = Object.keys(filter);
    let currentFilter = { ...filter };

    if (currentFilter.questions_count) {
      const parseValue = JSON.parse(currentFilter.questions_count);
      currentFilter = {
        ...currentFilter,
        min_questions_count: parseValue.min,
        max_questions_count: parseValue.max,
      };
      delete currentFilter.questions_count;
    }

    if (currentFilter.success_rate != undefined && currentFilter.success_rate != null) {
      const parseValue = JSON.parse(currentFilter.success_rate);
      currentFilter = {
        ...currentFilter,
        min_success_rate: parseValue.min,
        max_success_rate: parseValue.max,
      };

      delete currentFilter.success_rate;
    }

    function boolToInt(value) {
      return value === "true" ? 1 : value === "false" ? 0 : null;
    }

    currentFilter.is_answers_corrected = boolToInt(currentFilter.is_answers_corrected);
    currentFilter.show_mark = boolToInt(currentFilter.show_mark);
    currentFilter.monitored = boolToInt(currentFilter.monitored);
    currentFilter.completion_rate = boolToInt(currentFilter.completion_rate);

    return currentFilter;
  }

  const actionsList = [
    {
      id: "answerCriteria",
      type: "button",
      label: t("answerCriteria.table_title"),
      color: "#8599B6",
      action: () => {
        history.push(`/${role}/course/${courseId}/assessment/evaluation`);
      },
    },
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      title: t("filter"),
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "import",
            icon: (
              <label className="tw-m-0 tw-p-4">
                <ZipExportIcon className="tw-h-5 tw-w-5" />

                <input
                  key={`import-${keyPrefix}`}
                  type="file"
                  accept=".zip"
                  className="tw-hidden"
                  onChange={({ target: { files } }) => {
                    setKeyPrefix(rend());
                    localImportQuestionBank(files[0]);
                  }}
                />
              </label>
            ),
            title: t(`${t("trainer.quiz.import")}`),
            theme: "pdf",
            color: "#2e3e5f",
            action: () => {},
          },
        ]
      : []),
    ...(role !== "partner"
      ? [
          {
            id: "export",
            icon: <ImportIcon className="tw-h-5 tw-w-5" />,
            title: t(`${t("trainer.quiz.export")}`),
            theme: "pdf",
            color: "#eee",
            action: () => {
              localExportQuestionBank();
            },
          },
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            title: t("trainer.quiz.add_question"),
            theme: "add",
            action: () => {
              history.push(`/${role}/course/${courseId}/question-bank/add`);
            },
          },
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            title: t(`${t("trainer.quiz.export")} PDF`),
            action: () => exportHandler("pdf"),
          },
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            title: t(`${t("trainer.quiz.export")} Excel`),
            action: () => exportHandler("xlsx"),
          },
        ]
      : []),
  ];

  const quizActionsList = [
    {
      id: "view-details",
      icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("general.view_details"),
      action: (row) => {
        setSelectedItemToBeReOpened(row);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "edit",
            icon: (row) => <PenIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("edit"),
            action: (row) => {
              history.push(`/${role}/course/${courseId}/question-bank/edit/${row.id}`);
            },
          },
          {
            id: "delete",
            icon: (row) => <CroseIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("delete"),
            action: (row, index = null) => {
              setShowDeleteModalByRow(row);
            },
          },
        ]
      : []),
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>,
    },
    // title
    {
      id: "title",
      name: t("questionTitle"),
      key: "title",
      cell: ({ rowData }) => (
        <span className={"cell-font tw-max-w-36 text2lines"} dangerouslySetInnerHTML={{ __html: rowData.title }} />
      ),
    },
    // type
    {
      id: "type_id",
      name: t("questionType"),
      key: "type_id",
      cell: ({ rowData }) => (
        <span className={"cell-font"}> {i18n.language == Lang.AR ? rowData.type.name_ar : rowData.type.name_en}</span>
      ),
    },
    {
      id: "created_at",
      name: t("dateAdded"),
      key: "created_at",
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            <></>
          )}
        </span>
      ),
      sortable: false,
    },
    // actions
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div className={`tw-flex tw-items-center tw-justify-center tw-space-s-2`}>
          {quizActionsList.map((action, index) => (
            <IconsTooltip
              title={action.title(rowData)}
              content={
                <button
                  key={action.id}
                  className={classes["exhibition_list__actions-btn"]}
                  onClick={() => {
                    action.action(rowData, rowIndex);
                  }}
                >
                  {action.icon(rowData, rowIndex)}
                </button>
              }
            />
          ))}
        </div>
      ),
    },
    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",

      cell: ({ rowData, rowIndex }) => (
        <SwitchToggle
          key={rowData.id}
          status={rowData.active}
          id={rowData.id}
          onStatusChange={() => {
            statusToggleQuestion(rowData);
          }}
          disabled={role === "partner"}
        />
      ),
    },
  ];

  const filterFields = [
    // title
    {
      id: "title",
      component: "input",
      type: "text",
      placeholder: t("question_title"),
      label: t("question_title"),
      name: "title",
      value: filter.title ?? "",
      classes: "col-12 col-md-6",
      onReset: () => filterChangeHandler({ target: { value: "", name: "title" } }),
      onchange: filterChangeHandler,
    },
    {
      id: "type_id",
      component: "select",
      label: t("questionType"),
      placeholder: t("questionType"),
      initialValue: t("questionType"),
      name: "type_id",
      value: filter.type_id ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: questionTypes?.map((type) => ({
        id: type.id,
        value: type.id,
        label: i18n.language === Lang.AR ? type.name_ar : type.name_en,
      })),
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setFilter({});
        if (currentPage == 1) {
          refetchQuestionBank({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        if (currentPage == 1) {
          refetchQuestionBank();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchQuestionBank = (params = getFilter()) => {
    params = {
      ...params,
      perPage: 10,
      quizable_type: quizable,
      quizable_id: quizableId,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getQuestionBankItems(courseId, params);
        if (response.status == 200) {
          setQuestions(response.data.data.questions);
          setMeta(response.data.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const refetchQuestionBank = async (params = getFilter()) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        page: currentPage,
      };
      await fetchQuestionBank(customFilter);
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  useEffect(() => {
    refetchQuestionBank();
  }, [currentPage, order]);

  const breadcrumbList = [
    ...(role == "admin"
      ? [
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("sidebar.label.courses"),
      page: t("sidebar.label.courses"),
      pagePath: `/${role}/courses`,
    },
    {
      id: t("Courseoutline"),
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${courseId}`,
    },
    {
      id: "question_bank",
      page: t("trainer.quiz.question_bank"),
      active: true,
    },
  ];

  useEffect(() => {
    if (modalContent != 0) {
      modalRef.current.showModal();
    }
  }, [modalContent]);

  function QuestionDisplayModal({ question }) {
    const questionType = QuestionTypes.find((type) => type.type == question?.type_id);
    return (
      <DialogModal
        onCancel={() => setSelectedItemToBeReOpened(null)}
        className="tw-w-full sm:tw-w-5/6 md:tw-w-4/5 lg:tw-w-3/4 xl:tw-w-2/3"
      >
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
          <div
            className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-divide-y inner-scrollbar tw-max-h-screen`}
            style={{ overflowY: "overlay" }}
          >
            <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
              <div className="tw-text-xl tw-text-teal-700" dangerouslySetInnerHTML={{ __html: question.title }} />
              <button type="button" onClick={() => setSelectedItemToBeReOpened(null)}>
                <CroseIcon className="tw-h-6 tw-w-6" />
              </button>
            </div>
            <div className="tw-py-4 tw-px-8">
              <Formik
                initialValues={{
                  questions: [
                    {
                      id: question.id,
                      answers: [],
                    },
                  ],
                }}
                onSubmit={async (values) => {}}
                validate={(values) => {
                  const errors = {};
                  return errors;
                }}
                validateOnChange={false}
              >
                {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="tw-overflow-x-auto inner-scrollbar">
                      <questionType.viewComponent
                        values={values.questions[0]}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        question={selectedItemToBeReOpened}
                        questionIndex={0}
                        revision
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </DialogModal>
    );
  }
  return (
    <>
      {selectedItemToBeReOpened && <QuestionDisplayModal question={selectedItemToBeReOpened} />}
      {showDeleteModalByRow && (
        <SharedModal
          icon={DeleteIcon}
          title={t("trainer.quiz.modal.delete.label")}
          name={""}
          confirmMsg={t("trainer.quiz.modal.delete.description")}
          confirmText={t("trainer.quiz.modal.delete.accept")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModalByRow(null);
          }}
          onConfirm={() => deleteQuestion(showDeleteModalByRow.id)}
          // isLoading={iseDeleteLoading}
        />
      )}
      <div className="tw-ms-auto"></div>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8">
            <MainBox>
              <MainBoxHead title={t("questionBank")} actions={actionsList} />
              {isFilter && <FilterBox fields={filterFields} />}
              <MainTable columns={columns} data={questions} sortAction={sortActionHandler} />
              <Pagination
                count={meta.totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
