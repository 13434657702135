import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import HeaderLogo from "../../../assets/icons/newHeader2024.svg";

// mui icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloudQueueOutlinedIcon from "@mui/icons-material/CloudQueueOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HowToVoteOutlinedIcon from "@mui/icons-material/HowToVoteOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import HomeIcon from "../../../assets/icons/homeSidebar.svg";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";

function Sidebar(props) {
  require("./sidebar.css");
  const history = useHistory();
  const { t } = useTranslation();
  const [sideBarItems, setSideBarItems] = useState([
    {
      title: t("Main"),
      itemId: "/",
      elemBefore: () => (
        <img
          src={HomeIcon}
          alt=""
          style={{ width: 18, height: 18, marginInlineEnd: 2 }}
        />
      ),
    },
    {
      title: t("sidebar.label.user_management"),
      itemId: "/1",
      elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("sidebar.label.user_management"),
          itemId: "/admin/roles",
        },
        {
          title: t("sidebar.label.send_messages"),
          itemId: "/admin/message/send",
        },
        {
          title: t("sidebar.label.addUser"),
          itemId: "/admin/create-account",
        },
        // {
        //   title: t("sidebar.label.trainer_ratings"),
        //   itemId: "/admin/trainer-rate/list",
        // },
        {
          title: t("sidebar.label.partners_tebx"),
          itemId: "/admin/partners-tebx",
        },
        {
          title: t("sidebar.label.sponsers"),
          itemId: "/admin/sponsers",
        },
      ],
    },

    {
      title: t("sidebar.label.financial_management"),
      itemId: "/2",
      elemBefore: () => <AttachMoneyIcon color="#046C77" />,
      subNav: [
        {
          title: t("sidebar.label.coupon"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/coupon`,
        },
        {
          title: t("sidebar.label.gift_coupon"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/gift-coupon`,
        },
        {
          title: t("sidebar.label.offer"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/offer`,
        },
        {
          title: t("sidebar.label.tax"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/tax`,
        },
        {
          title: t("sidebar.label.financial_movement"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/financial-movement`,
        },
        {
          title: t("sidebar.label.all_refunds"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/refund`,
        },
        {
          title: t("sidebar.label.exhibition_visitors_refunds"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/refund/exhibition-visitors`,
        },
        {
          title: t("sidebar.label.exhibition_booth_refunds"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/refund/exhibition-booths`,
        },
        {
          title: t("sidebar.label.payment_requests_courses"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/peyment-requests-courses`,
        },
        {
          title: t("sidebar.label.payment_requests_programs"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/peyment-requests-programs`,
        },
        {
          title: t("refunds_for_trainers"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/program-payment-date?program_payment_date=all`,
        },
        {
          title: t("Percentage_trainers_courses"),
          itemId: `/${
            localStorage.getItem("type") === "accountant"
              ? "accountant"
              : "admin"
          }/percentage-trainer/list`,
        },
      ],
    },
    {
      title: t("sidebar.label.content_development"),
      itemId: "/3",
      elemBefore: () => <FormatAlignCenterOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("specialties_and_departments"),
          itemId: "/admin/category",
        },
        {
          title: t("sidebar.label.courses"),
          itemId: "/admin/courses",
        },
        // {
        //   title: t("sidebar.label.department"),
        //   itemId: "/admin/department",
        // },
        {
          title: t("sidebar.label.program"),
          itemId: "/admin/program/list",
        },
        {
          title: t("sidebar.label.certificate_templates"),
          itemId: "/admin/certificateTemplates",
        },
        // {
        //   title: t("lecturers_commitment"),
        //   itemId: "/admin/lecturers-commitment",
        // },
      ],
    },
    {
      title: t("sidebar.label.list_of_survey"),
      itemId: "/18",
      elemBefore: () => <InsertDriveFileOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("survey.add"),
          itemId: "/admin/survey/add",
        },
        {
          title: t("survey.listSideBar"),
          itemId: "/admin/survey/list",
        },
        {
          title: t("survey.trainerListSideBar"),
          itemId: "/admin/survey/trainer/list",
        },
      ],
    },
    {
      title: t("ads_sidebar"),
      itemId: "/19",
      elemBefore: () => <CalendarMonthIcon color="#046C77" />,
      subNav: [
        {
          title: t("Advertisements"),
          itemId: "/admin/advertisements-list",
        },
      ],
    },
    {
      title: t("sidebar.label.Exhibition"),
      itemId: "/15",
      elemBefore: () => <BookmarkBorderOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("Exhibition.title_create"),
          itemId: "/admin/create-exhibition",
        },
        {
          title: t("Exhibition.Exhibition"),
          itemId: "/admin/exhibitions-list",
        },
      ],
    },

    {
      title: t("sidebar.label.protection"),
      itemId: "/8",
      elemBefore: () => <ShieldOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("sidebar.label.list_blocked"),
          itemId: "/admin/ip-blocked",
        },
        {
          title: t("sidebar.label.delete_list_blocked"),
          itemId: "/admin/ip-blocked/requests",
        },
      ],
    },

    {
      title: t("sidebar.label.support"),
      elemBefore: () => <HelpOutlineOutlinedIcon color="#046C77" />,
      // title: t("sidebar.label.live_chat"),
      itemId: "/7",
      subNav: [
        {
          title: t("sidebar.label.support_tickets"),
          itemId: "/admin/tickets",
        },
        {
          title: t("sidebar.label.live_chat"),
          itemId: "/admin/livechat",
        },
        {
          title: t("FAQ.list_more_common_questions"),
          itemId: "/admin/faq-list",
        },
        {
          title: t("FAQ.list_common_questions"),
          itemId: "/admin/faq",
        },
      ],
    },
    {
      title: t("search.search_reports"),
      elemBefore: () => <ManageSearchIcon color="#046C77" />,
      itemId: "/20",
      subNav: [
        {
          title: t("search.free_search"),
          itemId: "/admin/search/free",
        },
        {
          title: t("search.conduct_search"),
          itemId: "/admin/search",
        },
        {
          title: t("search.search_history"),
          itemId: "/admin/search/history",
        },
      ],
    },

    {
      title: t("sidebar.label.learning_analysis"),
      itemId: "/16",
      elemBefore: () => <ScatterPlotOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("sidebar.label.analysis"),
          itemId: "/admin/learning-analysis",
        },
        {
          title: t("sidebar.label.statistics"),
          itemId: "/admin/statistics",
        },
      ],
    },

    {
      title: t("sidebar.label.system_preferences"),
      itemId: "/6",
      elemBefore: () => <DnsOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("sidebar.label.enable_services"),
          itemId: "/admin/preferences",
        },
        {
          title: t("sidebar.label.files_system"),
          itemId: "/admin/typefile/list",
        },
        {
          title: t("zoom.Webx_teams"),
          itemId: "/admin/webx/list",
        },
        {
          title: t("sidebar.label.history_move_system"),
          itemId: "/admin/logs",
        },
      ],
    },

    {
      title: t("system_preferences.page_text"),
      itemId: "/4",
      elemBefore: () => <NotesOutlinedIcon color="#046C77" />,
      subNav: [
        {
          title: t("sidebar.label.about-us"),
          itemId: "/admin/about-us-edit",
        },
        {
          title: t("sidebar.label.TermsAndConditions"),
          itemId: "/admin/terms-and-conditions",
        },
        {
          title: t("sidebar.label.PrivacyPolicy"),
          itemId: "/admin/privacy-policy",
        },
      ],
    },

    // {
    //   title: t("sidebar.report.title"),
    //   itemId: "/13",
    //   elemBefore: () => <EqualizerOutlinedIcon color="#046C77" />,
    //   subNav: [
    //     {
    //       title: t("sidebar.report.title_trainer_courses"),
    //       itemId: "/admin/report/trainer/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainer_hours"),
    //       itemId: "/admin/report/trainer/hours/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainee_courses"),
    //       itemId: "/admin/report/trainee-courses/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainee_programs"),
    //       itemId: "/admin/report/trainee-programs/list",
    //     },
    //     {
    //       title: t("sidebar.report.title_trainee_hours"),
    //       itemId: "/admin/report/trainee/hours/list",
    //     },
    //   ],
    // },
  ]);

  useEffect(async () => {
    // if (localStorage.getItem("system_preferences")) {
    // const toBeModifiedSideBarItems = sideBarItems;
    // const preferences = JSON.parse(localStorage.getItem("system_preferences"));
    // if (preferences.support_tickets_service) {
    // toBeModifiedSideBarItems.push({
    //   title: t("sidebar.label.support_tickets"),
    //   itemId: "/5",
    //   subNav: [
    //     {
    //       title: t("sidebar.label.support_tickets"),
    //       itemId: "/admin/tickets",
    //     },
    //   ],
    // });
    // }
    // if (preferences.chat_service) {
    // toBeModifiedSideBarItems.push({
    //   title: t("sidebar.label.live_chat"),
    //   itemId: "/7",
    //   subNav: [
    //     {
    //       title: t("sidebar.label.live_chat"),
    //       itemId: "/livechat",
    //     },
    //   ],
    // });
    // }
    // setSideBarItems(toBeModifiedSideBarItems);
    // }
  }, []);

  const closeSideBar = () => {
    props.dismissSideBar();
  };

  useEffect(() => {
    setTimeout(() => {
      props.setAddAnimationSide(false);
    }, 300);
  }, [props.addAnimationSide]);

  return (
    <div
      className="sidebar inner-scrollbar"
      style={{
        transform: `${
          props.addAnimationSide && document.body.dir == "rtl"
            ? "translateX(100%)"
            : props.addAnimationSide && document.body.dir == "ltr"
            ? "translateX(-100%)"
            : null
        }`,
      }}
    >
      <div className="logo-sidebar">
        <NavLink exact to="/">
          <img src={HeaderLogo} alt="" />
        </NavLink>
        <CancelOutlinedIcon
          onClick={closeSideBar}
          sx={{ color: "#EE4A4A", cursor: "pointer" }}
        />
      </div>

      <div className="sidebar-header">
        <img className="user-image" src={localStorage.getItem("img")} alt="" />
        <div className="">
          <span>{t("sidebar.label.hello_on_you")}</span>
          <h4>
            {i18n.language === Lang.AR
              ? localStorage.getItem("name_ar") != null
                ? localStorage.getItem("name_ar").split(" ")[0].length > 12
                  ? localStorage.getItem("name_ar").split(" ")[0].slice(0, 9) +
                    "..."
                  : localStorage.getItem("name_ar").split(" ")[0]
                : null
              : localStorage.getItem("name_en") != null
              ? localStorage.getItem("name_en").split(" ")[0].length > 12
                ? localStorage.getItem("name_en").split(" ")[0].slice(0, 9) +
                  "..."
                : localStorage.getItem("name_en").split(" ")[0]
              : null}
          </h4>
        </div>
      </div>

      {localStorage.getItem("type") === "accountant" ? (
        <Navigation
          className="nav_sidebar"
          onSelect={({ itemId }) => {
            if (itemId !== "/1") {
              history.push(itemId);
              closeSideBar();
            }
          }}
          items={[
            {
              title: t("sidebar.label.financial_management"),
              itemId: "/1",
              subNav: [
                {
                  title: t("sidebar.label.coupon"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/coupon`,
                },
                {
                  title: t("sidebar.label.gift_coupon"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/gift-coupon`,
                },
                {
                  title: t("sidebar.label.offer"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/offer`,
                },
                {
                  title: t("sidebar.label.tax"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/tax`,
                },
                {
                  title: t("sidebar.label.financial_movement"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/financial-movement`,
                },
                // {
                //   title: t("sidebar.label.financialReport"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/financial/report`,
                // },
                {
                  title: t("sidebar.label.all_refunds"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/refund`,
                },
                {
                  title: t("sidebar.label.exhibition_visitors_refunds"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/refund/exhibition-visitors`,
                },
                {
                  title: t("sidebar.label.exhibition_booth_refunds"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/refund/exhibition-booths`,
                },
                {
                  title: t("sidebar.label.payment_requests_courses"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/peyment-requests-courses`,
                },
                {
                  title: t("sidebar.label.payment_requests_programs"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/peyment-requests-programs`,
                },
                // {
                //   title: t("sidebar.label.refund-setting"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/refund-setting/list`,
                // },
                // {
                //   title: t("sidebar.label.userWallet"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                // ? "accountant"
                //       : "admin"
                //   }/users-wallet`,
                // },
                // {
                //   title: t("sidebar.label.bank_transfers"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/bank-transfers`,
                // },
                {
                  title: t("refunds_for_trainers"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/program-payment-date?program_payment_date=all`,
                },
                {
                  title: t("Percentage_trainers_courses"),
                  itemId: `/${
                    localStorage.getItem("type") === "accountant"
                      ? "accountant"
                      : "admin"
                  }/percentage-trainer/list`,
                },
                // {
                //   title: t("sidebar.label.accountant.sponsors.list"),
                //   itemId: `/${
                //     localStorage.getItem("type") === "accountant"
                //       ? "accountant"
                //       : "admin"
                //   }/sponsor`,
                // },
                // {
                //     title: t("sidebar.label.accountant.sponsors.add"),
                //     itemId: `/${localStorage.getItem("type") === "accountant" ? "accountant" : "admin"}/sponsor/addsponsor`,
                // },
              ],
            },
          ]}
        />
      ) : (
        <Navigation
          className="nav_sidebar"
          onSelect={({ itemId }) => {
            if (
              itemId !== "/1" &&
              itemId !== "/2" &&
              itemId !== "/3" &&
              itemId !== "/4" &&
              itemId !== "/5" &&
              itemId !== "/6" &&
              itemId !== "/7" &&
              itemId !== "/8" &&
              itemId !== "/9" &&
              itemId !== "/10" &&
              itemId !== "/11" &&
              itemId !== "/12" &&
              itemId !== "/13" &&
              itemId !== "/14" &&
              itemId !== "/15" &&
              itemId !== "/16" &&
              itemId !== "/17" &&
              itemId !== "/18" &&
              itemId !== "/19" &&
              itemId !== "/20"
            ) {
              history.push(itemId);
              closeSideBar();
            }
          }}
          items={sideBarItems}
        />
      )}
    </div>
  );
}

export default Sidebar;
