import moment from "moment";
import { useTranslation } from "react-i18next";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./refundRequests.module.css";

const RefundRequestsTable = ({ refunds, isLoading }) => {
  const { t, i18n } = useTranslation();

  const displayStatus = (status) => {
    if (status === "Waiting") {
      return t("financial.refund.waiting");
    } else if (status === "Approved") {
      return t("financial.refund.approved");
    } else if (status === "Refused") {
      return t("financial.refund.refused");
    }
  };

  const displayRefundableType = {
    course: t("trainer.course.label"),
    program_payment: t("trainer.program.label"),
    exhibition_booth: t("general.exhibition_booth"),
    exhibition_visitor: t("general.exhibition_visitor"),
    wallet: t("general.wallet"),
  };

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "revision_by",
      name: t("financial.refund.revision_by"),
      key: "revision_by",
      cell: ({ rowData }) => <>{rowData?.revision_by || "-"}</>,
      sortable: false,
    },
    {
      id: "revision_date",
      name: t("financial.refund.revision_date"),
      key: "revision_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.revision_date ? (
            <>
              {moment(rowData?.revision_date).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.revision_date)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: "refundable_type",
      name: t("financial.refund.refundable_type"),
      key: "refundable_type",
      cell: ({ rowData }) => <>{displayRefundableType[rowData?.refund_settings?.refundable_type] || "-"}</>,
      sortable: false,
    },
    {
      id: "refundable_name",
      name: t("financial.refund.refundable_name"),
      key: "refundable_name",
      cell: ({ rowData }) => <>{rowData?.refundable?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "mechanism",
      name: t("Mechanism"),
      key: "mechanism",
      cell: ({ rowData }) => (
        <>{rowData?.refund_settings?.mechanism == 1 ? t("amount_tax") : t("coupon.label.per_value")}</>
      ),
    },
    {
      id: "amount",
      name: t("financial.refund.amount"),
      key: "amount",
      cell: ({ rowData }) => (
        <>
          {rowData?.refund_settings?.mechanism == 1
            ? rowData?.refund_settings?.amount + t("general.sar")
            : rowData?.refund_settings?.amount + "%"}
        </>
      ),
    },

    {
      id: "order_status",
      name: t("financial.refund.order_status"),
      key: "order_status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData.status === "Approved"
                ? "#46BB3E"
                : rowData.status === "Waiting"
                ? "#8599B6"
                : rowData.status === "Refused"
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData.status === "Approved"
                ? "#C1E2C822"
                : rowData.status === "Waiting"
                ? "#C1CEE222"
                : rowData.status === "Refused"
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayStatus(rowData?.status) || "-"}
        </span>
      ),
      sortable: false,
    },

    {
      id: "reason",
      name: t("financial.refund.reason"),
      key: "reason",
      cell: ({ rowData }) => (
        <>
          <div style={{ width: 300 }}>
            <div className={classes["note_table"]} dangerouslySetInnerHTML={{ __html: rowData?.reason }}></div>
          </div>
        </>
      ),
    },
  ];

  return <MainTable columns={columns} data={refunds} isLoading={isLoading} />;
};

export default RefundRequestsTable;
