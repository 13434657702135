import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import SearchIcon from "../../../assets/icons/search icon.svg";
import CourseCard from "../../Shared/Components/CourseCard/CourseCard";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import classes from "./SearchPage.module.css";
import SharedSlider from "../../Shared/SharedSlider/SharedSlider";
import ArrowIcon from "../../../assets/icons/right-arrow-green.svg";
import {
  getSearchAutoComplete,
  getSearchResults,
} from "../../../Services/api/HomeCoursesProvider";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
export default function SearchPage() {
  const location = useLocation();
  const searchAutoComplete = useRef(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(2);
  const [searchResults, setSearchResults] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]); // autocomplte list
  const [sortValue, setSortValue] = useState("1");
  const [showMore, setShowMore] = useState(false);
  const [showMorePrograms, setShowMorePrograms] = useState(false);

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };

  const handleSort = (e) => {
    setSortValue(e.target.value);
    if (e.target.value === "1") {
      setCourses((prev) =>
        [...prev].sort(
          (a, b) => new Date(b.created_at) - new Date(a.start_date)
        )
      );
      setPrograms((prev) =>
        [...prev].sort(
          (a, b) => new Date(b.created_at) - new Date(a.start_date)
        )
      );
    } else if (e.target.value === "2") {
      setCourses((prev) =>
        [...prev].sort(
          (a, b) => new Date(a.created_at) - new Date(b.start_date)
        )
      );
      setPrograms((prev) =>
        [...prev].sort(
          (a, b) => new Date(a.created_at) - new Date(b.start_date)
        )
      );
    } else if (e.target.value === "3") {
      setCourses((prev) =>
        [...prev].sort((a, b) => b.ratings_avg_stars - a.ratings_avg_stars)
      );
      setPrograms((prev) =>
        [...prev].sort((a, b) => b.ratings_avg_stars - a.ratings_avg_stars)
      );
    } else if (e.target.value === "4") {
      setCourses((prev) => [...prev].sort((a, b) => a.price - b.price));
      setPrograms((prev) => [...prev].sort((a, b) => a.price - b.price));
    }
  };
  const searchParams = new URLSearchParams(location.search);

  const handleSearch = () => {
    const apiCall = getSearchResults;
    searchParams.set("q", inputSearchValue);
    // history.push({
    //   pathname: "/home-search",
    //   search: "?" + searchParams.toString(),
    // });
    setLoading(true);
    apiCall({
      search: inputSearchValue,
    })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCourses(res.data.data.courses);
          setPrograms(res.data.data.programs);
        }
        history.push({
          pathname: "/home-search",
          search: "?" + searchParams.toString(),
        });

        setLoading(false);
        setIsSearching(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleReset = () => {
    setInputSearchValue("");
    searchParams.set("q", "");
    history.push({
      pathname: "/home-search",
      search: "?" + searchParams.toString(),
    });
    handleSort({ target: { value: "1" } });
  };
  const searchValue = searchParams.get("q");

  useEffect(() => {
    setInputSearchValue(searchValue);
    const apiCall = getSearchResults;
    apiCall({
      search: inputSearchValue || searchValue,
    })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCourses(
            res.data.data.courses.sort(
              (a, b) => new Date(b.created_at) - new Date(a.start_date)
            )
          );
          setPrograms(
            res.data.data.programs.sort(
              (a, b) => new Date(b.created_at) - new Date(a.start_date)
            )
          );
          if (selectedDepartment === 2) {
            setSearchResults(res.data.data.courses);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (selectedDepartment === 1) {
      setSearchResults(programs);
    } else {
      setSearchResults(courses);
    }
  }, [courses, programs]);

  useEffect(() => {
    const apiCall = getSearchAutoComplete;
    apiCall()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setAvailableCourses(res.data.data);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="container-fluid">
          <div style={{ marginTop: "20px" }}>
            <div
              className={classes["training-course-back"]}
              onClick={() => history.goBack()}
            >
              <div className={classes["training-course-back-icon"]}>
                <img src={ArrowIcon} alt="back" />
              </div>
              <div className={classes["training-course-back-text"]}>
                {t("back")}
              </div>
            </div>
            <div className={classes["main__filter__container"]}>
              <div className={classes["main__filter"]}>
                <div className={classes["main__filter__search"]}>
                  <img src={SearchIcon} alt="" />
                  <div
                    className={classes["main__filter__search-field"]}
                    ref={searchAutoComplete}
                  >
                    <input
                      type="search"
                      placeholder={t("home.search_by_name")}
                      value={inputSearchValue}
                      onChange={(e) => {
                        setInputSearchValue(e.target.value);
                        if (e.target.value.length > 0) {
                          setIsSearching(true);
                        } else {
                          setIsSearching(false);
                        }
                      }}
                    />
                    {isSearching && (
                      <div
                        className={
                          classes["main__filter__search-field__auto-complete"]
                        }
                      >
                        <ul>
                          {availableCourses
                            .filter(
                              (course) =>
                                course.name
                                  .toLowerCase()
                                  .includes(inputSearchValue.toLowerCase()) ||
                                course.description
                                  .toLowerCase()
                                  .includes(inputSearchValue.toLowerCase()) ||
                                course.tags?.some((tag) =>
                                  tag
                                    .toLowerCase()
                                    .includes(inputSearchValue.toLowerCase())
                                )
                            )
                            .map((course) => (
                              <li
                                onClick={() => {
                                  setInputSearchValue(course.name);
                                  setIsSearching(false);
                                }}
                                key={course.id}
                              >
                                {course.name}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className={classes["main__filter__search-btn"]}
                  onClick={handleSearch}
                >
                  <p>{t("general.search")}</p>
                </button>
              </div>
            </div>
            <div className={classes["home_courses_header"]}>
              <h1 className={classes["home_courses_title"]}>
                {t(`search_results`)}
              </h1>
              <div className={classes["main__filter__actions"]}>
                <select
                  className={classes["main__filter__sort-select"]}
                  onChange={handleSort}
                  value={sortValue}
                >
                  <option value="1">{t("general.newest")}</option>
                  <option value="2">{t("general.oldest")}</option>
                  <option value="3">{t("general.rating_high_to_low")}</option>
                  <option value="4">{t("general.price_low_to_high")}</option>
                </select>
                <button
                  className={classes["main__filter__rest-btn"]}
                  onClick={handleReset}
                >
                  {t("general.clear")}
                </button>
              </div>
            </div>

            <MainBox
              className="border-8"
              style={{ width: "100%", marginBottom: "3rem" }}
            >
              <div className={classes["department_container"]}>
                <MainBoxHead title={t("courses")} />
                {courses?.length > 0 ? (
                  <div className={classes["courses_card_grid"]}>
                    {showMore ? (
                      <>
                        {courses.map((course) => (
                          <CourseCard
                            key={course.id}
                            levelbadge={displayLevel(Number(course.level))}
                            mode={course.mode}
                            price={course.price}
                            offer={
                              course?.offers?.length > 0 &&
                              course?.offers[0]?.status
                                ? course?.offers[0]
                                : false
                            }
                            img={course.img}
                            title={course.name}
                            briefDesc={course.brief_description}
                            date={course.start_date?.split(" ")[0]}
                            seat={course.available_seats}
                            trainers={
                              selectedDepartment === 2 && course.trainers
                            }
                            hour={course.number_hours}
                            handleSubmit={() => {
                              history.push(
                                `/${
                                  selectedDepartment === 1
                                    ? "training-programs"
                                    : "training-courses"
                                }/${course.id}/details`
                              );
                            }}
                            btn={
                              selectedDepartment === 1
                                ? t("program_details")
                                : t("course_details")
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {courses.slice(0, 3).map((course) => (
                          <CourseCard
                            key={course.id}
                            levelbadge={displayLevel(Number(course.level))}
                            mode={course.mode}
                            price={course.price}
                            offer={
                              course?.offers?.length > 0 &&
                              course?.offers[0]?.status
                                ? course?.offers[0]
                                : false
                            }
                            img={course.img}
                            title={course.name}
                            briefDesc={course.brief_description}
                            date={course.start_date?.split(" ")[0]}
                            seat={course.available_seats}
                            trainers={
                              selectedDepartment === 2 && course.trainers
                            }
                            hour={course.number_hours}
                            handleSubmit={() => {
                              history.push(
                                `/${
                                  selectedDepartment === 1
                                    ? "training-programs"
                                    : "training-courses"
                                }/${course.id}/details`
                              );
                            }}
                            btn={
                              selectedDepartment === 1
                                ? t("program_details")
                                : t("course_details")
                            }
                          />
                        ))}
                      </>
                    )}
                  </div>
                ) : (
                  <div className={classes["no_courses"]}>
                    <p>
                      {t(
                        `home.no_${
                          selectedDepartment === 1 ? "programs" : "courses"
                        }`
                      )}
                    </p>
                  </div>
                )}
                {courses.length > 3 && (
                  <CustomButton
                    value={
                      showMore
                        ? t("Exhibition.load_less")
                        : t("Exhibition.load_more")
                    }
                    colors={"#26b3b9"}
                    type={"button"}
                    variant={"outlined"}
                    classes={classes.btn}
                    action={() => {
                      setShowMore(!showMore);
                    }}
                  />
                )}
              </div>
              <div className={classes["department_container"]}>
                <MainBoxHead title={t("program")} />
                {programs?.length > 0 ? (
                  <div className={classes["courses_card_grid"]}>
                    {showMorePrograms ? (
                      <>
                        {programs.map((course) => (
                          <CourseCard
                            key={course.id}
                            levelbadge={displayLevel(Number(course.level))}
                            mode={course.mode}
                            price={course.price}
                            offer={
                              course?.offers?.length > 0 &&
                              course?.offers[0]?.status
                                ? course?.offers[0]
                                : false
                            }
                            img={course.img}
                            title={course.name}
                            briefDesc={course.brief_description}
                            date={course.start_date?.split(" ")[0]}
                            seat={course.available_seats}
                            trainers={
                              selectedDepartment === 2 && course.trainers
                            }
                            hour={course.number_hours}
                            handleSubmit={() => {
                              history.push(
                                `/${
                                  selectedDepartment === 1
                                    ? "training-programs"
                                    : "training-courses"
                                }/${course.id}/details`
                              );
                            }}
                            btn={
                              selectedDepartment === 1
                                ? t("program_details")
                                : t("course_details")
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {programs.slice(0, 3).map((course) => (
                          <CourseCard
                            key={course.id}
                            levelbadge={displayLevel(Number(course.level))}
                            mode={course.mode}
                            price={course.price}
                            offer={
                              course?.offers?.length > 0 &&
                              course?.offers[0]?.status
                                ? course?.offers[0]
                                : false
                            }
                            img={course.img}
                            title={course.name}
                            briefDesc={course.brief_description}
                            date={course.start_date?.split(" ")[0]}
                            seat={course.available_seats}
                            trainers={
                              selectedDepartment === 2 && course.trainers
                            }
                            hour={course.number_hours}
                            handleSubmit={() => {
                              history.push(
                                `/${
                                  selectedDepartment === 1
                                    ? "training-programs"
                                    : "training-courses"
                                }/${course.id}/details`
                              );
                            }}
                            btn={
                              selectedDepartment === 1
                                ? t("program_details")
                                : t("course_details")
                            }
                          />
                        ))}
                      </>
                    )}
                  </div>
                ) : (
                  <div className={classes["no_courses"]}>
                    <p>
                      {t(
                        `home.no_${
                          selectedDepartment === 1 ? "programs" : "courses"
                        }`
                      )}
                    </p>
                  </div>
                )}
                {courses.length > 3 && (
                  <CustomButton
                    value={
                      showMorePrograms
                        ? t("Exhibition.load_less")
                        : t("Exhibition.load_more")
                    }
                    colors={"#26b3b9"}
                    type={"button"}
                    variant={"outlined"}
                    classes={classes.btn}
                    action={() => {
                      setShowMorePrograms(!showMorePrograms);
                    }}
                  />
                )}
              </div>
            </MainBox>
          </div>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}
