import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import ManageDepartmentsAction from "./ManageDepartmentsAction";
import classes from "./manageDepartments.module.css";

const ManageDepartments = ({ type, submitHandler, departmentDetails }) => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const specialtyId = state?.specialtyId;
  const specialtyName = state?.specialtyName;
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(specialtyId
      ? [
          {
            id: "specialties",
            page: t("specialties"),
            pagePath: "/Admin/category",
          },
          {
            id: "create_exhibition",
            page: specialtyName,
            pagePath: `/Admin/category/${specialtyId}`,
          },
        ]
      : [
          {
            id: "departments",
            page: t("departments"),
            pagePath: "/Admin/department",
          },
        ]),
    {
      id: "create_exhibition",
      page:
        type === "create"
          ? t("department_manage.add_new_department")
          : i18n.language == Lang.AR
          ? departmentDetails?.name_ar
          : departmentDetails?.name_en,
      active: true,
    },
  ];

  return (
    <div className={classes["manage-departments"]}>
      <div className="container-fluid">
        <div className={classes["manage-departments__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <Formik
                  initialValues={{
                    name_ar: departmentDetails?.name_ar || "",
                    name_en: departmentDetails?.name_en || "",
                    description_ar: departmentDetails?.description_ar || "",
                    description_en: departmentDetails?.description_en || "",
                  }}
                  onSubmit={async (values, { setSubmitting, setErrors }) => {
                    let errorObj = {};
                    try {
                      if (specialtyId) {
                        values.specialty_id = specialtyId;
                      }
                      await submitHandler(values);
                      setSubmitting(false);
                    } catch (err) {
                      if (err.response.data.errors && err.response.data.errors.name_en) {
                        errorObj.name_en = err.response.data.errors.name_en;
                      }
                      if (err.response.data.errors && err.response.data.errors.name_ar) {
                        errorObj.name_ar = err.response.data.errors.name_ar;
                      }
                      if (Object.keys(errorObj).length !== 0) {
                        setErrors(errorObj);
                      }
                      toast.error(
                        <>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              display: "block",
                              paddingBottom: "10px",
                            }}
                          >
                            {err.response.data.msg}
                          </span>
                          <ul>
                            {Object.keys(err.response.data.errors).map((key) => {
                              return (
                                <li>
                                  <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      );
                    }
                  }}
                  validateOnChange={true}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name_ar) {
                      errors.name_ar = t("crud.errors.required");
                    }

                    if (!values.name_en) {
                      errors.name_en = t("crud.errors.required");
                    }

                    if (!values.description_ar) {
                      errors.description_ar = t("crud.errors.required");
                    }

                    if (!values.description_en) {
                      errors.description_en = t("crud.errors.required");
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    handleBlur,
                    setFieldValue,
                    validateForm,
                  }) => (
                    <form onSubmit={handleSubmit} className={classes["manage-departments__form"]}>
                      <MainBoxHead
                        title={
                          type === "create"
                            ? t("department_manage.add_new_department")
                            : t("department_manage.edit_new_department")
                        }
                      />
                      <div className={`row ${classes["manage-departments__form__fields"]}`}>
                        <div className={`${classes["manage-departments__form__field"]} col-md-6 col-12`}>
                          <Input
                            label={t("department_manage.department_name_ar")}
                            type="text"
                            name="name_ar"
                            placeholder={t("department_manage.department_name_ar")}
                            onChange={handleChange}
                            error={errors.name_ar}
                            value={values.name_ar}
                          />
                        </div>
                        <div className={`${classes["manage-departments__form__field"]} col-md-6 col-12`}>
                          <Input
                            label={t("department_manage.department_name_en")}
                            type="text"
                            name="name_en"
                            placeholder={t("department_manage.department_name_en")}
                            onChange={handleChange}
                            error={errors.name_en}
                            value={values.name_en}
                          />
                        </div>
                        <div className={`${classes["manage-departments__form__field"]} col-12`}>
                          <label htmlFor="description_ar">{t("department_manage.department_description_ar")}</label>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            onReady={(editor) => {}}
                            onChange={(event, editor) => {
                              // handleText("description_ar", editor);
                              const data = editor.getData();
                              setFieldValue("description_ar", data);
                            }}
                            required={true}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                            data={values.description_ar}
                            config={{
                              placeholder: t(
                                "department_manage.department_description_ar"
                              ),
                            }}
                          /> */}
                          <SunEditor
                            placeholder={t("department_manage.department_description_ar")}
                            value={values.description_ar}
                            defaultValue={values.description_ar}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "description_en", value: e },
                              });
                            }}
                            setOptions={{
                              height: 150,
                              rtl: i18n.language === "ar" ? true : false,
                              buttonList: [
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "lineHeight",
                                  "codeView",
                                ],
                              ],
                            }}
                            setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                          />
                          {errors.description_ar && (
                            <p className={`form-input-error-space ${classes["manage-departments__form-error"]}`}>
                              {errors.description_ar}
                            </p>
                          )}
                        </div>
                        <div className={`${classes["manage-departments__form__field"]} col-12`}>
                          <label htmlFor="description_en">{t("department_manage.department_description_en")}</label>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            onReady={(editor) => {}}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("description_en", data);
                            }}
                            required={true}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                            data={values.description_en}
                            config={{
                              placeholder: t(
                                "department_manage.department_description_en"
                              ),
                            }}
                          /> */}
                          <SunEditor
                            placeholder={t("department_manage.department_description_en")}
                            value={values.description_en}
                            defaultValue={values.description_en}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "description_en", value: e },
                              });
                            }}
                            setOptions={{
                              height: 150,
                              rtl: i18n.language === "ar" ? true : false,
                              buttonList: [
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "lineHeight",
                                  "codeView",
                                ],
                              ],
                            }}
                            setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                          />
                          {errors.description_en && (
                            <p className={`form-input-error-space ${classes["manage-departments__form-error"]}`}>
                              {errors.description_en}
                            </p>
                          )}
                        </div>
                        <ManageDepartmentsAction
                          submitAction={handleSubmit}
                          isSubmitting={isSubmitting}
                          text={type === "create" ? t("general.submit") : t("general.save")}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDepartments;
