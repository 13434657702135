import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { getOfferById, postOfferStatus } from "../../../../../Redux/Actions/offer/offer.action";
import toLocalTimezone, { toServerTime } from "../../../../../Services/Timezone";
import { getAllCourses } from "../../../../../Services/api/CoursesList";
import { putOffer } from "../../../../../Services/api/Financial/FinancialProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import { formatDate, formatTime } from "../../../../../utils/formatDate";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../../Shared/Components/DataTable/DataTable";
import Input from "../../../../Shared/Components/Input/Input";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import EditAction from "./EditAction";
import classes from "./edit.module.css";

const Edit = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { get_offer_by_id } = useSelector((state) => state.offerReducer);

  const [isLoading, setIsLoading] = useState(true);
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [offer, setOffer] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState("canEdit");

  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "offer",
      page: t("financial.offer.offer"),
      pagePath: `${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/offer`,
    },
    {
      id: "offer",
      page: t("financial.offer.edit_offer"),
      active: true,
    },
  ];

  const mechanismOptions = [
    {
      value: 2,
      label: t("coupon.label.per_value"),
    },
    {
      value: 1,
      label: t("coupon.label.co_value"),
    },
  ];

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
    },
  ];

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1, is_program: 0 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  useEffect(() => {
    currentDate();
    dispatch(getOfferById(id));
  }, []);

  useEffect(() => {
    if (get_offer_by_id.status === 200) {
      setOffer(get_offer_by_id.data.response.offer);
      setType(
        get_offer_by_id.data.response.offer.is_valid
          ? moment(new Date()).isBefore(getLocaleTime(new Date(get_offer_by_id.data.response.offer?.end_date)))
            ? "limitedEdit"
            : "cannotEdit"
          : "canEdit"
      );
      getAllCoursesProgramsHandler({
        is_program: get_offer_by_id.data.response.offer.parent_type === 1 ? false : true,
      });
      setStatus(get_offer_by_id.data.response.offer.status);
      setIsLoading(false);
    }
  }, [get_offer_by_id]);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };

  const coursesFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];

  const programsFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];

  return (
    <div className={classes["create_coupon"]}>
      <div className="container-fluid">
        <div className={classes["create_coupon__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading ? (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      name: offer.name == null ? "" : offer.name,
                      notes: offer.notes == null ? "" : offer.notes,
                      value: offer.value == null ? "" : offer.value,
                      mechanism: offer.mechanism == null ? "1" : offer.mechanism,
                      parentType: offer.parent_type == null ? 1 : offer.parent_type,
                      parent_id: offer.parent.id,
                      start_date: offer?.start_date
                        ? new Date(getLocaleTime(new Date(offer?.start_date)))
                        : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                      end_date: offer?.end_date
                        ? new Date(getLocaleTime(new Date(offer?.end_date)))
                        : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let dataToBeUploaded = {
                          ...values,
                        };
                        dataToBeUploaded.start_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.start_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.start_date))
                        );

                        dataToBeUploaded.end_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.end_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.end_date))
                        );

                        delete dataToBeUploaded.parent_id;
                        delete dataToBeUploaded.parentType;

                        if (type === "limitedEdit") {
                          // delete dataToBeUploaded.notes;
                        }

                        let response = await putOffer(id, dataToBeUploaded);
                        if ((response.status === 201 || response.status === 200) && response.data.status) {
                          setIsLoading(true);
                          history.push(
                            `${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/offer`
                          );
                          setIsLoading(false);
                        } else {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {response.data.message ? response.data.message : "Failure in service"}
                            </span>
                          );
                          setSubmitting(false);
                        }
                      } catch (err) {
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map((key) => {
                              return <span style={{ fontSize: 12 }}>{err.response.data.errors[key]}</span>;
                            })}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      if (!values.notes) {
                        errors.notes = t("crud.errors.required");
                      }

                      if (!values.start_date) {
                        errors.start_date = t("crud.errors.required");
                      }

                      if (!values.end_date) {
                        errors.end_date = t("crud.errors.required");
                      }

                      if (!values.value) {
                        errors.value = t("crud.errors.required");
                      }

                      if (!values.parentType) {
                        errors.parentType = t("crud.errors.required");
                      }

                      if (!values.parent_id) {
                        errors.parent_id = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form onSubmit={handleSubmit} className={classes["create_coupon__form"]}>
                        {type === "cannotEdit" && (
                          <div className={classes["offer__warning__note"]}>{t("financial.offer.cannot_edit_note")}</div>
                        )}
                        {type === "limitedEdit" && (
                          <div className={classes["offer__warning__note"]}>
                            {t("financial.offer.limited_edit_note")}
                          </div>
                        )}
                        <MainBoxHead
                          title={t("financial.offer.edit_offer")}
                          actions={[
                            {
                              id: "course-visibility",
                              type: "switch",
                              value: true,
                              active: t("trainer.course.active"),
                              inactive: t("trainer.course.inactive"),
                              checked: status,

                              onChange: (event) => {
                                dispatch(postOfferStatus(offer?.id));
                                setStatus((prev) => !prev);
                                setFieldValue("is_visible", event.target.checked ? 1 : 0);
                              },
                            },
                          ]}
                        />
                        <div className={`row ${classes["create_coupon__form__fields"]}`}>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <Input
                              label={t("financial.offer.name")}
                              type="text"
                              name="name"
                              placeholder={t("financial.offer.name")}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                              disabled={type === "cannotEdit"}
                            />
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <Input
                              label={t("note_for_user")}
                              type="text"
                              name="notes"
                              placeholder={t("note_for_user")}
                              onChange={handleChange}
                              error={errors.notes}
                              value={values.notes}
                              disabled={type === "cannotEdit" || type === "limitedEdit"}
                            />
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <label htmlFor="mechanism">{t("financial.label.discount_type")}</label>
                            <Select
                              isDisabled={type === "cannotEdit" || type === "limitedEdit"}
                              name="mechanism"
                              id="mechanism"
                              options={mechanismOptions}
                              value={mechanismOptions.find((option) => option.value == values.mechanism)}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("mechanism", item.value);
                              }}
                              placeholder={t("financial.label.discount_type")}
                            />
                            <p className={"form-input-error-space"}>{errors.mechanism ? errors.mechanism : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            {values.mechanism == 1 ? (
                              <Input
                                disabled={type === "cannotEdit" || type === "limitedEdit"}
                                label={t("financial.label.percentage")}
                                type="number"
                                name="value"
                                placeholder={t("financial.label.percentage")}
                                onChange={handleChange}
                                error={errors.value}
                                min={0.01}
                                max={100}
                                value={values.value}
                              />
                            ) : (
                              <Input
                                disabled={type === "cannotEdit" || type === "limitedEdit"}
                                label={t("financial.label.percentage")}
                                type="number"
                                name="value"
                                placeholder={t("financial.label.percentage")}
                                onChange={handleChange}
                                error={errors.value}
                                min={0.01}
                                value={values.value}
                              />
                            )}
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              readOnly={type === "cannotEdit" || type === "limitedEdit"}
                              label={t("general.start_date_time")}
                              name="start_date"
                              error={errors.start_date}
                              value={values.start_date}
                              onChange={(date) => {
                                setFieldValue("start_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                            />
                            <p className={"form-input-error-space"}>{errors.start_date ? errors.start_date : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              readOnly={type === "cannotEdit"}
                              label={t("general.end_date_time")}
                              name="end_date"
                              error={errors.end_date}
                              value={values.end_date}
                              onChange={(date) => {
                                setFieldValue("end_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setHoursDatepickerEnd(0);
                                  setMinutesDatepickerEnd(0);
                                } else {
                                  currentDateEnd();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                            />
                            <p className={"form-input-error-space"}>{errors.end_date ? errors.end_date : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <label htmlFor="course_program">{t("coupon.label.select_course_or_program")}</label>
                            <Select
                              isDisabled
                              name="course_program"
                              id="course_program"
                              options={parentTypeOptions}
                              value={parentTypeOptions.find((option) => option.id == values.parentType)}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              onChange={(item) => {
                                setFieldValue("parentType", item.id);
                                setFieldValue("parent_id", "");
                              }}
                              placeholder={t("coupon.label.select_course_or_program")}
                            />
                            <p className={"form-input-error-space"}>{errors.parentType ? errors.parentType : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <label htmlFor="courses_programs">
                              {values.parentType == 1
                                ? t("coupon.label.select_course")
                                : t("coupon.label.select_program")}
                            </label>
                            <DataTable
                              selectedItems={[values.parent_id]}
                              isLoading={values.parentType == 1 ? isCoursesLoading : isProgramsLoading}
                              data={values.parentType == 1 ? courses : programs}
                              fields={values.parentType == 1 ? coursesFields : programsFields}
                              isMultiple={false}
                              searchableFields={
                                values.parentType == 1 ? ["id", "name", "end_register_date"] : ["id", "name"]
                              }
                              selectedHandler={(item) => {
                                setFieldValue("parent_id", item?.id);
                              }}
                              disabled={true}
                            />
                            <p className={"form-input-error-space"}>{errors.parent_id ? errors.parent_id : null}</p>
                          </div>
                          <EditAction type={type} submitAction={handleSubmit} isSubmitting={isSubmitting} />
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          ) : (
            <SkeletonCardOverlay skeletonWidth="100" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Edit;
