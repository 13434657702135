import classes from "./tableActionsColumn.module.css";

const TableActionsColumn = ({ actions, cantEdit, id, name }) => {
  return (
    <div className={classes["table-actions"]}>
      {actions.map((action, index) => {
        return (
          <button
            key={action.id}
            className={classes["table-actions-btn"]}
            title={action.tooltip}
            onClick={() => {
              if (action.id === "edit" && cantEdit) {
                return;
              }
              action.action(id, name);
            }}
            disabled={action.id === "edit" && cantEdit}
          >
            {action.icon}
          </button>
        );
      })}
    </div>
  );
};

export default TableActionsColumn;
