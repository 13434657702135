import { Link, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BoxCard, MainBtn } from "../../Shared/Components";
import { ContactArrow } from "../../Shared/icons";

const ContactInfoBox = ({ icon, title, subTitle, btnText, href }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <BoxCard
      py={{ xs: "25px", md: "45px" }}
      px={{ xs: "25px", md: "35px" }}
      sx={{
        flexDirection: { xs: "column", md: "row" },
        gap: "36px",
        alignItems: { xs: "flex-start", md: "center" },
      }}
    >
      <Stack
        width={118}
        height={118}
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={"50%"}
        border={`4px solid ${theme.palette.primary.light}`}
        flexShrink={0}
      >
        {icon}
      </Stack>
      <Stack spacing={"5px"}>
        <Typography variant="h5" color={"primary.main"} fontWeight={500} fontFamily="dinNextRegular">
          {t(title)}
        </Typography>
        <Typography variant="subtitle2" color={"grey.100"} fontFamily="dinNextRegular">
          {t(subTitle)}
        </Typography>
        <MainBtn
          sx={{
            mt: "20px !important",
            display: " flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "0px !important",
          }}
          width={213}
          height={51}
          borderRadius={"6px"}
          fontFamily="dinNextRegular"
        >
          <Link
            href={href}
            underline="none"
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="body1" color={"white"} fontFamily="dinNextRegular" dir={"ltr"}>
              {t(btnText)}
            </Typography>
            <ContactArrow fill={"#fff"} />
          </Link>
        </MainBtn>
      </Stack>
    </BoxCard>
  );
};

export default ContactInfoBox;
