import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "./CarosalHomePage.module.css";
import { useHistory } from "react-router";
import Img from "../../../assets/image/teal-ribbon-near-stethoscope-lumber-background.jpg";
import CaroselSlide from "./CaroselSlide";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import MainSectionCarousel from "../../Shared/Components/MainSectionCarousel/MainSectionCarousel";
import {
  getAdHome,
  getAdHomepage,
} from "../../../Services/api/HomeCoursesProvider";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";

const CarosalHomePage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [adList, setAdList] = useState([]);
  const [adListLoading, setAdListLoading] = useState(false);

  useEffect(() => {
    // setTrainersLoading(true);

    const apiCall = getAdHomepage;

    apiCall()
      .then((res) => {
        if (res.status && res.status === 200) {
          // console.log(res);
          setAdList(res.data.data);
          setAdListLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdListLoading(true);
      });
  }, []);

  return (
    <>
      {adListLoading ? (
        <div className={classes.text_img}>
          {adList.length === 0 ? (
            <></>
          ) : (
            <>
              {adList.length === 1 ? (
                <>
                  {adList.map((ad) => (
                    <>
                      <div
                        className={classes["main"]}
                        style={{
                          backgroundImage: `url(${ad?.image})`,
                        }}
                      >
                        <div className={classes.backdropDiv}>
                          <div className={classes["main__wrapper"]}>
                            {ad?.show_content !== "0" && (
                              <div className={classes["main__content"]}>
                                <h2 className={classes["main__content-title"]}>
                                  {ad?.title}
                                </h2>
                                <p
                                  className={classes["main__content-desc"]}
                                  style={
                                    ad?.description?.length > 710
                                      ? { fontSize: "14px" }
                                      : {}
                                  }
                                >
                                  {ad?.description}
                                </p>
                              </div>
                            )}
                            {ad?.has_button && (
                              <div className={classes["search_container"]}>
                                <CustomButton
                                  value={ad?.button_text}
                                  colors={"#fff"}
                                  classes={classes.btnRegister}
                                  action={() => window.open(ad?.url, "_blank")}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <MainSectionCarousel>
                    {adList.map((ad, index) => (
                      <CaroselSlide
                        ad={ad}
                        adListLoading={adListLoading}
                        key={index}
                        id={ad.id}
                      />
                    ))}
                  </MainSectionCarousel>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              height: "600px",
              width: "90%",
            }}
          >
            <SkeletonSpinnerOverlay
              backdrop={true}
              skeletonWidth="100"
              skeletonHight="100"
            />
          </div>
          {/* <div
            style={{
              width: "200px",
            }}
          >
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
          </div> */}
        </div>
      )}
    </>
  );
};

export default CarosalHomePage;
