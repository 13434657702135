export const walletConstants = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",

  GET_USER_WALLET_BY_ID: "GET_USER_WALLET_BY_ID",
  GET_USER_WALLET: "GET_USER_WALLET",

  POST_PAY: "POST_PAY",
  POST_TRANSACTION: "POST_TRANSACTION",

  POST_IBAN: "POST_IBAN",
};
