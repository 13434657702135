import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  removeUserFromTicket,
  ticketTypeAssignUsers,
  ticketTypesAssignToUserData,
  ticketUsers,
} from "../../../../../Services/api/Tickets/TicketsProvider";
import { getAllUsers } from "../../../../../Services/api/survey/SurveyProvider";
import { ReactComponent as CroseIcon } from "../../../../../assets/icons/crose.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus-square.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import "./AssignToUser.css";

function AssignTicketTypeToUser(props) {
	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams();

	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [statuses, setStatuses] = useState([]);
	const [fileTypes, setFileTypes] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [abilities, setAbilities] = useState([]);
	const [assignees, setAssignees] = useState([]);
	const [formattedAssignees, setFormattedAssignees] = useState([]);
	const [fieldRequirements, setFieldRequirements] = useState([]);
	const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
	const [users, setUsers] = useState([]);

	const initialUserRowData = {
		department: "",
		user: "",
		search: "",
		abilities: [],
	};

	useEffect(() => {
		async function executeOnLoad() {
			let response = await ticketTypesAssignToUserData(id);
			if (response.status && response.status == 200) {
				setAssignees(response.data.data.assignees);
				setAbilities(response.data.data.abilities);
				setDepartments(response.data.data.departments);
				if (response.data.data.assignees.length == 0) {
					setIsLoading(false);
				}
			}
		}

		fetchUsers();

		executeOnLoad();
	}, []);

	const reformatAssignees = async () => {
		let filteredAssignees = [];
		await assignees.forEach((item) => {
			const filteredAssigneesItem = {};
			filteredAssigneesItem.department = item.department?.id;
			filteredAssigneesItem.user = item.user;
			filteredAssigneesItem.search = item.search;
			filteredAssigneesItem.id = item.id;
			filteredAssigneesItem.abilities = item.abilities.map((entity) => entity.value);
			filteredAssignees.push(filteredAssigneesItem);
		});
		await setFormattedAssignees(filteredAssignees);
		setIsLoading(false);
	};

	useEffect(() => {
		if (assignees && assignees.length > 0) {
			reformatAssignees();
		}
	}, [assignees]);

	const removeUser = (id) => {
		removeUserFromTicket(id)
			.then(() => {
				toast.success(
					<span style={{ fontSize: 13, fontWeight: "bold" }}>
						{t("support.tickets.success.remove_user_from_ticket")}
					</span>
				);
			})
			.catch((err) => {
				toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_deleting")}</span>);
			});
	};

	const fetchUsers = async (search) => {
		try {
			setUsers([]);
			let response = await getAllUsers({
				search: search ? search : null,
				"users-for-tickets": true,
				ticket_type_id: id,
			});
			if (response.status == 200) {
				setUsers(response.data.users);
			} else {
				throw response;
			}
		} catch (err) {
			
			toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
		}
	};

	function breadcrumbList() {
		let breadcrumbList = [
			{
				id: t("admin.label.admin_label"),
				page: t("admin.label.admin_label"),
				pagePath: "/admin",
			},
			{
				id: t("admin.label.list_tickets"),
				page: t("admin.label.list_tickets"),
				pagePath: `/admin/tickets`,
			},
			{
				id: t("admin.label.list_ticket_types"),
				page: t("admin.label.list_ticket_types"),
				pagePath: `/admin/ticket/types`,
			},
		];
		breadcrumbList.push({
			id: t("support.tickets_type.support_representatives"),
			page: t("support.tickets_type.support_representatives"),
			active: true,
		});
		return breadcrumbList;
	}

	function SearchUser({ values, setFieldValue, push, remove, index, initialUsers = [] }) {
		const [users, setUsers] = useState(initialUsers);
		const [userFetching, setUserFetching] = useState(false);
		const fetchUsers = async (search, selectedId) => {
			try {
				setUserFetching(true);
				setUsers([]);
				let response = await getAllUsers({
					search: search ? search : null,
					includes: selectedId ? [selectedId] : null,
					"users-for-tickets": true,
				});
				if (response.status == 200) {
					setUsers(response.data.users);
					setUserFetching(false);
				} else {
					throw response;
				}
			} catch (err) {
				
				toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
				setUserFetching(false);
			}
		};
		return (
			<div className="tw-border tw-rounded tw-divide-y">
				<div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
					<div className="tw-font-semibold tw-text-gray-500">{t("select_name")}</div>
					<div className="tw-flex tw-items-center tw-space-s-2">
						<Field
							name={`users.${index}.search`}
							className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent search-field"
							placeholder={t("search_by_name_or_email")}
							onChange={({ target: { value } }) => {
								setFieldValue(`users.${index}.search`, value);
								fetchUsers(value, values.users[index].user.id);
							}}
						/>
						<i className="fal fa-search tw-text-teal-500 tw-w-6"></i>
					</div>
				</div>
				<div className="tw-py-4 tw-pe-4">
					<div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
						{userFetching ? (
							[...Array(5).keys()].map((i) => (
								<div
									key={i}
									className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
								>
									<div className="tw-flex tw-items-center tw-space-s-4">
										<div className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}></div>
										<div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
									</div>
									<div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
								</div>
							))
						) : users.length ? (
							users.map((user) => (
								<button
									key={user.id}
									type="button"
									onClick={() => {
										setFieldValue(`users.${index}.user`, user);
									}}
									className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-w-full`}
								>
									<div className="tw-flex tw-items-center tw-space-s-4">
										<div
											className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded-full  ${
												values.users[index].user.id == user.id
													? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
													: "tw-border-gray-300"
											}`}
										></div>
										<div>{user.name}</div>
									</div>
									<div>{user.email}</div>
								</button>
							))
						) : (
							<div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">{t("there_no_matching_users")}</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-12 sm:tw-px-2 tw-py-8">
								{/*TODO*/}
								<Breadcrumb list={breadcrumbList()} />
							</div>
						</div>
						{isLoading ? (
							<div
								style={{
									height: "65vh",
								}}
							>
								<SkeletonCard />
							</div>
						) : (
							<div className="tw-relative tw-mb-8">
								<Formik
									initialValues={{
										users:
											formattedAssignees.length === 0
												? [
														{
															...initialUserRowData,
														},
												  ]
												: formattedAssignees,
									}}
									enableReinitialize={true}
									onSubmit={async (values) => {
										try {
											setGlobalIsSubmitting(true);
											let formData = new FormData();
											values.users.forEach((item, index) => {
												formData.append(`users[${index}][user]`, item.user.id);
												item.abilities.forEach((ability, abilityIndex) => {
													formData.append(`users[${index}][abilities][${abilityIndex}]`, ability);
												});
											});
											let response = await ticketTypeAssignUsers(formData, id);
											if (response.status == 200 && response.data.status) {
												history.push("/admin/ticket/types");
											} else {
												toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_deleting")}</span>);
											}
											setGlobalIsSubmitting(false);
										} catch (err) {
											toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_deleting")}</span>);

											
											setGlobalIsSubmitting(false);
										}
									}}
									validateOnChange={hasSubmitted}
									validate={async (values) => {
										setHasSubmitted(true);
										const errors = {};
										// if (values.users.length) {
										const userErrors = [];

										await values.users.forEach((item, index) => {
											const errorInstance = {};

											if (!item.user) {
												errorInstance.user = t("crud.errors.required");
											}

											if (item.abilities.length == 0) {
												errorInstance.abilities = t("crud.errors.required");
											}

											if (Object.keys(errorInstance).length !== 0) {
												userErrors[index] = errorInstance;
											}
										});

										if (userErrors.length > 0) {
											errors.users = userErrors;
										}
										// } else {
										// 	errors.users = t("crud.errors.required");
										// }

										return errors;
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<form onSubmit={handleSubmit} className="admin_add_form">
											{globalIsSubmitting ? <SkeletonCardOverlay borderRadius={0} /> : null}

											<FieldArray name={`users`}>
												{({ insert, remove, push }) => (
													<div className="tw-space-y-8">
														{values.users?.length ? (
															values.users.map((item, index) => (
																<div key={index} className="tw-bg-white tw-shadow tw-rounded tw-p-8">
																	<div className="tw-flex tw-items-center tw-justify-end">
																		<button
																			type="button"
																			disabled={!values?.users?.length}
																			onClick={async () => {
																				remove(index);
																			}}
																			className={values?.users?.length ? "" : "tw-hidden"}
																		>
																			<CroseIcon className="tw-w-5 tw-h-5" />
																		</button>
																	</div>
																	<div className="tw-space-y-4">
																		<div>
																			<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																				{t("support.tickets_type.support_representative")}
																			</div>
																			{item.id ? (
																				<input
																					id={`users.${index}.user`}
																					value={item.user.name}
																					className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																					disabled
																				/>
																			) : (
																				<SearchUser
																					values={values}
																					setFieldValue={setFieldValue}
																					remove={remove}
																					push={push}
																					index={index}
																					initialUsers={users}
																				/>
																			)}
																		</div>
																		<div>
																			<label className="tw-block mb-0">
																				<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																					{t("support.tickets_type.roles")}
																				</div>

																				<Select
																					name={`users.${index}.abilities`}
																					id={`users.${index}.abilities`}
																					options={abilities}
																					getOptionLabel={(option) => option.text}
																					getOptionValue={(option) => option.value}
																					onChange={(items) => {
																						let selectedItems = [];
																						items.forEach((item) => {
																							selectedItems.push(item.value);
																						});
																						setFieldValue(`users.${index}.abilities`, selectedItems);
																					}}
																					value={abilities.filter((item) => {
																						if (values.users[index].abilities.includes(item.value)) {
																							return item;
																						}
																					})}
																					isMulti={true}
																					placeholder={t("crud.placeholders.select.abilities")}
																					styles={{
																						control: (baseStyles) => ({
																							...baseStyles,
																							height: "auto",
																						}),
																					}}
																				/>
																			</label>
																			<ErrorMessage
																				name={`users.${index}.abilities`}
																				component="div"
																				className="tw-text-xs tw-text-red-700 tw-h-4"
																			/>
																		</div>
																	</div>
																</div>
															))
														) : (
															<></>
														)}

														<div className="tw-relative tw-py-4">
															<hr className="tw-border-gray-300 tw-border-dashed" />
															<button
																type="button"
																onClick={() => push({ ...initialUserRowData })}
																className="tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-[#f7f7f7] tw-px-3 tw-text-teal-700"
															>
																<div>
																	<PlusIcon className="tw-stroke-[#046c77] tw-h-4 tw-w-4" />
																</div>
																<div>{t("support.tickets_type.add_support_representative")}</div>
															</button>
														</div>
													</div>
												)}
											</FieldArray>
											<div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4 tw-bg-white tw-shadow tw-rounded tw-px-8 tw-py-4 tw-mt-8">
												<button
													type="submit"
													disabled={isSubmitting}
													className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
												>
													{t("crud.placeholders.submit")}
												</button>
											</div>
										</form>
									)}
								</Formik>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default AssignTicketTypeToUser;
