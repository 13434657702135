import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import IconTooltips from '../../../../Components/tooltips/IconsTooltip';
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { exportOrder } from "../../../../Services/api/Financial/FinancialProvider";
import { getTotalIncomeReport } from "../../../../Services/api/report/ReportProvider";
import Pdf from "../../../../assets/image/rating/pdf.png";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Xls from './../../../../assets/image/rating/xls.png';

function TotalIncomeReport(props) {
    require("./totalIncomeReport.css");
    const {t} = useTranslation();
    const history = useHistory();

    const [order, setOrder] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [meta, setMeta] = useState({});
    const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
        useState(false);
    const [currentOrder, setCurrentOrder] = useState([]);
    const [filter, setFilter] = useState({});

    const [status, setStatus] = useState([
        {text: t("financial.label.approved"), value: 1},
        {text: t("financial.label.canceled"), value: 2},
        {text: t("financial.label.waiting"), value: 3},
        {text: t("financial.label.refund"), value: 4},
        {text: t("financial.label.sponsor"), value: 5},
    ]);

    const [paymentMethod, setPaymentMethod] = useState([
        {text: t("financial.label.bankTrasfer"), value: "BankTrasfer"},
        {text: t("financial.label.stripe"), value: "Stripe"},
        {text: t("financial.label.freeCourse"), value: "FreeCourse"},
    ]);

    const fetchReportData = (params = null) => {
        const myPromise = new Promise(async (resolve, reject) => {
            try {
                let response = await getTotalIncomeReport(clearEmptyProp(params));
                if (response.status == 200) {
                    setOrder(response.data.response.data);
                    setMeta(response.data.response.meta);
                    setIsLoading(false);
                }
                resolve();
            } catch (e) {
                reject(e);
            }
        });

        return myPromise;
    };

    useEffect(async () => {
        try {
            await fetchReportData(filter);
        } catch (e) {
            toast.error(
                <span style={{fontSize: 13, fontWeight: "bold"}}>
          {t("failed_fetching")}
        </span>
            );
        }
    }, []);

    useEffect(() => {
        setCurrentOrder(order && order);
    }, [order]);

    const handlePageClick = (event) => {
        const toBeUpdatedFilters = filter;
        toBeUpdatedFilters.page = event.selected + 1;
        setFilter(toBeUpdatedFilters);

        fetchReportData(toBeUpdatedFilters);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="admin_label">
                    <NavLink
                        to={`${
                            localStorage.getItem("type") == "accountant"
                                ? "/accountant"
                                : "/admin"
                        }`}
                    >
                        {t("admin.label.admin_label")}
                    </NavLink>
                    <p style={{padding: "0 5px"}}>/</p>
                    {t("financial.label.report")}
                </div>
                {isPerformingBackgroundOperation && (
                    <SkeletonCardOverlay borderRadius={10}/>
                )}
                {isLoading == false ? (
                    <>
                        {showFilters == true ? (
                            <div
                                className={`tajah_table_fade_change ${
                                    showFilters && "filter_opacity"
                                }`}
                                style={{}}
                            >
                                <Formik
                                    initialValues={{
                                        resource_name: "",
                                    }}
                                    onSubmit={async (values, {setSubmitting}) => {
                                        try {
                                            setIsPerformingBackgroundOperation(true);
                                            setFilter(values);
                                            await fetchReportData(values);
                                            setIsPerformingBackgroundOperation(false);
                                        } catch (e) {
                                            toast.error(
                                                <>
                          <span
                              style={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                  display: "block",
                                  paddingBottom: "10px",
                              }}
                          >
                            {e.response.data.msg}
                          </span>
                                                    {e.response.data.errors ? (
                                                        <ul>
                                                            {Object.keys(e.response.data.errors).map(
                                                                (key) => {
                                                                    return (
                                                                        <li>
                                      <span style={{fontSize: 11}}>
                                        {e.response.data.errors[key]}
                                      </span>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    ) : null}
                                                </>
                                            );
                                        }
                                    }}
                                    validateOnChange={false}
                                >
                                    {({values, handleSubmit, setFieldValue}) => (
                                        <form onSubmit={handleSubmit} className={"table_bank_tran"}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    width: "90%",
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: "rgba(0,0,0,0.3)",
                                                    borderBottomStyle: "solid",
                                                    height: 40,
                                                }}
                                            >
                                                <h5>{t("Filter")}</h5>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-around",
                                                    width: "90%",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginLeft: document.body.dir == "rtl" ? 10 : 0,
                                                        marginRight: document.body.dir == "ltr" ? 10 : 0,
                                                    }}
                                                >
                                                    <label>{t("financial.label.resource_name")}</label>
                                                    <input
                                                        id="resource_name"
                                                        type="text"
                                                        name="resource_name"
                                                        className="admin_filter_input"
                                                        onChange={(e) => {
                                                            setFieldValue("resource_name", e.target.value);
                                                        }}
                                                        placeholder={t("financial.label.resource_name")}
                                                        value={values.resource_name}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-end",
                                                    width: "90%",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <button
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    type="submit"
                                                    className="admin_add_button"
                                                >
                                                    {t("crud.placeholders.submit")}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        ) : null}
                        <div
                            className={`table_bank_tran ${
                                showFilters && "tabel_anmation_open"
                            }`}
                        >
                            <div className="tajah_card_title_container">
                                <h3 className="table_header">
                                    <div>{t("financial.label.report")}</div>
                                </h3>
                                <div className={"tajah_card_title_container_actions"}>
                                    <button
                                        style={{borderRadius: 10, padding: 10, borderWidth: 0}}
                                        className={
                                            showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"
                                        }
                                        onClick={() => {
                                            setShowFilters(!showFilters);
                                        }}
                                    >
                                          <IconTooltips
                                            title={t('Filter')}
                                            content={<i className={"fas fa-filter tajah_ticket_list_table_filter_icon"}/>}
                                        />
                                        {/* <i
                                            className={
                                                "fas fa-filter tajah_ticket_list_table_filter_icon"
                                            }
                                        /> */}
                                    </button>
                                    <button
                                        className={"btn btn-sm btn-primary"}
                                        style={{
                                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderWidth: 0,
                                        }}
                                        onClick={async () => {
                                            try {
                                                setIsPerformingBackgroundOperation(true);
                                                await exportOrder(clearEmptyProp(filter), "xlsx");
                                                setIsPerformingBackgroundOperation(false);
                                            } catch (e) {
                                                setIsPerformingBackgroundOperation(false);
                                                toast.error(
                                                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                            {t("failed_fetching")}
                          </span>
                                                );
                                            }
                                        }}
                                    >
                                        <IconTooltips
                                            title={t("modal.coupon.hover.titleDownloadExcel")}
                                            content={<img src={Xls} style={{width: 25}}/>}
                                        />
                                    </button>
                                    <button
                                        className={"btn btn-sm btn-primary"}
                                        style={{
                                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                            borderWidth: 0,
                                        }}
                                        onClick={async () => {
                                            try {
                                                setIsPerformingBackgroundOperation(true);
                                                await exportOrder(clearEmptyProp(filter), "pdf");
                                                setIsPerformingBackgroundOperation(false);
                                            } catch (e) {
                                                setIsPerformingBackgroundOperation(false);
                                                toast.error(
                                                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                            {t("failed_fetching")}
                          </span>
                                                );
                                            }
                                        }}
                                    >
                                        <IconTooltips
                                            title={t("modal.coupon.hover.titleDownloadPdf")}
                                            content={<img src={Pdf} style={{width: 25}}/>}
                                        />
                                    </button>
                                </div>
                            </div>
                            <div
                                className="table-responsive"
                                style={{
                                    width: "90%",
                                }}
                            >
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("financial.label.trainee_name")}</th>
                                        <th scope="col">{t("financial.label.course_name")}</th>
                                        <th scope="col">{t("financial.label.payment_method")}</th>
                                        <th scope="col">{t("financial.label.price")}</th>
                                        <th scope="col">{t("financial.label.type")}</th>
                                        <th scope="col">{t("financial.label.tax")}</th>
                                        <th scope="col">{t("financial.label.discount")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentOrder &&
                                        currentOrder.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{i18n.language === Lang.AR ? item.user.name_ar : item.user.name_en}</td>
                                                    <td scope="col">{item.item.name ?? item.item.title}</td>
                                                    <td scope="col">{item.payment_method}</td>
                                                    <td scope="col">{item.item.price}</td>
                                                    <td scope="col">{item.item_type}</td>
                                                    <td scope="col">{item.item_tax}</td>
                                                    <td scope="col">{item.item_discount}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    nextLabel={t("next")}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={meta.totalPages}
                                    previousLabel={t("previous")}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className="mt-5"
                        style={{
                            height: "65vh",
                        }}
                    >
                        <SkeletonCard/>
                    </div>
                )}
            </div>
        </>
    );
}

export default TotalIncomeReport;
