import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { NavLink, useLocation } from "react-router-dom"
import notFoundFAQ from '../../../../../assets/image/FAQNotFound.png'
// eslint-disable-next-line no-unused-vars
import { toast } from "react-toastify"
import DeleteModal from '../../../../../Modals/DeleteModal/DeleteModal'
import { deleteObjective, getAllObjective } from '../../../../../Services/api/EduGoals/EduGoalsProvider'
import { BasicModal } from '../../../../Shared/Components/Modal/BasicModal'
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard"

const Content = () => {
    require("./content.css");
    const { t } = useTranslation();
    const location = useLocation();
    const deleteModalRef = useRef();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { id } = useParams();
    const [ListOfObjective, setListOfObjective] = useState([])
    const [DataState, setDataState] = useState("")
    const [objectiveId, setObjectiveId] = useState("")
    const [objectiveIndex, setObjectiveIndex] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      getListOfEdu(id);
      if (location.state) {
        setDataState(location.state.data)
      }
    },[])

    const getListOfEdu = async(id) =>{
        try{
            let response = await getAllObjective(id);
            if(response.data.objectives){
                setListOfObjective(response.data.objectives)
                setIsLoading(false)
            }
        }catch (err){
            toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>{err.response.data.msg}</span>
            );
        }
    }

    const deleteHandler = (id,index) => {
        setObjectiveId(id);
        setObjectiveIndex(index)
        deleteModalRef.current.showModal();
      };


    const removeObjective = (id,index) => {
        deleteObjective(id)
            .then(() => {
              const newObjectives = ListOfObjective.filter((item, i) => i !== index);
              setListOfObjective(newObjectives);
              deleteModalRef.current.dismissModal();
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t('edu.successDeleted')}
                </span>
              )
            })
            .catch((err) => {
             deleteModalRef.current.dismissModal();
              toast.error(
                  <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {err.data.msg}
                  </span>
              );
            });
      };
    return (
        <div>
         <BasicModal ref={deleteModalRef}>
         <DeleteModal
          label={t("edu.delete_aprove")}
          title={t("edu.delete.title")}
          deleteMsg={t("edu.delete.body")}
          accept={() => removeObjective(objectiveId,objectiveIndex)}
          deleteModalRef={deleteModalRef}
          />
          </BasicModal>
            <div className="container-fluid profile_wrapper">
                <div className="admin_label" >
                    {
                        DataState?
                        <>
                            {t('edu.Edu_goals')}
                            <p style={{ padding: "0 5px" }}>/</p>
                            {DataState}
                        </> : null
                    }

                </div>
            </div>
            {
                isLoading?
                    <div style={{
                        height: "55vh",
                        width: '100%'
                    }}>
                        <SkeletonCard/>
                    </div>
                :
                    ListOfObjective.length > 0 ?
                        <div className="list-goals" style={{marginTop: '20px'}} >
                            <h4> {t('edu.Edu_goals')}</h4>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t('edu.Edu_goals')}</th>
                                        <th scope="col">{t('edu.name')}</th>
                                        <th scope="col">{t('edu.edit')}</th>
                                    </tr>
                                </thead>
                                <tbody  style={{overflowWrap: "anywhere"}}>
                                    {
                                        ListOfObjective.map((obj, key) => {
                                            return(
                                                <tr>
                                                    <td> {key + 1}</td>
                                                    <td><div style={{ width: '100%',height: '100px',margin: 0,padding: 0,overflow: 'auto'}}>{obj.description}</div></td>
                                                    <td><div style={{ width: '100%',height: '100px',margin: 0,padding: 0,overflow: 'auto'}}>{obj.name}</div></td>
                                                    <td>
                                                        <NavLink
                                                            to={{
                                                                pathname: `/trainer/course/educational-goals/edit/${id}`,
                                                                state: {data: obj},
                                                                }}
                                                            >
                                                                    <i
                                                                    className="fas fa-edit"
                                                                    style={{
                                                                    color: "#126e77",
                                                                    cursor: "pointer",
                                                                    fontSize: "20px",
                                                                    padding: "0 5px"
                                                                    }}
                                                                ></i>
                                                        </NavLink>
                                                        <button
                                                            onClick={()=>deleteHandler(obj.id,key)}
                                                            style={{backgroundColor:'transparent',border:'0px',padding: "0 5px"}}
                                                            >
                                                                    <i
                                                                    className="fas fa-trash"
                                                                    style={{
                                                                    color: "red",
                                                                    backgroundColor:'transparent',
                                                                    cursor: "pointer",
                                                                    fontSize: "20px",
                                                                    }}
                                                                ></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    :
                        <div className="goals-not-found">
                            <p> {t('edu.not_add_edu')}</p>
                            <img src={notFoundFAQ} alt="" className="box_new_chapter" />
                            <div>
                                <NavLink to={`/trainer/course/educational-goals/add/${id}`} className='btn-add-goals'>
                                    <i className="fas fa-plus"></i>
                                    {t('edu.add_Goals')}
                                </NavLink>
                            </div>
                        </div>
            }
        </div>
    )
}
export default Content
