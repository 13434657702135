import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { saveWalletTransaction } from "../../../../../Redux/Actions/wallet/wallet.action";
import { toServerTime } from "../../../../../Services/Timezone";
import { DOC_PDF, PNG_TYPE, acceptDocuments, acceptImages, fromKbToMb } from "../../../../../Services/Validator";
import { getListType } from "../../../../../Services/api/TypeFile/TypeFileProvider";
import ibanIcon from "../../../../../assets/icons/bank (1).svg";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import cashIcon from "../../../../../assets/icons/wallet.svg";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import InputFile from "../../../../Shared/Components/InputFile/InputFile";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Textarea from "../../../../Shared/Components/Textarea/Textarea";
import Title from "../../../../Shared/Components/Title/Title";
import RadioButton from "../../../../Shared/Components/radioButton/RadioButton";
import classes from "./walletDetailsList.module.css";

const WalletTransactionModal = ({ closeWalletTransactionModal, id, fetchUserWalletHandler, user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [processDateHours, setProcessDateHours] = useState(0);
  const [processDateMinutes, setProcessDateMinutes] = useState(0);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    setProcessDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setProcessDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [listTypeFile, setListTypeFile] = useState([]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  const getTypeFile = (searchTypes) => {
    return listTypeFile.filter((file) => searchTypes.includes(file.type));
  };

  const acceptFiles = () => {
    return `${acceptImages()},${acceptDocuments()}`;
  };

  useEffect(() => {
    currentDate();
    getListTypeFile();
    setLoading(false);
  }, []);

  return (
    <MainBox className={classes["wallet-transaction-modal"]}>
      {loading ? (
        <SkeletonCardOverlay skeletonWidth="100" />
      ) : (
        <>
          <div className={classes["wallet-transaction-modal_header"]}>
            <Title title={t("wallet.label.wallet_transaction")} />
            <img src={deleteIcon} alt="close" onClick={closeWalletTransactionModal} />
          </div>
          <Formik
            initialValues={{
              user_id: id,
              amount: "",
              process_date: new Date(),
              transfer_type: "cash",
              process_type: "1",
              file: null,
              notes: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setLoading(true);
              try {
                const formData = new FormData();
                Object.keys(values).forEach((key) => {
                  if (key === "file") {
                    formData.append(key, values[key].file);
                  } else if (key === "process_date") {
                    const processDate = formatDate(values.process_date).split(" ")[0];
                    formData.append(key, processDate);
                  } else {
                    formData.append(key, values[key]);
                  }
                });

                await dispatch(saveWalletTransaction(formData));
                closeWalletTransactionModal();
                fetchUserWalletHandler();
              } catch (error) {
                toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("error")}</span>);
              } finally {
                setSubmitting(false);
                setLoading(false);
              }
            }}
            validateOnChange={true}
            validate={(values) => {
              const errors = {};
              if (!values.amount) {
                errors.amount = t("crud.errors.required");
              }
              if (!values.file) {
                errors.file = t("crud.errors.required");
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setFieldValue,
              validateForm,
            }) => (
              <form onSubmit={handleSubmit} className={classes["wallet_transaction_form"]}>
                <div className={`row ${classes["wallet-transaction_fields"]}`}>
                  <div className={`${classes["wallet-transaction_field"]} col-12`}>
                    <RadioButton
                      name="process_type"
                      label={t("wallet.transaction.process_type")}
                      buttons={[
                        {
                          name: "deposit",
                          label: t("wallet.transaction.deposit"),
                          value: "1",
                          checked: values.process_type === "1",
                          onChange: () => setFieldValue("process_type", "1"),
                        },
                        {
                          name: "withdrawal",
                          label: t("wallet.transaction.withdrawal"),
                          value: "2",
                          checked: values.process_type === "2",
                          onChange: () => setFieldValue("process_type", "2"),
                        },
                      ]}
                      error={errors.process_type}
                      required
                    />
                  </div>
                  <div className={`${classes["wallet-transaction_field"]} col-12`}>
                    <div className={classes["wallet-transaction-box"]}>
                      <div className={classes["wallet-transaction-box-title"]}>{t("payments.payment_method")}</div>
                      <div className={classes["wallet-transaction-box-options"]}>
                        <div
                          className={
                            classes[
                              `wallet-transaction-box-option${values.transfer_type === "cash" ? "-selected" : ""}`
                            ]
                          }
                          onClick={() => setFieldValue("transfer_type", "cash")}
                          disabled={values.transfer_type === "cash"}
                        >
                          <img src={cashIcon} alt="cash-icon" />
                          <h4>{t("wallet.transaction.cash")}</h4>
                        </div>
                        <div
                          className={
                            classes[
                              `wallet-transaction-box-option${values.transfer_type === "iban" ? "-selected" : ""}`
                            ]
                          }
                          onClick={() => setFieldValue("transfer_type", "iban")}
                          disabled={values.transfer_type === "iban" || user?.iban === null}
                        >
                          <img src={ibanIcon} alt="iban-icon" />
                          <h4>{t("wallet.transaction.iban")}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${classes["wallet-transaction_field"]} col-12`}>
                    <Input
                      name="amount"
                      label={t("wallet.transaction.amount")}
                      placeholder={t("wallet.transaction.amount")}
                      type="number"
                      onChange={handleChange}
                      value={values.amount}
                      error={errors.amount}
                      required
                    />
                  </div>
                  <div className={`${classes["wallet-transaction_field"]} col-12`}>
                    <Textarea
                      name="notes"
                      label={t("wallet.transaction.notes")}
                      placeholder={t("wallet.transaction.notes")}
                      onChange={handleChange}
                      value={values.notes}
                      error={errors.notes}
                    />
                  </div>
                  {/* <div className={`${classes["wallet-transaction_field"]} col-12`}>
                    <MainDatePicker
                      label={t(`wallet.transaction.process_date`)}
                      name="process_date"
                      error={errors.process_date}
                      value={values.process_date}
                      dayMonthYear={true}
                      onChange={(date) => {
                        setFieldValue("process_date", date);

                        let CurrentDate = new Date();
                        let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                        let DataPickerValue = date.toISOString().split("T")[0];
                        if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                          setProcessDateHours(0);
                          setProcessDateMinutes(0);
                        } else {
                          currentDate();
                        }
                      }}
                      minTime={setHours(setMinutes(new Date(), 0), 0)}
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      required
                    />
                  </div> */}
                  <div className={`${classes["wallet-transaction_field"]} col-12`}>
                    <InputFile
                      name="file"
                      type="gallery"
                      accept={acceptFiles()}
                      label={t("wallet.transaction.file")}
                      maxSize={
                        getTypeFile
                          ? `${t("general.upload_max_size")} ${Math.floor(
                              getTypeFile([PNG_TYPE, DOC_PDF])[0]?.max_size >
                                getTypeFile([PNG_TYPE, DOC_PDF])[1]?.max_size
                                ? fromKbToMb(getTypeFile([PNG_TYPE, DOC_PDF])[0]?.max_size)
                                : fromKbToMb(getTypeFile([PNG_TYPE, DOC_PDF])[1]?.max_size)
                            )} ${t("general.megabyte")}`
                          : t("general.upload_max_size") + " 10 " + t("general.megabyte")
                      }
                      setFieldValue={setFieldValue}
                      onChange={handleChange}
                      note={t("general.png_pdf_note")}
                      error={errors.file}
                      value={values.file}
                      required
                    />
                  </div>

                  <div className={`${classes["wallet-transaction_submit_container"]} col-12`}>
                    <CustomButton
                      type="submit"
                      colors="#036c77"
                      value={t("general.submit")}
                      classes={classes["wallet-transaction_submit_button"]}
                      disable={isSubmitting}
                      loading={isSubmitting}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </MainBox>
  );
};

export default WalletTransactionModal;
