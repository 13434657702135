import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getlivechatSupportUsersById,
  postLivechatUpdateSupportUser,
  resetLivechat,
} from "../../../Redux/Actions/livechat/livechat.action";
import { getAllUsersForSearch } from "../../../Services/api/survey/SurveyProvider";
import deleteIcon from "../../../assets/icons/delete.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import AddIcon from "../../../assets/icons/plus.svg";
import clock from "../../../assets/icons/clock-green.png";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import Title from "../../Shared/Components/Title/Title";
import classes from "./supportUsers.module.css";

const days = [
  {
    value: "saturday",
    label_ar: "السبت",
    label_en: "Saturday",
    id: 0,
  },
  {
    value: "sunday",
    label_ar: "الأحد",
    label_en: "Sunday",
    id: 1,
  },
  {
    value: "monday",
    label_ar: "الاثنين",
    label_en: "Monday",
    id: 2,
  },
  {
    value: "tuesday",
    label_ar: "الثلاثاء",
    label_en: "Tuesday",
    id: 3,
  },
  {
    value: "wednesday",
    label_ar: "الأربعاء",
    label_en: "Wednesday",
    id: 4,
  },
  {
    value: "thursday",
    label_ar: "الخميس",
    label_en: "Thursday",
    id: 5,
  },
  {
    value: "friday",
    label_ar: "الجمعة",
    label_en: "Friday",
    id: 6,
  },
];

const UpdateSupportUsers = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { livechat_update_support_users, livechat_support_users_by_id } =
    useSelector((state) => state.livechatReducer);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selectedHourStart, setSelectedHourStart] = useState(null);
  const [selectedHourEnd, setSelectedHourEnd] = useState(null);
  const [userFetching, setUserFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [role, setRole] = useState("3");
  const [valueArray, setValueArray] = useState([]);
  const [renderTheForm, setRenderTheFrom] = useState(true);
  const [errorsWorkingHour, setErrorsWorkingHour] = useState("");
  const [loading, setLoading] = useState(id ? true : false);
  const [supportUsersRes, setSupportUsersRes] = useState();
  const [hasMessage, setHasMessage] = useState();
  const [timeError, setTimeError] = useState();

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page: t("chat.title"),
      pagePath: "/admin/livechat",
    },
    {
      id: "chat",
      page: id ? t("chat.editsupportusers") : t("chat.addsupportusers"),
      active: true,
    },
  ];

  const fetchUsers = async (values) => {
    try {
      setUserFetching(true);
      setUsers([]);
      let response = await getAllUsersForSearch({
        search: values.search ? values.search : "",
        role: values.role,
      });
      if (response.status == 200) {
        setUsers(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
      setUserFetching(false);
    }
  };

  const fetchUsersForFirst = async () => {
    try {
      setUserFetching(true);
      setUsers([]);
      let response = await getAllUsersForSearch({
        role: role,
      });
      if (response.status == 200) {
        setUsers(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
      setUserFetching(false);
    }
  };

  const [toRoles] = useState([
    {
      value: "3",
      name: t("survey.select.option.trainer"),
    },
    {
      value: "4",
      name: t("survey.select.option.trainee"),
    },
    {
      value: "5",
      name: "محاسب",
    },
    {
      value: "6",
      name: "شريك",
    },
    {
      value: "7",
      name: "رعاه",
    },
  ]);

  function convertToAMPM(time24) {
    const [hours, minutes] = time24.split(":");

    if (hours === "00" || hours === "24") {
      return `12:${minutes} AM`;
    } else if (hours === "12") {
      return `12:${minutes} PM`;
    } else if (parseInt(hours) < 12) {
      return `${hours}:${minutes} AM`;
    } else {
      const pmHours = (parseInt(hours) - 12).toString();
      return `${pmHours}:${minutes} PM`;
    }
  }

  function convertTo24HourFormat(time12) {
    if (time12.startsWith("0:")) {
      time12 = "00" + time12.substring(1);
    }

    const [hours, minutes] = time12.split(":");
    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    if (!id) fetchUsersForFirst();
  }, []);

  useEffect(() => {
    if (livechat_update_support_users.status) {
      history.push("/admin/livechat/support-users");
      dispatch(resetLivechat());
    }
  }, [livechat_update_support_users]);

  useEffect(() => {
    if (id) {
      dispatch(getlivechatSupportUsersById(id));
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (livechat_support_users_by_id.status) {
      setSupportUsersRes(livechat_support_users_by_id.data.user);
      setLoading(false);
    }
  }, [livechat_support_users_by_id]);

  return (
    <div className={classes["chat_list"]}>
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className={`row ${classes.breadcrumbs}`}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                {loading ? (
                  <div className={classes["loading__container"]}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      working_plan: id ? supportUsersRes.working_plan : [],
                      user_id: id ? supportUsersRes.user_id : "",
                    }}
                    onSubmit={async (values) => {
                      delete values.role;
                      delete values.day;
                      delete values.label_ar;
                      delete values.label_en;
                      delete values.start;
                      delete values.end;
                      dispatch(postLivechatUpdateSupportUser(values));
                    }}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};

                      if (!values.user_id && !id) {
                        errors.user_id = t("Exhibition.errors.required");
                      }
                      if (values.working_plan.length <= 0) {
                        errors.working_plan = t("Exhibition.errors.required");
                      }

                      return errors;
                    }}
                    validateOnChange={hasSubmitted}
                  >
                    {({
                      setFieldValue,
                      setValues,
                      values,
                      handleSubmit,
                      errors,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <>
                          <Title
                            title={
                              id
                                ? t("chat.editsupportusers")
                                : t("chat.addsupportusers")
                            }
                            fontSize={24}
                          />

                          {!id && (
                            <div className={classes["m-50"]}>
                              <div className="tw-space-y-4">
                                {(values.surveyType != 1 &&
                                  values.surveyType != 2) ||
                                !values.is_public ? (
                                  <>
                                    <div>
                                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                        {t("chat.supportuser")}
                                      </div>
                                      <div
                                        className={`sm:tw-flex tw-items-center sm:tw-space-s-4 ${classes.radioOption}`}
                                      >
                                        {toRoles.map((i) => (
                                          <label
                                            key={i.value}
                                            className="tw-flex tw-items-center tw-space-s-2 tw-mb-2"
                                          >
                                            <Field
                                              name="role"
                                              type="radio"
                                              style={{
                                                display: "none",
                                              }}
                                              value={i.value}
                                              className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                              onChange={({
                                                target: { value },
                                              }) => {
                                                const newValues = {
                                                  ...values,
                                                  role: value,
                                                };
                                                setValues(newValues);
                                                if (value != "") {
                                                  setRole(value);
                                                  fetchUsers(newValues);
                                                }
                                              }}
                                            />
                                            <div
                                              className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded-full  ${
                                                role == i.value
                                                  ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                  : "tw-border-gray-300"
                                              }`}
                                            ></div>
                                            <div className="tw-text-gray-600 tw-font-medium">
                                              {i.name}
                                            </div>
                                          </label>
                                        ))}

                                        <ErrorMessage
                                          name={`role`}
                                          component="div"
                                          className="tw-text-xs tw-text-red-700 tw-h-4"
                                        />
                                      </div>
                                    </div>
                                    {role != "" ? (
                                      <>
                                        <FieldArray name="users">
                                          {({ push }) => (
                                            <div className="tw-border tw-rounded tw-divide-y">
                                              <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
                                                <div className="tw-flex tw-items-center tw-space-s-4">
                                                  <div className="tw-font-semibold tw-text-gray-500">
                                                    {t("chat.selectname")}
                                                  </div>
                                                </div>
                                                <div className="tw-flex tw-items-center tw-space-s-8">
                                                  <Field
                                                    name="search"
                                                    className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                                                    placeholder={t(
                                                      "search_by_name_or_email"
                                                    )}
                                                    onChange={({
                                                      target: { value },
                                                    }) => {
                                                      setFieldValue(
                                                        "search",
                                                        value
                                                      );
                                                      fetchUsers({
                                                        ...values,
                                                        search: value,
                                                      });
                                                    }}
                                                  />
                                                  <i className="fal fa-search tw-text-teal-500 tw-w-6"></i>
                                                </div>
                                              </div>
                                              <div className="tw-py-4 tw-pe-4">
                                                <div
                                                  className={`tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-pe-4 ${classes["max-h-30vh"]}`}
                                                >
                                                  {userFetching ? (
                                                    [...Array(5).keys()].map(
                                                      (i) => (
                                                        <div
                                                          key={i}
                                                          className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                                        >
                                                          <div className="tw-flex tw-items-center tw-space-s-4">
                                                            <div
                                                              className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded-full tw-border-gray-300`}
                                                            ></div>
                                                            <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                                          </div>
                                                          <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                                        </div>
                                                      )
                                                    )
                                                  ) : users.length ? (
                                                    users.map((user) => (
                                                      <button
                                                        key={user.id}
                                                        type="button"
                                                        onClick={() => {
                                                          setFieldValue(
                                                            "user_id",
                                                            user.id
                                                          );
                                                          setSelectedId(
                                                            user.id
                                                          );
                                                        }}
                                                        className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full`}
                                                      >
                                                        <div className="tw-flex tw-items-center tw-space-s-4">
                                                          <div
                                                            className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded-full  ${
                                                              selectedId ==
                                                              user.id
                                                                ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                                : "tw-border-gray-300"
                                                            }`}
                                                          ></div>
                                                          <div>
                                                            {i18n.language ===
                                                            Lang.AR
                                                              ? user.name_ar
                                                              : user.name_en}
                                                          </div>
                                                        </div>
                                                        <div>{user.email}</div>
                                                      </button>
                                                    ))
                                                  ) : (
                                                    <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                                      {t(
                                                        "there_no_matching_users"
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </FieldArray>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {errors.user_id && (
                                <p
                                  className={`form-input-error-space ${classes["input-field-error"]}`}
                                >
                                  {errors.user_id}
                                </p>
                              )}
                            </div>
                          )}

                          {renderTheForm && (
                            <div
                              className={`${id && classes["m-50"]} ${
                                classes.timeform
                              }`}
                            >
                              <div className={classes.days}>
                                <label>{t("chat.workingdays")}</label>
                                <Select
                                  name="day"
                                  id="day"
                                  options={days}
                                  getOptionLabel={(option) =>
                                    i18n.language === Lang.AR
                                      ? option.label_ar
                                      : option.label_en
                                  }
                                  getOptionValue={(option) => option.value}
                                  onChange={(item, e) => {
                                    setFieldValue("day", item.value);
                                    setFieldValue("label_ar", item.label_ar);
                                    setFieldValue("label_en", item.label_en);
                                  }}
                                  placeholder={t("chat.selectday")}
                                />
                              </div>

                              <div className={classes["container-hours"]}>
                                <label>{t("chat.workinghour")}</label>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                  className={classes.hours}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div className={classes.clock}>
                                      <img
                                        src={clock}
                                        alt="date"
                                        width={20}
                                        height={20}
                                      />
                                    </div>
                                    <DatePicker
                                      selected={selectedHourStart}
                                      placeholderText={t("chat.start")}
                                      className={`${classes["time-input"]}`}
                                      onChange={(item) => {
                                        setSelectedHourStart(item);

                                        var date = new Date(item);
                                        var hour = date.getHours();
                                        var minute = date
                                          .getMinutes()
                                          .toString()
                                          .padStart(2, "0");
                                        setFieldValue(
                                          "start",
                                          `${hour}:${minute}`
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={15}
                                      dateFormat="h:mm aa"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div className={classes.clock}>
                                      <img
                                        src={clock}
                                        alt="date"
                                        width={20}
                                        height={20}
                                      />
                                    </div>

                                    <DatePicker
                                      placeholderText={t("chat.end")}
                                      selected={selectedHourEnd}
                                      className={`${classes["time-input"]} ${classes["mx-20"]}`}
                                      onChange={(item) => {
                                        setSelectedHourEnd(item);
                                        var date = new Date(item);
                                        var hour = date.getHours();
                                        var minute = date
                                          .getMinutes()
                                          .toString()
                                          .padStart(2, "0");
                                        setFieldValue(
                                          "end",
                                          `${hour}:${minute}`
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={15}
                                      dateFormat="h:mm aa"
                                    />
                                  </div>
                                </div>
                              </div>
                              <CustomButton
                                type={"button"}
                                action={async () => {
                                  if (
                                    values.day &&
                                    values.start &&
                                    values.end
                                  ) {
                                    const day = values.working_plan.some(
                                      (item) => item.day === values.day
                                    );
                                    if (values.start < values.end) {
                                      if (day) {
                                        setHasMessage(t("chat.hasmessage"));
                                      } else {
                                        setHasMessage("");
                                        await setRenderTheFrom(false);

                                        let startTime = convertTo24HourFormat(
                                          values.start
                                        );
                                        let endTime = convertTo24HourFormat(
                                          values.end
                                        );

                                        values.working_plan.push({
                                          day: values.day,
                                          label_ar: values.label_ar,
                                          label_en: values.label_en,
                                          start: startTime,
                                          end: endTime,
                                        });
                                        setSelectedHourEnd(null);
                                        setFieldValue("end", null);
                                        setFieldValue("start", null);
                                        setFieldValue("day", "");
                                        setSelectedHourStart(null);
                                        await setRenderTheFrom(true);
                                        setErrorsWorkingHour("");
                                      }
                                    } else {
                                      setTimeError(t("chat.timeerror"));
                                    }
                                  } else {
                                    setErrorsWorkingHour(
                                      t("chat.youmustselecttheday")
                                    );
                                  }
                                }}
                                colors={"#036c77"}
                                icon={AddIcon}
                                classes={`${classes.icon}`}
                                variant={""}
                                iconClass={classes["icon-m"]}
                              />
                            </div>
                          )}
                          {errorsWorkingHour && (
                            <div className="tw-text-xs tw-text-red-700 tw-h-4">
                              {errorsWorkingHour}
                            </div>
                          )}
                          {hasMessage && (
                            <div className="tw-text-xs tw-text-red-700 tw-h-4">
                              {hasMessage}
                            </div>
                          )}
                          {timeError && (
                            <div className="tw-text-xs tw-text-red-700 tw-h-4">
                              {timeError}
                            </div>
                          )}

                          <div>
                            {values.working_plan.map((value, index) => (
                              <span
                                key={index}
                                className={classes["selected-time"]}
                              >
                                {i18n.language === Lang.AR
                                  ? value.label_ar
                                  : value.label_en}{" "}
                                {t("chat.start")} {convertToAMPM(value.start)}{" "}
                                {t("chat.end")} {convertToAMPM(value.end)}
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const newArray = [...values.working_plan];
                                    newArray.splice(index, 1);
                                    setValueArray(newArray);
                                    setFieldValue("working_plan", newArray);
                                  }}
                                >
                                  <img src={deleteIcon} alt="" />
                                </button>
                              </span>
                            ))}
                          </div>
                          {errors.working_plan && (
                            <p
                              className={`form-input-error-space ${classes["input-field-error"]}`}
                            >
                              {errors.working_plan}
                            </p>
                          )}
                          <div
                            className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                          >
                            <CustomButton
                              value={t("FAQ.done")}
                              colors={"#036c77"}
                              type={"button"}
                              iconClass={"mt-2"}
                              action={handleSubmit}
                              classes={"button-width"}
                              // loading={hasSubmitted}
                              // disable={hasSubmitted}
                            />
                          </div>
                        </>
                      </form>
                    )}
                  </Formik>
                )}
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSupportUsers;
