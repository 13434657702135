import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { getFundList } from "../../../../Redux/Actions/fund/fund.action";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getExhibitionList } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import { exportOfferList } from "../../../../Services/api/OfferList";
import HomeIcon from "../../../../assets/icons/home.svg";
import { ReactComponent as ReopenIcon } from "../../../../assets/icons/reopen.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./fund.module.css";
import { filterActions } from "./fundAction";

const Fund = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { get_fund, loading } = useSelector((state) => state.fundReducer);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [fund, setFund] = useState([]);
  const [lastPaymentType, setLastPaymentType] = useState("");

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    payable_type: "",
    payable_name: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      start_date: "",
      end_date: "",
      payable_type: "",
      payable_name: "",
    });
    setIsLoading(true);
    dispatch(getFundList());
  };

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getFundList({ ...filterData }));
  }, []);

  useEffect(() => {
    if (get_fund.status === 200) {
      setFund(get_fund.data.data);
      setIsLoading(false);
    }
  }, [get_fund]);

  const getFilterData = () => {
    const filter = {};
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.payable_type && (filter.payable_type = filterData.payable_type);
    filterData.payable_name && (filter.payable_name = filterData.payable_name);
    return filter;
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportOfferList(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const fetchData = async () => {
    try {
      const [coursesRes, programsRes, exhibitionRes] = await Promise.all([
        getAllCourses({ paginated: 0, not_in_program: 1, is_program: 0, mode: "Paid", perPage: 1000 }),
        getAllCourses({ paginated: 0, is_program: 1, mode: "Paid", perPage: 1000 }),
        getExhibitionList({ perPage: 1000, mode: "Paid" }),
      ]);

      if (coursesRes.status === 200 && coursesRes.data.status) {
        setCourses(coursesRes.data.data.courses.data);
      }

      if (programsRes.status === 200 && programsRes.data.status) {
        setPrograms(programsRes.data.data.courses.data);
      }

      if (exhibitionRes.status === 200) {
        setExhibitions(exhibitionRes.data.data.exhibitions.data);
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response ? error.response.data.msg : t("Failure_in_service")}
        </span>
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "payments",
      page: t("sidebar.label.financial_movement"),
      pagePath: `/${localStorage.getItem("type") === "accountant" ? "accountant" : "admin"}/financial-movement`,
    },
    {
      id: "fund",
      page: t("fund.label"),
      active: true,
    },
  ];

  const filterFields = [
    {
      id: "start_date",
      component: "datePicker",
      label: t("financial.label.start_date_time"),
      type: "date",
      placeholder: t("financial.label.start_date_time"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "end_date",
      component: "datePicker",
      label: t("financial.label.end_date_time"),
      type: "date",
      placeholder: t("financial.label.end_date_time"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "payable_type",
      label: t("payments.payable_type"),
      component: "select",
      placeholder: t("payments.payable_type"),
      initialValue: t("payments.payable_type"),
      name: "payable_type",
      value: filterData.payable_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: (e) => {
        setFilterData({ ...filterData, payable_type: e.target.value, payable_name: "" });
      },
      onReset: resetHandler,
      options: [
        {
          id: "course",
          value: "course",
          label: t("trainer.course.label"),
        },
        {
          id: "program_payment",
          value: "program_payment",
          label: t("trainer.program.label"),
        },
        {
          id: "booth",
          value: "booth",
          label: t("general.exhibition_booth"),
        },
        {
          id: "visit",
          value: "exhibition_visit",
          label: t("general.exhibition_visitor"),
        },
        {
          id: "wallet",
          value: "wallet",
          label: t("general.wallet"),
        },
        {
          id: "wallet_transaction",
          value: "wallet_transaction",
          label: t("general.user"),
        },
        {
          id: "coupon",
          value: "coupon",
          label: t("general.coupon"),
        },
      ],
    },
    ...(filterData.payable_type === "course"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.name,
                  label: course.name,
                }))
              : [],
          },
        ]
      : filterData.payable_type === "program_payment"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.name,
                  label: program.name,
                }))
              : [],
          },
        ]
      : filterData.payable_type === "exhibition_visit" || filterData.payable_type === "booth"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_exhibition"),
            component: "select",
            placeholder: t("coupon.label.select_exhibition"),
            initialValue: t("coupon.label.select_exhibition"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: exhibitions?.length
              ? exhibitions?.map((exhibition) => ({
                  id: exhibition.id,
                  value: exhibition.name,
                  label: exhibition.name,
                }))
              : [],
          },
        ]
      : []),
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.reset"),
      classes: "col-12 col-md-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.search"),
      classes: "col-12 col-md-2",
      action: () => {
        dispatch(getFundList(filterData));
        setLastPaymentType(filterData.payable_type);
        setIsLoading(true);
        setCurrentPage(1);
      },
    },
  ];

  const statistics = [
    {
      label: t("fund.net_profit"),
      value: `${fund.net_profit} ${t("rs")}`,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all"],
    },
    {
      label: t("fund.total_payments"),
      value: `${fund.total_payments} ${t("rs")}`,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "course", "program_payment", "exhibition_visit", "booth", "coupon"],
    },
    {
      label: t("fund.total_refunds"),
      value: `${fund.total_refunds} ${t("rs")}`,
      color: "#046C77dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "course", "program_payment", "exhibition_visit", "booth", "coupon"],
    },
    {
      label: t("fund.total_taxes"),
      value: `${fund.total_taxes} ${t("rs")}`,
      color: "#046C77cc",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
          <path
            id="user-pen"
            d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "course", "program_payment", "exhibition_visit", "booth", "coupon"],
    },
    {
      label: t("fund.total_discounts_for_offers"),
      value: `${fund.total_offers} ${t("rs")}`,
      color: "#046C77bb",
      icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
      show: ["all", "course", "program_payment"],
    },
    {
      label: t("fund.total_discounts_for_coupons"),
      value: `${fund.total_coupons} ${t("rs")}`,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "course", "program_payment"],
    },
    {
      label: t("fund.total_admin_coupons"),
      value: `${fund.total_admin_coupons} ${t("rs")}`,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "course", "program_payment"],
    },
    {
      label: t("fund.total_gift_coupons"),
      value: `${fund.total_gift_coupons} ${t("rs")}`,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "course", "program_payment"],
    },
    {
      label: t("fund.admin_total_withdraw"),
      value: `${fund.admin_total_withdraw} ${t("rs")}`,
      color: "#046C77bb",
      icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
      show: ["all", "wallet_transaction"],
    },
    {
      label: t("fund.total_deposits"),
      value: `${fund.total_deposits} ${t("rs")}`,
      color: "#046C77dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "wallet"],
    },
    {
      label: t("fund.admin_total_deposits"),
      value: `${fund.admin_total_deposits} ${t("rs")}`,
      color: "#046C77cc",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
          <path
            id="user-pen"
            d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "wallet"],
    },
    {
      label: t("fund.user_total_deposits"),
      value: `${fund.user_total_deposits} ${t("rs")}`,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
      show: ["all", "wallet"],
    },
  ];

  return (
    <div className={classes["chat_list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      {loading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className={`row ${classes.breadcrumbs}`}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("general.statistics")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                  })}
                />
                {showFilter && <FilterBox fields={filterFields} />}
                {isLoading ? (
                  <div className="container-fluid">
                    <div
                      style={{
                        height: "25vh",
                      }}
                    >
                      <SkeletonCard />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
                      {statistics.map((item, index) => {
                        return (
                          <>
                            {item.show.includes(lastPaymentType || "all") && (
                              <div
                                key={index}
                                className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                                style={{ backgroundColor: item.color }}
                              >
                                <div className="tw-h-10 tw-w-10">{item.icon}</div>
                                <div>
                                  <div className="tw-text-2xl">{item.value}</div>
                                  <div className="tw-text-sm">{item.label}</div>
                                </div>
                              </div>
                            )}
                            {index === 0 && !lastPaymentType && (
                              <>
                                <div />
                                <div />
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fund;
