import classes from "./ContentView.module.css";
function PdfView({ question }) {
  return (
    <div>
      <div style={{ paddingBottom: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div>
      <iframe
        src={question.download}
        width="690"
        height="500"
        frameborder="0"
        allowfullscreen
        title="PDF Preview"
      ></iframe>
    </div>
  );
}

export default PdfView;
