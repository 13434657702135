import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import toLocalTimezone from "../../../../Services/Timezone";
import { deleteExam, exportQuizParticipants, getQuizParticipants } from "../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import DeleteIcon from "../../../../assets/icons/warning.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./exhibitionList.module.css";

export default function AssessmentParticipants({ quizable, role }) {
  require("./index.css");

  const { t } = useTranslation();
  const { courseId, quizableId, quizId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [quiz, setQuiz] = useState({});
  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  function getFilter() {
    const keys = Object.keys(filter);
    let currentFilter = { ...filter };

    if (currentFilter.attempts_number) {
      const parseValue = JSON.parse(currentFilter.attempts_number);
      currentFilter = {
        ...currentFilter,
        min_attempts_number: parseValue.min,
        max_attempts_number: parseValue.max,
      };
      delete currentFilter.attempts_number;
    }

    if (currentFilter.grade) {
      const parseValue = JSON.parse(currentFilter.grade);
      currentFilter = {
        ...currentFilter,
        min_grade: parseValue.min,
        max_grade: parseValue.max,
      };

      delete currentFilter.grade;
    }

    if (keys.includes("is_auto_corrected"))
      currentFilter.is_auto_corrected = currentFilter.is_auto_corrected == "true" ? 1 : 0;

    if (keys.includes("show_mark")) currentFilter.show_mark = currentFilter.show_mark == "true" ? 1 : 0;
    return currentFilter;
  }

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            action: () => {
              setIsUpdating(true);
              exportQuizParticipants("pdf", quizId, {
                ...getFilter(),
                quizable_type: quizable,
                quizable_id: quizableId,
              })
                .then((res) => {
                  if (res.status && res.status === 200) {
                    setIsUpdating(false);
                    toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
                  } else {
                    throw res;
                  }
                })
                .catch((err) => {
                  setIsUpdating(false);
                  toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
                });
            },
          },
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            action: () => {
              setIsUpdating(true);
              exportQuizParticipants("xlsx", quizId, {
                ...getFilter(),
                quizable_type: quizable,
                quizable_id: quizableId,
              })
                .then((res) => {
                  if (res.status && res.status === 200) {
                    setIsUpdating(false);
                    toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
                  } else {
                    throw res;
                  }
                })
                .catch((err) => {
                  setIsUpdating(false);
                  toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
                });
            },
          },
        ]
      : []),
  ];

  const quizActionsList = [
    {
      id: "view-details",
      icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("booth.details"),
      action: (row) => {
        history.push(
          `/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}/participants/answers/${row.user.id}`
        );
      },
    },
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>,
    },
    {
      id: "image",
      name: t("Exhibition.photo"),
      key: "img",
      cell: ({ rowData }) =>
        rowData.user?.img ? (
          <div className=" tw-h-16 tw-w-16 tw-shrink-0 tw-mx-auto">
            <img src={rowData.user?.img} className="tw-rounded-full tw-h-full tw-aspect-square" />
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: "name",
      name: t("student_name"),
      key: "name",
      sortable: false,
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.user?.name}</span>,
    },
    {
      id: "email",
      name: t("email"),
      key: "email",
      cell: ({ rowData }) => <span className="cell-font">{rowData.user?.email}</span>,
    },
    {
      id: "grade",
      name: t("student_grade"),
      key: "grade",
      cell: ({ rowData }) => (
        <span className="cell-font">
          {Math.round(parseFloat(rowData.score) * 100) / 100} (
          {Math.round((parseFloat(rowData.score) / parseFloat(quiz.total_grade)) * 10000) / 100}
          %)
        </span>
      ),
    },
    {
      id: "result",
      name: t("student_result"),
      key: "result",
      cell: ({ rowData }) => (
        <span
          className={`cell-font tw-p-1 tw-rounded ${
            rowData.is_corrected
              ? rowData.is_success
                ? "tw-text-emerald-600 tw-bg-emerald-600/10"
                : "tw-text-red-600 tw-bg-red-600/10"
              : "tw-text-gray-400 tw-bg-gray-400/10"
          }`}
        >
          {rowData.is_corrected ? (rowData.is_success ? t("success") : t("fail")) : t("undefined")}
        </span>
      ),
    },
    {
      id: "status",
      name: t("student_status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className={`cell-font tw-p-1 tw-rounded ${
            rowData.is_corrected ? "tw-text-amber-500 tw-bg-amber-500/10" : "tw-text-gray-400 tw-bg-gray-400/10"
          }`}
        >
          {rowData.is_corrected ? t("completed") : t("not_completed")}
        </span>
      ),
    },
    {
      id: "final_attempt",
      name: t("attempt_number"),
      key: "final_attempt",
      cell: ({ rowData }) => <span className="cell-font">{rowData.final_attempt}</span>,
    },
    {
      id: "attempts_number",
      name: t("attempts_number"),
      key: "attempts_number",
      cell: ({ rowData }) => <span className="cell-font">{rowData.attempts_number}</span>,
    },
    {
      id: "is_all_corrected",
      name: t("attempts_status"),
      key: "is_all_corrected",
      cell: ({ rowData }) => (
        <span
          className={`cell-font tw-p-1 tw-rounded ${
            rowData.is_all_corrected ? "tw-text-amber-500 tw-bg-amber-500/10" : "tw-text-gray-400 tw-bg-gray-400/10"
          }`}
        >
          {rowData.is_all_corrected ? t("completed") : t("not_completed")}
        </span>
      ),
    },
    {
      id: "enrolled_date",
      name: t("enrolled_date"),
      key: "enrolled_date",
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.answer_date ? (
            <>
              <div>{moment(toLocalTimezone(rowData.answer_date)).format("DD/MM/YYYY")}</div>
              <div>{moment(toLocalTimezone(rowData.answer_date)).format("hh:mm A")}</div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // actions
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-center`}>
          {quizActionsList.map((action, index) => (
            <>
              {action.id == "view-details" && quiz.monitored ? (
                <></>
              ) : (
                <IconsTooltip
                  title={action.title(rowData)}
                  content={
                    <button
                      key={action.id}
                      className={classes["exhibition_list__actions-btn"]}
                      onClick={() => {
                        action.action(rowData, rowIndex);
                      }}
                    >
                      {action.icon(rowData, rowIndex)}
                    </button>
                  }
                />
              )}
            </>
          ))}
        </div>
      ),
    },
  ];

  const filterFields = [
    // search
    {
      id: "student_name",
      component: "input",
      type: "search",
      placeholder: t("student_name"),
      label: t("student_name"),
      name: "student_name",
      value: filter.student_name ?? "",
      classes: "col-12 col-md-4",
      onReset: () => filterChangeHandler({ target: { value: "", name: "student_name" } }),
      onchange: filterChangeHandler,
    },
    // email
    {
      id: "email",
      component: "input",
      type: "search",
      placeholder: t("Email"),
      label: t("Email"),
      name: "email",
      value: filter.email ?? "",
      classes: "col-12 col-md-4",
      onReset: () => filterChangeHandler({ target: { value: "", name: "email" } }),
      onchange: filterChangeHandler,
    },
    // student grade
    {
      id: "grade",
      component: "select",
      placeholder: t("student_grade"),
      initialValue: t("student_grade"),
      label: t("student_grade"),
      name: "grade",
      value: filter.grade ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 60, min: 51 }),
          label: "51 - 60",
        },
        {
          id: "7",
          value: JSON.stringify({ max: 70, min: 61 }),
          label: "61 - 70",
        },
        {
          id: "8",
          value: JSON.stringify({ max: 80, min: 71 }),
          label: "71 - 80",
        },
        {
          id: "9",
          value: JSON.stringify({ max: 90, min: 81 }),
          label: "81 - 90",
        },
        {
          id: "10",
          value: JSON.stringify({ max: 100, min: 91 }),
          label: "91 - 100",
        },
      ],
    },
    // student result
    {
      id: "result",
      component: "select",
      placeholder: t("student_result"),
      initialValue: t("student_result"),
      label: t("student_result"),
      name: "result",
      value: filter.result ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        {
          id: "success",
          value: "1",
          label: t("success"),
        },
        {
          id: "fail",
          value: "0",
          label: t("fail"),
        },
      ],
    },
    // student status
    {
      id: "status",
      component: "select",
      placeholder: t("student_status"),
      initialValue: t("student_status"),
      label: t("student_status"),
      name: "status",
      value: filter.status ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        {
          id: "completed",
          value: "1",
          label: t("student_status_options.completed"),
        },
        {
          id: "not_completed",
          value: "0",
          label: t("student_status_options.not_completed"),
        },
      ],
    },
    // student attempts number
    {
      id: "attempts_number",
      component: "select",
      placeholder: t("attempts_number"),
      initialValue: t("attempts_number"),
      label: t("attempts_number"),
      name: "attempts_number",
      value: filter.attempts_number ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "2",
          value: JSON.stringify({ max: 1, min: 1 }),
          label: "1",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 3, min: 2 }),
          label: "2 - 3",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 8, min: 4 }),
          label: "4 - 8",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 15, min: 9 }),
          label: "9 - 15",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 20, min: 16 }),
          label: "16 - 20",
        },
        {
          id: "7",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "8",
          value: JSON.stringify({ min: 31, max: "" }),
          label: t("survey.morethan"),
        },
      ],
    },
    // enrolled_date
    {
      id: "enrolled_date",
      component: "dateRange",
      placeholder: t("enrolled_date"),
      label: t("enrolled_date"),
      name: "enrolled_date",
      value: filter.enrolled_date ?? "",
      classes: "col-12 col-md-3",
      onchange: filterChangeHandler,
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 mr-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setFilter({});
        if (currentPage == 1) {
          refetchQuiz({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        if (currentPage == 1) {
          refetchQuiz();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchQuiz = (params = getFilter()) => {
    params = {
      ...params,
      perPage: 10,
      quizable_type: quizable,
      quizable_id: quizableId,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getQuizParticipants(quizId, params);
        if (response.status == 200) {
          setParticipants(response.data.data.participants);
          setQuiz(response.data.data.quiz);
          setMeta(response.data.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  const refetchQuiz = async (params = getFilter()) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        page: currentPage,
      };
      await fetchQuiz(customFilter);
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    refetchQuiz();
  }, [currentPage, order]);

  const breadcrumbList = [
    ...(role == "admin"
      ? [
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("sidebar.label.courses"),
      page: t("sidebar.label.courses"),
      pagePath: `/${role}/courses`,
    },
    {
      id: t("Courseoutline"),
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${courseId}`,
    },
    {
      id: t("trainer.quiz.exams"),
      page: t("trainer.quiz.exams"),
      pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`,
    },
    {
      id: quizId,
      page: quiz.title,
      pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}`,
    },
    {
      id: t("participants"),
      page: t("participants"),
      active: true,
    },
  ];

  return (
    <>
      {showDeleteModalByRow && (
        <SharedModal
          icon={DeleteIcon}
          title={t("modal.support.ticket_types.delete.title")}
          name={showDeleteModalByRow.title}
          deleteMsg={t("modal.support.ticket_types.delete.body")}
          confirmText={t("survey.delete_aprove")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModalByRow(null);
          }}
          onConfirm={async () => {
            try {
              setIsUpdating(true);
              const res = await deleteExam(showDeleteModalByRow.id);
              refetchQuiz();
              setShowDeleteModalByRow(null);
              setIsUpdating(false);
            } catch (error) {
              setIsUpdating(false);
            }
          }}
          // isLoading={iseDeleteLoading}
        />
      )}
      <div className="tw-ms-auto"></div>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8">
            <MainBox>
              <MainBoxHead title={t("participants")} actions={actionsList} />
              {isFilter && <FilterBox fields={filterFields} />}
              <MainTable columns={columns} data={participants} sortAction={sortActionHandler} />
              <Pagination
                count={meta.totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
