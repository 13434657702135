import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import {
  exportExhibitionFinancialReports,
  exportExhibitionsVisits,
  getExhibition,
} from "../../../Services/api/Exhibition/ExhibitionProvider";
import XlsPopupIcon from "../../../assets/icons/file-XLS.svg";
import PdfPopupIcon from "../../../assets/icons/file-pdf.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import CloseIcon from "../../../assets/icons/remove-red.svg";
import XlsIcon from "../../../assets/icons/xls.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import "./exhibitionReport.css";

const colors = [
  "#2e3e5f",
  "#24b3b9",
  "#8599b6",
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#2C73D2",
  "#008E9B",
  "#008F7A",
  "#B39CD0",
  "#FBEAFF",
  "#C34A36",
];

const pickColor = (index = 0) => {
  if (colors.length > index) {
    return colors[index];
  } else {
    return colors[index % colors.length];
  }
};

const ExhibitionReport = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [yList, setYList] = useState([]);
  const [getExhibitionLoading, setGetExhibitionLoading] = useState(false);
  const [exhibitionDetails, setExhibitionDetails] = useState([]);
  const [exhibitionReport, setExhibitionReport] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [exportType, setExportType] = useState("pdf");
  const [exportPopup, setExportPopup] = useState(false);

  const exportPopupRef = useRef(null);

  const openExportPopup = () => {
    setExportPopup(true);
    exportPopupRef?.current?.showModal();
  };

  const closeExportPopup = () => {
    setExportPopup(false);
    exportPopupRef?.current?.dismissModal();
  };

  const buildChartOne = (exhibition) => {
    let labels = [];
    let datasets = [];
    let charts = [];

    labels = exhibition.booths.map((booth) => booth.name);
    datasets = [
      {
        label: t("Exhibition.visits_count"),
        data: exhibition.booths.map((booth) => booth.visit_count),
        backgroundColor: exhibition.booths.map((booth, index) => pickColor(index)),
      },
    ];

    charts.push({
      labels,
      datasets,
    });

    return charts;
  };

  const buildChartTwo = (exhibition) => {
    let labels = [];
    let datasets = [];
    let charts = [];

    labels = exhibition.booths.map((booth) => booth.name);
    datasets = [
      {
        label: t("Exhibition.avg_rating"),
        data: exhibition.booths.map((booth) => booth.rate_avg),
        backgroundColor: exhibition.booths.map((booth, index) => pickColor(index)),
      },
    ];

    charts.push({
      labels,
      datasets,
    });

    return charts;
  };

  useEffect(() => {
    let mappedExhibitionDetails = [];
    if (!id) return;
    setGetExhibitionLoading(true);
    getExhibition(id)
      .then((response) => {
        if (response.data.status) {
          setExhibitionDetails(response.data.data.exhibition);
          mappedExhibitionDetails.push({
            id: 1,
            name: t("Exhibition.booth_visits_chart"),
            type: "bar",
            is_required: false,
            response: response.data.exhibition,
            charts: buildChartOne(response.data.data.exhibition),
          });
          mappedExhibitionDetails.push({
            id: 2,
            name: t("Exhibition.booth_rate_chart"),
            type: "bar",
            is_required: false,
            response: response.data.data.exhibition,
            charts: buildChartTwo(response.data.data.exhibition),
          });
          setExhibitionReport(mappedExhibitionDetails);
          setGetExhibitionLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/admin/page-not-found");
        }
        setGetExhibitionLoading(false);
      });
  }, []);

  const getOptions = (report, chart) => {
    return {
      indexAxis: yList.includes(report.id) ? "y" : "x",
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: report.charts.length > 1,
          text: chart.label,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: chart.labels.length > 1 && yList.includes(report.id),
          ticks: {
            precision: 0,
          },
          grid: {
            borderWidth: 0,
            borderWidth: "rgba(0, 0, 0, 0)",
          },
        },
        y: {
          stacked: chart.labels.length > 1 && yList.includes(report.id),
          ticks: {
            precision: 0,
          },
          grid: {
            borderWidth: 0,
            borderWidth: "rgba(0, 0, 0, 0)",
          },
        },
      },
    };
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: t("Exhibition.Exhibition"),
      pagePath: "/admin/exhibitions-list",
    },
    {
      id: "exhibition-details",
      page: exhibitionDetails?.name || "",
      pagePath: `/admin/exhibition/${id}`,
    },
    {
      id: "exhibition-report",
      page: t("Exhibition.ExhibitionReport"),
      active: true,
    },
  ];

  const exportFile = async (exportHandler) => {
    setIsExportLoading(true);
    await exportHandler({ id, type: exportType });
    setIsExportLoading(false);
  };

  return (
    <div className="container-fluid" id={"content-section"}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="row">
        <div className="col-12 sm:tw-px-2 tw-py-8">
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div>
      {getExhibitionLoading ? (
        <div className="mt-5" style={{ height: "65vh" }}>
          <SkeletonCard />
        </div>
      ) : (
        <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-10 tw-mb-8">
          <div className="md:tw-flex tw-items-center tw-justify-between">
            <div className="md:tw-flex tw-items-center tw-justify-center tw-space-s-4">
              <div className="tw-space-y-2 tw-py-2">
                <div className="sm:tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-text-lg sm:tw-text-3xl tw-text-teal-700">{exhibitionDetails?.name}</div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-space-s-6">
              <button
                type="button"
                onClick={() => (setExportType("pdf"), openExportPopup())}
                className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#b91414] tw-border-none"
              >
                <img src={PdfIcon} alt="" />
              </button>
              <button
                type="button"
                onClick={() => (setExportType("xls"), openExportPopup())}
                className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#138d5f] tw-border-none"
              >
                <img src={XlsIcon} alt="" />
              </button>
            </div>
          </div>
          <>
            {!!exhibitionReport.length &&
              exhibitionReport.map((report, index) => {
                return (
                  <div className="tw-border !tw-border-gray-200 tw-divide-y tw-divide-gray-200 tw-rounded">
                    {report.charts.length ? (
                      <>
                        <div className="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-between">
                          <div className="tw-flex tw-items-center tw-space-s-2">
                            <div className="tw-flex tw-items-center tw-space-s-2 tw-font-bold tw-text-gray-700">
                              <div>{report.name}</div>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              let temp = [...yList];
                              const ind = temp.findIndex((i) => i == report.id);
                              if (ind < 0) {
                                temp.push(report.id);
                              } else {
                                temp.splice(ind, 1);
                              }
                              setYList(temp);
                            }}
                            className={`fal fa-poll tw-p-1 tw-rounded tw-bg-gray-600 tw-text-white tw-transition-all tw-duration-500 ${
                              yList.includes(report.id) ? "" : "tw-rotate-90"
                            }`}
                          ></button>
                        </div>
                        <div className="tw-py-2 tw-px-4">
                          {report.charts.map((chart, index) => {
                            return (
                              <Bar
                                options={getOptions(report, chart)}
                                data={{
                                  labels: chart.labels,
                                  datasets: chart.datasets.map((dataset) => ({
                                    ...dataset,
                                    maxBarThickness: 120,
                                    grouped: true,
                                  })),
                                }}
                                height={100}
                                key={`report-${report.id}-bar-${index}`}
                              />
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                  </div>
                );
              })}
          </>
        </div>
      )}
      <BasicModal ref={exportPopupRef}>
        <div
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-8  tw-text-center tw-space-y-4"
          style={{ borderRadius: "20px", backgroundColor: "#fff" }}
        >
          <div className="tw-flex tw-items-center tw-justify-start tw-w-full">
            <div className="tw-cursor-pointer" onClick={() => closeExportPopup()}>
              <img src={CloseIcon} alt="" className="tw-w-6 tw-h-6 tw-mr-2" />
            </div>
          </div>
          {exportType === "pdf" ? (
            <img src={PdfPopupIcon} alt="" className="tw-w-20 tw-h-20 tw-mb-4" />
          ) : (
            <img src={XlsPopupIcon} alt="" className="tw-w-20 tw-h-20 tw-mb-4" />
          )}
          <div className="tw-text-lg tw-font-bold tw-text-gray-700 tw-mb-4" style={{ color: "#555555" }}>
            {t("Exhibition.export_title")}
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center" style={{ gap: "10px", width: "100%" }}>
            <button
              type="button"
              onClick={() => {
                exportFile(exportExhibitionsVisits);
                closeExportPopup();
              }}
              className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-32 tw-rounded tw-border-none -tw-top-4 export-btn"
              style={{ backgroundColor: "#ededed", width: "100%" }}
            >
              {t("Exhibition.export_visits")}
            </button>
            <button
              type="button"
              onClick={() => {
                exportFile(exportExhibitionFinancialReports);
                closeExportPopup();
              }}
              className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-32 tw-rounded tw-border-none export-btn tw-mb-4"
              style={{ backgroundColor: "#ededed", width: "100%" }}
            >
              {t("Exhibition.export_financial_reports")}
            </button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default ExhibitionReport;
