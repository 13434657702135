import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addActivityComment,
  deleteActivityComment,
  deleteChapterComment,
  fetchActivityCommentsList,
  fetchChapterCommentsList,
  updateIsBannedActivityComment,
  updateIsBannedChapterComment,
  updateIsPublishedActivityComment,
  updateIsPublishedChapterComment,
} from "../../../../../../../Redux/Actions/activityComment/activityComment.action";
import toLocalTimezone from "../../../../../../../Services/Timezone";
import { downloadCommentFile } from "../../../../../../../Services/api/ComentDownloadFile";
import { ReactComponent as DocumentIcon } from "../../../../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../../assets/icons/download.svg";
import PrivateReplayIcon from "../../../../../../../assets/image/encryption.png";
import DropDown from "../../../../../../Shared/Components/dorpdown/Dropdown";
import CommentForm from "./CommentForm";
import classes from "./activities.module.css";
export default function ActivityRepliesBox({
  item,
  commentId,
  isAdmin,
  isTrainer,
  isManager,
  isChapter,
  id,
  discussionId,
  isDisabled,
  timeStatus,
  isClosed,
  reqId,
}) {
  const { t, i18n } = useTranslation();
  const [privateReplayBox, setPrivateReplayBox] = useState(false);
  const dispatch = useDispatch();
  const addReplayToComment = (payload) => {
    dispatch(
      addActivityComment(
        payload,
        isChapter ? getCommentsListHandler : getCommentsListHandlerActivity
      )
    );
  };
  const getCommentsListHandler = () => {
    dispatch(
      fetchChapterCommentsList({
        id: reqId,
        // page: currentPage,
        // perPage: 10,
        // filter,
      })
    );
  };
  const getCommentsListHandlerActivity = () => {
    dispatch(
      fetchActivityCommentsList({
        id: reqId,
        // page: currentPage,
        // perPage: 10,
        // filter,
      })
    );
  };
  const handleDeleteComment = (specialId, parent_id) => {
    if (specialId || parent_id) {
      isChapter
        ? dispatch(deleteChapterComment(specialId, true, parent_id))
        : dispatch(deleteActivityComment(specialId, true, parent_id));
      if (isChapter) {
        getCommentsListHandler();
      } else {
        getCommentsListHandlerActivity();
      }
    } else {
      isChapter
        ? dispatch(deleteChapterComment(commentId, true, id))
        : dispatch(deleteActivityComment(commentId, true, id));
    }
  };
  const imgextensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "webp",
  ];
  const handlePublishComment = (specialId, parent_id) => {
    if (specialId || parent_id) {
      isChapter
        ? dispatch(updateIsPublishedChapterComment(specialId, true, parent_id))
        : dispatch(
            updateIsPublishedActivityComment(specialId, true, parent_id)
          );
      if (isChapter) {
        getCommentsListHandler();
      } else {
        getCommentsListHandlerActivity();
      }
    } else {
      isChapter
        ? dispatch(updateIsPublishedChapterComment(commentId, true, id))
        : dispatch(updateIsPublishedActivityComment(commentId, true, id));
    }
  };
  const handleBanComment = (specialId, parent_id) => {
    if (specialId || parent_id) {
      isChapter
        ? dispatch(updateIsBannedChapterComment(specialId, true, parent_id))
        : dispatch(updateIsBannedActivityComment(specialId, true, parent_id));
      if (isChapter) {
        getCommentsListHandler();
      } else {
        getCommentsListHandlerActivity();
      }
    } else {
      isChapter
        ? dispatch(updateIsBannedChapterComment(commentId, true, id))
        : dispatch(updateIsBannedActivityComment(commentId, true, id));
    }
  };

  const downloadActivityFile = (id, filename) => {
    downloadCommentFile(id, filename)
      .then((res) => {})
      .catch((err) => {
        toast.error(<span style={{ fontSize: 15 }}>{err.data.msg}</span>);
      });
  };

  const replayDropdownOptions = (specialId, parent_id) => [
    ...(!isTrainer
      ? [
          {
            label: t("discussionBoard.delete_replay"),
            onClick: () => handleDeleteComment(specialId, parent_id),
          },
        ]
      : []),
    {
      label: t("discussionBoard.ban_replay"),
      onClick: () => handleBanComment(specialId, parent_id),
    },
    {
      label: t("discussionBoard.publish_replay"),
      onClick: () => handlePublishComment(specialId, parent_id),
    },
  ];
  return (
    <div
      style={{
        borderTop: "0.5px solid  rgb(229 231 235 / var(--tw-border-opacity))",
        paddingTop: "16px",
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        className="tw-border tw-border-gray-200 tw-rounded tw-p-4 tw-space-y-4 border"
        style={{ width: "100%" }}
      >
        <div className="tw-inline-flex tw-items-center tw-justify-between tw-w-full">
          <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
            <div
              className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
              style={{
                backgroundImage: `url(${item.user.image})`,
              }}
            ></div>

            <div className="tw-py-2">
              <div
                className="tw-text-center sm:tw-text-start"
                style={{ color: "#24b3b9" }}
              >
                {item.is_private && (
                  <div className={classes["booth-status-container"]}>
                    <div
                      className={`${classes["booth-status-box"]} ${classes["booth-status-box-private"]}`}
                    >
                      {t("private_replay")}
                    </div>
                  </div>
                )}
                {i18n.language === "ar" ? item.user.name_ar : item.user.name_en}
                <br />
                <span style={{ fontSize: "14px" }} className="tw-text-gray-400">
                  {item.user.email}
                </span>
              </div>
              <div className="tw-text-sm tw-text-gray-400 tw-text-center sm:tw-text-start">
                {`${moment(toLocalTimezone(item.created_at))
                  .local(true)
                  .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(item?.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
              </div>
            </div>
          </div>
          {isAdmin || isTrainer || isManager ? (
            <div style={{ display: "flex" }}>
              {item.is_published === true ? (
                <div className={classes["booth-status-container"]}>
                  <div
                    className={`${classes["booth-status-box"]} ${classes["booth-status-box-published"]}`}
                  >
                    {t("published")}
                  </div>
                </div>
              ) : item.is_banned === true ? (
                <div className={classes["booth-status-container"]}>
                  <div
                    className={`${classes["booth-status-box"]} ${classes["booth-status-box-banned"]}`}
                  >
                    {t("banned")}
                  </div>
                </div>
              ) : (
                <div className={classes["booth-status-container"]}>
                  <div
                    className={`${classes["booth-status-box"]} ${classes["booth-status-box-waiting"]}`}
                  >
                    {t("waiting_publish")}
                  </div>
                </div>
              )}
              <DropDown options={replayDropdownOptions()} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className=" tw-text-sm  ticket_view-replies-body"
          dangerouslySetInnerHTML={{
            __html: item.comment,
          }}
        />
        <div className="tw-px-4">
          {Object.keys(item.attachment ?? {}).length ? (
            imgextensions.includes(
              item.attachment.name.split(".").slice(-1)[0]
            ) ? (
              <>
                <div
                  className="tw-flex tw-flex-col  tw-items-center tw-bg-gray-100 tw-rounded tw-p-2"
                  style={{ width: "25%" }}
                >
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-p-2.5  tw-bg-gray-200">
                      <img
                        src={item.attachment.url}
                        alt=""
                        className=""
                        style={{ height: 120, width: 120 }}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-center">
                    <div>{item.attachment.name}</div>
                    <button
                      type="button"
                      onClick={() => {
                        downloadActivityFile(item.id, item.attachment.name);
                      }}
                    >
                      <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                      <DocumentIcon className="tw-h-4 tw-w-4" />
                    </div>

                    <div>{item.attachment.name}</div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        downloadActivityFile(item.id, item.attachment.name);
                      }}
                    >
                      <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                    </button>
                  </div>
                </div>
              </>
            )
          ) : (
            <></>
          )}
          {item.is_private && (
            <>
              {item.replies.map((replay) => (
                <div
                  style={{
                    borderTop:
                      "0.5px solid  rgb(229 231 235 / var(--tw-border-opacity))",
                    paddingTop: "16px",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <div
                    className="tw-border tw-border-gray-200 tw-rounded tw-p-4 tw-space-y-4 border"
                    style={{ width: "100%" }}
                  >
                    <div className="tw-inline-flex tw-items-center tw-justify-between tw-w-full">
                      <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
                        <div
                          className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
                          style={{
                            backgroundImage: `url(${replay.user.image})`,
                          }}
                        ></div>

                        <div className="tw-py-2">
                          <div
                            className="tw-text-center sm:tw-text-start"
                            style={{ color: "#24b3b9" }}
                          >
                            {item.is_private && (
                              <div
                                className={classes["booth-status-container"]}
                              >
                                <div
                                  className={`${classes["booth-status-box"]} ${classes["booth-status-box-private"]}`}
                                >
                                  {t("private_replay")}
                                </div>
                              </div>
                            )}
                            {i18n.language === "ar"
                              ? replay.user.name_ar
                              : replay.user.name_en}
                            <br />
                            <span
                              style={{ fontSize: "14px" }}
                              className="tw-text-gray-400"
                            >
                              {replay.user.email}
                            </span>
                          </div>
                          <div className="tw-text-sm tw-text-gray-400 tw-text-center sm:tw-text-start">
                            {`${moment(toLocalTimezone(replay.created_at))
                              .local(true)
                              .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(replay?.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
                          </div>
                        </div>
                      </div>
                      {isAdmin || isTrainer || isManager ? (
                        <div style={{ display: "flex" }}>
                          {replay.is_published === true ? (
                            <div className={classes["booth-status-container"]}>
                              <div
                                className={`${classes["booth-status-box"]} ${classes["booth-status-box-published"]}`}
                              >
                                {t("published")}
                              </div>
                            </div>
                          ) : replay.is_banned === true ? (
                            <div className={classes["booth-status-container"]}>
                              <div
                                className={`${classes["booth-status-box"]} ${classes["booth-status-box-banned"]}`}
                              >
                                {t("banned")}
                              </div>
                            </div>
                          ) : (
                            <div className={classes["booth-status-container"]}>
                              <div
                                className={`${classes["booth-status-box"]} ${classes["booth-status-box-waiting"]}`}
                              >
                                {t("waiting_publish")}
                              </div>
                            </div>
                          )}
                          <DropDown
                            options={replayDropdownOptions(replay.id, item.id)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className=" tw-text-sm  ticket_view-replies-body"
                      dangerouslySetInnerHTML={{
                        __html: replay.comment,
                      }}
                    />
                    <div className="tw-px-4">
                      {Object.keys(replay.attachment ?? {}).length ? (
                        imgextensions.includes(
                          replay.attachment.name.split(".").slice(-1)[0]
                        ) ? (
                          <>
                            <div
                              className="tw-flex tw-flex-col  tw-items-center tw-bg-gray-100 tw-rounded tw-p-2"
                              style={{ width: "25%" }}
                            >
                              <div className="tw-flex tw-items-center tw-space-s-2">
                                <div className="tw-p-2.5  tw-bg-gray-200">
                                  <img
                                    src={replay.attachment.url}
                                    alt=""
                                    className=""
                                    style={{ height: 120, width: 120 }}
                                  />
                                </div>
                              </div>
                              <div className="tw-flex tw-justify-center">
                                <div>{replay.attachment.name}</div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    downloadActivityFile(
                                      replay.id,
                                      replay.attachment.name
                                    );
                                  }}
                                >
                                  <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                              <div className="tw-flex tw-items-center tw-space-s-2">
                                <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                                  <DocumentIcon className="tw-h-4 tw-w-4" />
                                </div>

                                <div>{replay.attachment.name}</div>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    downloadActivityFile(
                                      replay.id,
                                      replay.attachment.name
                                    );
                                  }}
                                >
                                  <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {item.is_private && !isDisabled && timeStatus !== "Finished" && (
            <div
              className={classes["replays_container"]}
              style={{ marginTop: 10 }}
            >
              <>
                {/* <div className={classes["button_split_div"]} /> */}
                <div className={classes["replays_button_private"]}>
                  <button
                    style={{ display: "flex" }}
                    onClick={() => {
                      setPrivateReplayBox(!privateReplayBox);
                      // setUserId(item.user.id);
                    }}
                    disabled={
                      isDisabled || timeStatus === "Finished" || isClosed
                        ? true
                        : false
                    }
                  >
                    <div className={classes["comment_buttons"]}>
                      <img
                        src={PrivateReplayIcon}
                        alt=""
                        className={classes["button_img"]}
                      />
                      <span>{t("add_replay_private")}</span>
                    </div>
                  </button>
                </div>
              </>
            </div>
          )}

          {privateReplayBox && item.is_private && (
            <CommentForm
              handleClose={() => setPrivateReplayBox(false)}
              addCommentHandler={addReplayToComment}
              commentId={item.id}
              discussionId={discussionId}
              // userId={userId}
            />
          )}
        </div>
      </div>
    </div>
  );
}
