import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import QuestionTypeEnum, { QuestionTypeWithFile } from "../../../../Enums/QuestionTypeEnum";
import toLocalTimezone from "../../../../Services/Timezone";
import EvaluationApi from "../../../../Services/api/evaluation";
import { exportTraineeExamAnswers, getTraineeExamAnswers } from "../../../../Services/api/exams/ExamsProvider";
import ArrowIcon from "../../../../assets/icons/arrow.png";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DialogModal from "../../../Shared/Components/DialogModal/DialogModal";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import QuestionTypes from "../QuestionTypes";
import CorrectionModal from "./Part/CorrectionModal";
import classes from "./index.module.css";

export default function AssessmentAnswers({ quizable, role, isTrainee = false }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [responses, setResponses] = useState([]);
  const [quiz, setQuiz] = useState({});
  const [seletedResponseIndex, setSeletedResponseIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [correctionAnswer, setCorrectionAnswer] = useState(null);
  const { courseId, quizableId, quizId, id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (!isLoaded && responses.length) {
      let finalAttempt = { index: 0, final_grade: 0 };
      responses.forEach((attempt, index) => {
        if (attempt.final_grade >= finalAttempt.final_grade) {
          finalAttempt.final_grade = attempt.final_grade;
          finalAttempt.index = index;
        }
      });
      setSeletedResponseIndex(finalAttempt.index);
      setIsLoaded(true);
    }
  }, [responses]);

  const exportHandler = async (type) => {
    setIsUpdating(true);
    try {
      await exportTraineeExamAnswers(type, quizId, id ?? 0, "");
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  function fetchData() {
    getTraineeExamAnswers(quizId, id ?? 0, "")
      .then((res) => {
        const responses = res.data.data.response;
        setResponses(responses);

        setQuiz(res.data.data.quiz);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response?.data?.msg ?? t("failed_fetching")}</span>
        );
      });
  }

  const seletedResponse = responses[seletedResponseIndex];

  // const breadcrumbList = [
  //   role == "admin"
  //     ? {
  //         id: t("admin.label.admin_label"),
  //         page: t("admin.label.admin_label"),
  //         pagePath: "/admin",
  //       }
  //     : {
  //         id: "home",
  //         page: <img src={HomeIcon} alt="" />,
  //         pagePath: "/",
  //       },
  //   ...(isTrainee
  //     ? [
  //         {
  //           id: t("Courseoutline"),
  //           page: t("Courseoutline"),
  //           pagePath: `/training-courses/${courseId}/profile`,
  //         },
  //         {
  //           id: t("trainer.quiz.exams"),
  //           page: t("trainer.quiz.exams"),
  //           pagePath: `/trainees/course/${courseId}/${quizable}/${quizableId}/exams`,
  //         },
  //         {
  //           id: id,
  //           page: quiz.title,
  //           pagePath: `/trainees/course/${courseId}/${quizable}/${quizableId}/exam/${quizId}/result`,
  //         },
  //       ]
  //     : [
  //         {
  //           id: t("sidebar.label.courses"),
  //           page: t("sidebar.label.courses"),
  //           pagePath: `/${role}/courses`,
  //         },
  //         {
  //           id: t("Courseoutline"),
  //           page: t("Courseoutline"),
  //           pagePath: `/${role}/course/${courseId}`,
  //         },
  //         {
  //           id: t("trainer.quiz.exams"),
  //           page: t("trainer.quiz.exams"),
  //           pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`,
  //         },
  //         {
  //           id: id,
  //           page: quiz.title,
  //           pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}`,
  //         },
  //         {
  //           id: t("participants"),
  //           page: t("participants"),
  //           pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}/participants`,
  //         },
  //       ]),
  //   {
  //     id: 0,
  //     page: seletedResponse ? seletedResponse.user?.name : "",
  //     active: true,
  //   },
  // ];

  function getDiffInMins(from, to) {
    var diffMs = new Date(toLocalTimezone(to)) - new Date(toLocalTimezone(from)); // milliseconds between now & Christmas
    // var diffDays = Math.floor(diffMs / 86400000); // days
    // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // var diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000);
    var diffMins = Math.floor(diffMs / 60000);
    var diffSecs = Math.floor((diffMs % 60000) / 1000);
    return `${diffMins}${diffSecs ? `:${diffSecs}` : ""}`;
  }

  return (
    <>
      <div style={{ padding: "2rem 0" }}>
        <div className="container-fluid" id={"content-section"}>
          {/* <div className="row">
            <div className="col-12 sm:tw-px-2 tw-py-8">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div> */}

          {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
          {isLoading ? (
            <SkeletonCardOverlay skeletonWidth="100" />
          ) : (
            <>
              {correctionAnswer ? (
                <DialogModal
                  onCancel={() => setCorrectionAnswer(null)}
                  className="tw-w-full sm:tw-w-2/3 md:tw-w-3/5 lg:tw-w-1/2 xl:tw-w-1/3"
                >
                  <CorrectionModal
                    onClose={() => setCorrectionAnswer(null)}
                    onSuccess={() => {
                      setCorrectionAnswer(null);
                      fetchData();
                    }}
                    answer={correctionAnswer}
                    traineeId={id}
                    examId={quizId}
                    responseId={seletedResponse.id}
                    evaluationList={quiz.questions.find((q) => q.id == correctionAnswer.question_id)?.standards}
                  />
                </DialogModal>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-12 ">
                  <MainBox>
                    <div className={classes["back"]} onClick={() => history.goBack()}>
                      <div className={classes["back-icon"]}>
                        <img src={ArrowIcon} alt="back" />
                      </div>
                      <div className={classes["back-text"]}>{t("back")}</div>
                    </div>
                    <div className="tw-space-y-4">
                      <div>
                        <div className="tw-flex tw-items-center tw-justify-between tw-pb-1">
                          <label htmlFor="show_mark_condition" className="tw-block tw-text-gray-600 tw-font-medium">
                            {t("choose_the_try")}
                          </label>
                          {role !== "partner" && (
                            <div className="tw-flex tw-items-center tw-space-s-2">
                              <button
                                type="button"
                                onClick={() => exportHandler("pdf")}
                                className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded tw-bg-[#b91414] tw-border-none"
                              >
                                <img src={PdfIcon} alt="" />
                              </button>
                              <button
                                type="button"
                                onClick={() => exportHandler("xlsx")}
                                className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded tw-bg-[#138d5f] tw-border-none"
                              >
                                <img src={XlsIcon} alt="" />
                              </button>
                            </div>
                          )}
                        </div>
                        <Select
                          id="attachment_number"
                          isClearable
                          defaultValue={{
                            label: seletedResponseIndex + 1,
                            value: seletedResponseIndex,
                          }}
                          styles={{
                            option: (base, state) => ({
                              ...base,
                              color: responses[state.value].is_corrected ? null : "#ff0000aa",
                            }),
                          }}
                          options={[...Array(responses.length).keys()].map((_, i) => ({
                            label: i + 1,
                            value: i,
                          }))}
                          onChange={(item) => {
                            if (item) {
                              setSeletedResponseIndex(item.value);
                            }
                          }}
                          placeholder={t("choose_the_try")}
                        />
                      </div>
                      <div
                        className={`${classes["result_container"]} tw-bg-cyan-600 tw-text-white tw-shadow tw-rounded tw-p-8`}
                      >
                        <div className="tw-flex tw-items-center tw-space-s-8">
                          <div className="tw-space-y-2">
                            <div className={classes["title_font_size"]}>{t("student_final_mark")}</div>
                            {isTrainee ? (
                              <></>
                            ) : (
                              <>
                                <div className="tw-text-sm">
                                  {`${t("exam_date")} ${new Date(
                                    toLocalTimezone(seletedResponse.answer_date)
                                  ).toLocaleDateString()}`}
                                </div>
                                <div className="tw-text-sm">{`${t("pass_percentage")} ${quiz.success_rate}%`}</div>
                              </>
                            )}
                          </div>
                          <div className="tw-flex tw-items-end tw-space-s-1">
                            <div>{Math.round(parseFloat(quiz.total_grade) * 100) / 100}</div>
                            <div>/</div>
                            <div className={classes["mark_font_size"]}>
                              {Math.round(parseFloat(seletedResponse.score) * 100) / 100}
                            </div>
                          </div>
                        </div>
                        <div className={`tw-flex tw-items-center tw-bg-white/10 ${classes["pass_box"]}`}>
                          <div className={`tw-px-8 tw-py-3 ${classes["time_size"]}`}>
                            {`${t("time_in_exam")} ${getDiffInMins(
                              seletedResponse.created_at ?? 0,
                              seletedResponse.answer_date ?? 0
                            )} ${t("minutes")}`}
                          </div>
                          <div
                            className={`tw-bg-white tw-shadow tw-rounded-full tw-px-8 tw-py-3 ${
                              seletedResponse.is_corrected
                                ? seletedResponse.is_success
                                  ? "tw-text-emerald-600"
                                  : "tw-text-red-600"
                                : "tw-text-gray-400 tw-bg-gray-400/10"
                            } tw-text-xl`}
                          >
                            {seletedResponse.is_corrected
                              ? seletedResponse.is_success
                                ? t("success")
                                : t("fail")
                              : t("undefined")}
                          </div>
                        </div>
                      </div>
                      <Formik
                        key={seletedResponseIndex}
                        initialValues={{
                          questions: seletedResponse.answers.map((answer) => ({
                            id: answer.question_id,
                            answers: answer.answers,
                          })),
                        }}
                        onSubmit={async (values) => {}}
                        validate={(values) => {
                          const errors = {};
                          return errors;
                        }}
                        validateOnChange={false}
                      >
                        {({ values, errors, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="tw-space-y-4">
                              {seletedResponse.answers.map((answer, index) => {
                                const question = quiz.questions.find((item) => item.id == answer.question_id);
                                const questionType = QuestionTypes.find((type) => type.type == question?.type_id);
                                return (
                                  <div
                                    key={answer.id}
                                    className="tw-bg-white tw-shadow tw-rounded tw-px-8 tw-py-4 tw-space-y-4"
                                  >
                                    <div className={classes["question_title_container"]}>
                                      <div className="tw-flex tw-items-center tw-space-s-4">
                                        <div className="tw-flex tw-items-center tw-space-s-2 tw-font-bold tw-text-teal-600">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: question.title,
                                            }}
                                          />
                                          <div>
                                            {i18n.language === "ar"
                                              ? `(${question.type.name_ar})`
                                              : `(${question.type.name_en})`}
                                          </div>
                                        </div>
                                        <div className="tw-flex tw-items-center tw-text-gray-400">
                                          <div className="tw-font-bold">{index + 1}</div>
                                          <div>/</div>
                                          <div>{seletedResponse.answers.length}</div>
                                        </div>
                                      </div>
                                      {answer.is_corrected ? (
                                        <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-full tw-border-2 tw-border-green-600 tw-text-green-600">
                                          <div>{Math.round(parseFloat(question.degree) * 100) / 100}</div>
                                          <div>/</div>
                                          <div className="tw-font-bold">
                                            {Math.round(parseFloat(answer.grade) * 100) / 100}
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div>
                                      {QuestionTypeWithFile.includes(question.type_id) ? (
                                        <div className="tw-p-4 tw-flex tw-items-center tw-justify-center">
                                          <div className="tw-text-gray-800 tw-text-7xl tw-font-bold">
                                            {values.questions[index].answers[0]?.answer
                                              ? `${
                                                  Math.round(
                                                    (parseFloat(values.questions[index].answers[0]?.answer * 100) +
                                                      Number.EPSILON) *
                                                      100
                                                  ) / 100
                                                }%`
                                              : ""}
                                          </div>
                                        </div>
                                      ) : question.type_id == QuestionTypeEnum.essay_question ||
                                        question.type_id == QuestionTypeEnum.text ? (
                                        <div className="tw-whitespace-pre-line tw-p-4 tw-border tw-border-gray-200 tw-rounded">
                                          <div className="tw-max-h-24 tw-overflow-x-auto inner-scrollbar tw-pe-4">
                                            {values.questions[index].answers[0]?.answer}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="tw-overflow-x-auto inner-scrollbar">
                                          <div className="tw-pointer-events-none">
                                            <questionType.viewComponent
                                              question={question}
                                              questionIndex={index}
                                              values={values.questions[index]}
                                              errors={errors}
                                              setFieldValue={() => {}}
                                              setValues={() => {}}
                                              revision
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {isTrainee ? (
                                      answer.feedback && (
                                        <div className="tw-text-teal-500 tw-border-b-2 tw-border-teal-500 tw-whitespace-pre-wrap tw-pb-2">
                                          {answer.feedback}
                                        </div>
                                      )
                                    ) : (
                                      <div className="tw-flex tw-items-center tw-justify-end">
                                        {answer.is_manual && role !== "partner" ? (
                                          <button
                                            type="button"
                                            onClick={() => setCorrectionAnswer(answer)}
                                            className="tw-bg-teal-700 tw-rounded tw-px-8 tw-py-3 tw-text-white"
                                          >
                                            {t("grading")}
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </MainBox>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
