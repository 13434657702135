import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../Components";
import CloneSurveyModal from "../../../Modals/CloneSurveyModal/CloneSurveyModal";
import env from "../../../Services/api/Environment";
import { getSurvey, responseSurvey } from "../../../Services/api/survey/SurveyProvider";
import ArrowIcon from "../../../assets/icons/arrow.png";
import { ReactComponent as CloneIcon } from "../../../assets/icons/clone.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import { ReactComponent as ParticipantsIcon } from "../../../assets/icons/participants.svg";
import { ReactComponent as ReportIcon } from "../../../assets/icons/report.svg";
import { getAllQuestionTypes, getQuestionType } from "../../Admin/survey/QuestionTypes";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import PhoneInput from "../../Shared/Components/PhoneInput/PhoneInput";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import classes from "./survey.module.css";

const is_loggedIn = localStorage.getItem("token") ? true : false;

function CalendarIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20 22">
      <g id="calendar_9_" data-name="calendar (9)" transform="translate(-2 -1)">
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="18"
          height="18"
          rx="2"
          transform="translate(3 4)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_1"
          data-name="Line 1"
          y2="4"
          transform="translate(16 2)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          y2="4"
          transform="translate(8 2)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x2="18"
          transform="translate(3 10)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function UserGroupIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24.031 20.112">
      <g id="users_5_" data-name="users (5)" transform="translate(1 1.124)">
        <path
          id="Path_201"
          data-name="Path 201"
          d="M17.023,21.009v-2A4.006,4.006,0,0,0,13.017,15H5.006A4.006,4.006,0,0,0,1,19.006v2"
          transform="translate(-1 -3.02)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <ellipse
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="3.876"
          cy="4.08"
          rx="3.876"
          ry="4.08"
          transform="translate(4.08 0)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_202"
          data-name="Path 202"
          d="M23,21.008v-2a4.006,4.006,0,0,0-3-3.876"
          transform="translate(-0.973 -3.02)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_203"
          data-name="Path 203"
          d="M16,3.13a4.006,4.006,0,0,1,0,7.761"
          transform="translate(-0.979 -3.037)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function CloseIcon({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="10"
          cy="10"
          r="10"
          transform="translate(2 2)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_35"
          data-name="Line 35"
          x1="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_36"
          data-name="Line 36"
          x2="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function CheckIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.307 139.981" className={className}>
      <g id="check-circle_2_" data-name="check-circle (2)" transform="translate(4.013 4.082)">
        <path
          id="Path_213"
          data-name="Path 213"
          d="M129.809,60.059v5.879a63.9,63.9,0,1,1-37.9-58.409"
          transform="translate(0 0)"
          fill="none"
          stroke="#046c77"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="12"
        />
        <path
          id="Path_214"
          data-name="Path 214"
          d="M92.076,4l-63.9,63.968L9,48.8"
          transform="translate(37.733 10.814)"
          fill="none"
          stroke="#046c77"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="12"
        />
      </g>
    </svg>
  );
}

export default function Survey({
  isTrainer = false,
  isTrainee = false,
  preview = false,
  editor = false,
  general = false,
  role,
  fromTrainer = false,
}) {
  const { t } = useTranslation();
  let { id, targetCourseId, targetLessonId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [survey, setSurvey] = useState("");

  useEffect(() => {
    getSurvey(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSurvey(res.data.survey);
          setIsLoading(false);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        
      });
  }, []);

  // function breadcrumbList() {
  //   let breadcrumbList = [];
  //   if (isTrainer || isTrainee || general) {
  //     breadcrumbList = [
  //       {
  //         id: "home",
  //         page: <img src={HomeIcon} alt="" />,
  //         pagePath: "/",
  //       },
  //     ];
  //     if (!isTrainee) {
  //       breadcrumbList.push({
  //         id: t("survey.title"),
  //         page: t("survey.title"),
  //         pagePath: general
  //           ? `/general-surveys`
  //           : targetLessonId
  //           ? `/trainer/course/${targetCourseId}/lesson/${targetLessonId}/survey/list`
  //           : targetCourseId
  //           ? `/trainer/course/${targetCourseId}/survey/list`
  //           : `/trainer/survey/list`,
  //       });
  //     }
  //   } else {
  //     breadcrumbList = [
  //       {
  //         id: "home",
  //         page: t("admin.label.admin_label"),
  //         pagePath: "/admin",
  //       },
  //       {
  //         id: t("survey.title"),
  //         page: t("survey.title"),
  //         pagePath: `/admin/survey${fromTrainer ? "/trainer" : ""}/list`,
  //       },
  //     ];
  //   }
  //   if (preview) {
  //     breadcrumbList.push({
  //       id: t("preview"),
  //       page: t("preview"),
  //       active: true,
  //     });
  //   }
  //   breadcrumbList.push({
  //     id: survey.id,
  //     page: survey.name,
  //     active: true,
  //   });
  //   return breadcrumbList;
  // }

  return (
    <div className="container-fluid !tw-pb-4">
      {isLoading ? (
        <div
          className="mt-5"
          style={{
            height: "65vh",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <div className="tw-mt-8 tw-mb-8">
          <SurveyView
            survey={survey}
            isTrainer={isTrainer}
            preview={preview}
            editor={editor}
            fromTrainer={fromTrainer}
            role={role}
          />
        </div>
      )}
    </div>
  );
}

export function SurveyView({ survey, preview = false, editor = false, fromTrainer = false, role }) {
  const [step, setStep] = useState(1);
  const cloneSurvayModal = useRef();

  const { t } = useTranslation();
  const history = useHistory();

  const forRole = survey?.roles
    ? survey.roles.length == 1
      ? survey.roles[0].name
      : survey.roles.length == 0
      ? "all"
      : "trainerAndTrainee"
    : "";

  const questions = getAllQuestionTypes().map((item, index) => ({
    id: index + 1,
    name: "هنا عنوان السؤال كامل",
    options: [...Array(3)].map((x, i) => ({
      id: index * 3 + (i + 1),
      value: `اختيار ${i + 1}`,
    })),
    items: [...Array(2)].map((x, i) => ({
      id: index * 2 + (i + 1),
      name: `عنصر ${i + 1}`,
    })),
    item_options: [...Array(3)].map((x, i) => ({
      id: index * 3 + (i + 1),
      name: `خيار ${i + 1}`,
    })),
    is_required: (index + 1) % 3 == 0,
    type: item.type,
  }));
  return (
    <>
      <BasicModal ref={cloneSurvayModal}>
        <CloneSurveyModal survey={survey} onClose={() => cloneSurvayModal.current?.dismissModal()} />
      </BasicModal>
      {step == 1 ? (
        <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-10">
          <div className={classes["back"]} onClick={() => history.goBack()}>
            <div className={classes["back-icon"]}>
              <img src={ArrowIcon} alt="back" />
            </div>
            <div className={classes["back-text"]}>{t("back")}</div>
          </div>
          <div className="md:tw-flex tw-items-center tw-justify-between">
            <div className="md:tw-flex tw-items-center tw-justify-center tw-space-s-4">
              <div
                className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-28 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
                style={{ backgroundImage: `url(${survey.logo})` }}
              ></div>
              <div className="tw-space-y-2 tw-py-2">
                <div className="sm:tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-text-lg sm:tw-text-3xl tw-text-teal-700 tw-text-center">{survey.name}</div>
                  {survey.is_shared && editor ? (
                    <div className="tw-text-white tw-bg-teal-500 tw-rounded-full tw-px-4 tw-pb-1 tw-mt-2 tw-text-center">
                      {t("result_shared")}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="tw-space-y-2 sm:tw-flex sm:tw-items-center sm:tw-space-s-4 sm:tw-space-y-0 tw-text-teal-500">
                  {survey.course_id || survey.lesson_id || survey.exhibition_id ? (
                    <></>
                  ) : (
                    <div className="tw-flex tw-items-center tw-justify-center sm:tw-justify-start tw-space-s-2">
                      <CalendarIcon className="tw-h-4" />
                      <div>{`${new Date(survey.start_time).toLocaleDateString()} ${t("to")} ${new Date(
                        survey.end_time
                      ).toLocaleDateString()}`}</div>
                    </div>
                  )}
                  {editor ? (
                    <div className="tw-flex tw-items-center tw-justify-center sm:tw-justify-start tw-space-s-2">
                      <UserGroupIcon className="tw-h-4" />
                      <div>
                        {t(`addressed`)} {t(`survey.select.option.${forRole}`)}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {editor ? (
                  <div className="tw-text-gray-500 tw-text-center sm:tw-text-start">
                    {t("survey_link")}: {t(survey.is_public ? "public" : "private")}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {editor && survey.id ? (
              <div className="tw-flex tw-items-center tw-justify-center sm:tw-justify-start  tw-space-s-6">
                <button type="button" onClick={() => cloneSurvayModal.current?.showModal()}>
                  <IconsTooltip
                    title={t("survey.addClone")}
                    content={<CloneIcon className="tw-h-7 tw-aspect-square" />}
                  />
                </button>
                <NavLink to={`/${role}/survey${fromTrainer ? "/trainer" : ""}/${survey.id}/participants`}>
                  <IconsTooltip
                    title={t("participants")}
                    content={<ParticipantsIcon className="tw-h-7 tw-aspect-square" />}
                  />
                </NavLink>
                <NavLink to={`/${role}/survey${fromTrainer ? "/trainer" : ""}/edit/${survey.id}`}>
                  <IconsTooltip
                    title={t("survey.editButton")}
                    content={<EditIcon className="tw-h-8 tw-p-1.5 tw-aspect-square" />}
                  />
                </NavLink>
                <NavLink
                  to={`/${role}/survey${fromTrainer ? "/trainer" : ""}/${survey.id}/questions`}
                  className="tw-bg-gray-500 tw-rounded-lg"
                >
                  <IconsTooltip
                    title={t("sidebar.report.title")}
                    content={<ReportIcon className="tw-h-8 tw-p-1.5 tw-aspect-square tw-stroke-white" />}
                  />
                </NavLink>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="tw-space-y-3">
            <div className="tw-text-lg tw-font-bold tw-text-teal-700">{t("survey_desc")}</div>
            <div className="tw-text-gray-500" dangerouslySetInnerHTML={{ __html: survey.description }} />
          </div>
          <div>
            <button
              type="button"
              disabled={!survey.questions?.length}
              onClick={() => setStep(is_loggedIn || preview ? 3 : 2)}
              className="tw-bg-teal-700 tw-py-3 tw-px-16 tw-rounded tw-text-white"
            >
              {t("start_survey")}
            </button>
          </div>
        </div>
      ) : step == 2 || step == 3 || step == 4 ? (
        // <SurveyPresenter
        //   questions={[{
        //     id: 1,
        //     questions: questions,
        //     type: 'section',
        //   }]}
        //   survey={survey}
        //   courseId={null}
        //   surveyId={survey.id}
        //   loading={false}
        //   canAnswer={true}
        //   setIsLoading={() => {}}
        //   setIsSubmitted={() => {}}
        // />
        <QuestionResponseWarpper survey={survey} step={step} setStep={setStep} preview={preview} />
      ) : (
        <div className="tw-bg-white tw-shadow tw-rounded tw-p-8">
          <div className={classes["back"]} onClick={() => history.goBack()}>
            <div className={classes["back-icon"]}>
              <img src={ArrowIcon} alt="back" />
            </div>
            <div className={classes["back-text"]}>{t("back")}</div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
            <div className="tw-h-20 tw-aspect-square">
              <CheckIcon />
            </div>
            <div
              className="tw-text-xl tw-font-bold tw-text-teal-700"
              dangerouslySetInnerHTML={{
                __html: survey.thanks_message ? survey.thanks_message : t("survey.default_thanks_message"),
              }}
            ></div>
            <div>
              <NavLink to="/" className="tw-bg-teal-700 tw-py-3 tw-px-16 tw-rounded tw-text-white hover:!tw-text-white">
                {t("back_to_home")}
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function QuestionResponseWarpper({ survey, step, setStep, preview = false }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [unComplitedQuestions, setUnComplitedQuestions] = useState([]);
  const recaptchaRef = useRef();

  const fromikRef = useRef();

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  const questions = survey.questions;
  // const questions = getAllQuestionTypes().map((item, index) => ({
  //   id: index + 1,
  //   name: "هنا عنوان السؤال كامل",
  //   is_required: 1,
  //   options: [...Array(3)].map((x, i) => ({
  //     id: index * 3 + (i + 1),
  //     value: `اختيار ${i + 1}`,
  //   })),
  //   items: [...Array(2)].map((x, i) => ({
  //     id: index * 2 + (i + 1),
  //     name: `عنصر ${i + 1}`,
  //   })),
  //   item_options: [...Array(3)].map((x, i) => ({
  //     id: index * 3 + (i + 1),
  //     name: `خيار ${i + 1}`,
  //   })),
  //   is_required: (index + 1) % 3 == 0,
  //   type: item.type,
  // }));
  const questionType = getQuestionType(questions[questionIndex].type);

  const { t } = useTranslation();

  function getListErrorKeys(params) {
    let arr = [];
    params.forEach((element, index) => {
      arr.push(index);
    });
    return arr;
  }

  return (
    <Formik
      ref={fromikRef}
      initialValues={{
        name: "",
        email: "",
        mobile: "",
        code: "",
        questions: questions.map((question) => ({
          id: question.id,
          answers: [],
        })),
      }}
      onSubmit={async (values, { setErrors }) => {
        values.phone = `${values.code}${values.mobile}`;
        values.questions = values.questions.map((v) => ({
          ...v,
          answers: v.answers.length ? v.answers : [{ value: "" }],
        }));
        if (preview) {
          return;
        }

        // check for Recaptcha
        // if (!isVerified) {
        //   toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.errors.recaptcha")}</span>, {
        //     autoClose: 3000,
        //   });
        //   recaptchaRef.current?.execute();
        //   return;
        // }
        try {
          const res = await responseSurvey(values, survey.id);

          if (res.data.errNum == 201) {
            toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.thanksForAnswered")}</span>, {
              autoClose: 3000,
            });
            setStep(5);
          } else {
            throw res;
          }
        } catch (err) {
          // if (!!error.data?.errors) setErrors(error.data?.errors);
          // if (error.data?.msg) {
          //   toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data?.msg}</span>);
          // }
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {typeof err === "string" ? (
                <span style={{ fontSize: 12, fontWeight: "500", display: "block" }}>{err}</span>
              ) : err.data ? (
                <>
                  <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{err.data.msg}</span>
                  <ul>
                    {Object.keys(err.data.errors).map((key) => {
                      return (
                        <li>
                          <span style={{ fontSize: 11 }}>{err.data.errors[key]}</span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                t("Failure_in_service")
              )}
            </span>
          );
        }
      }}
      validate={(values) => {
        setHasSubmitted(true);
        const errors = {};
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const mobileRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (!is_loggedIn) {
          if (!values.name) {
            errors.name = t("crud.errors.required");
          }
          if (!values.email) {
            errors.email = t("crud.errors.required");
          } else if (regex.test(values.email) === false) {
            errors.email = t("error_msg_shape_email");
          }
          if (values.mobile && mobileRegex.test(`${values.code}${values.mobile}`) === false) {
            errors.mobile = t("error_msg_shape_mobile");
          }
        }
        if (step > 2) {
          let questionsErrors = [];
          values.questions.forEach((question, index) => {
            const findOrg = questions.find((i) => i.id == question.id);

            if (findOrg.is_required) {
              if (!question.answers.length) {
                questionsErrors[index] = t("crud.errors.required");
              } else if (
                (findOrg.items.length && question.answers.length < findOrg.items.length) ||
                (findOrg.item_options.length && question.answers.length < findOrg.items.length * findOrg.options.length)
              ) {
                questionsErrors[index] = t("crud.errors.required");
              } else if (findOrg.type == "drag_and_drop" && question.answers.length < findOrg.options.length) {
                questionsErrors[index] = t("crud.errors.required");
              }

              let answersErrors = [];
              question.answers.map((answer, answerIndex) => {
                let error;
                if (!answer.value && answer.value !== 0) {
                  error = t("crud.errors.required");
                } else if (findOrg.type == "email" && regex.test(answer.value) === false) {
                  error = t("error_msg_shape_email");
                }

                if (error) {
                  answersErrors[answerIndex] = {
                    value: error,
                  };
                }
              });
              if (answersErrors.length) {
                questionsErrors[index] = { answers: answersErrors };
              }
            }
          });

          if (questionsErrors.length) {
            errors.questions = questionsErrors;
          }
        }
        return errors;
      }}
      validateOnChange={hasSubmitted}
    >
      {({
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handlevalidate,
        isSubmitting,
        resetForm,
        submitForm,
        validateForm,
      }) => (
        <form onSubmit={handleSubmit}>
          {step == 4 ? (
            <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-py-8 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
              <div className="tw-h-20 tw-aspect-square">
                <CloseIcon />
              </div>
              <div className="tw-text-xl tw-font-bold tw-text-red-600">
                {t("You did not answer all the obligatory questions")}
              </div>
              <div className="tw-flex tw-items-center tw-mx-auto tw-space-s-4 tw-py-4 tw-max-w-full tw-overflow-x-auto inner-scrollbar">
                {unComplitedQuestions.map((qi) => (
                  <button
                    key={qi}
                    type="button"
                    onClick={() => {
                      setQuestionIndex(qi);
                      setStep(3);
                    }}
                    className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-aspect-square tw-p-2 tw-rounded-lg tw-bg-white tw-border tw-border-gray-300 tw-text-gray-600"
                  >
                    {qi + 1}
                  </button>
                ))}
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setQuestionIndex(unComplitedQuestions[0]);
                    setStep(3);
                  }}
                  className="tw-bg-teal-700 tw-py-3 tw-px-16 tw-rounded tw-text-white hover:!tw-text-white"
                >
                  {t("back")}
                </button>
              </div>
            </div>
          ) : step == 2 ? (
            <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-flex tw-items-center tw-space-s-2 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600">
                  <div>{t("To be able to participate in the survey, please enter the following information")}</div>
                </div>
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="tw-bg-red-600 tw-p-2 tw-rounded-lg ltr:tw-rotate-180"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="log-out_2_" data-name="log-out (2)" transform="translate(-2 -2)">
                      <path
                        id="Path_204"
                        data-name="Path 204"
                        d="M9,21H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H9"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Path_205"
                        data-name="Path 205"
                        d="M16,17l5-5L16,7"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <line
                        id="Line_33"
                        data-name="Line 33"
                        x1="12"
                        transform="translate(9 12)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="tw-pb-4 tw-space-y-4">
                <div>
                  <label className="tw-block mb-0">
                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("Exhibition.popupusername")} *</div>

                    <Field
                      name="name"
                      className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                      placeholder={t("Exhibition.popupusername")}
                    />
                  </label>
                  <ErrorMessage name={`name`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                </div>

                <div>
                  <PhoneInput
                    label={t("Exhibition.popupphone")}
                    type="number"
                    nameInput="mobile"
                    value={values.mobile}
                    nameSelect="code"
                    selectValue={values.code}
                    placeholder={t("Exhibition.popupphone")}
                    onChange={handleChange}
                    error={errors.mobile}
                    min={0}
                  />
                </div>
                <div>
                  <label className="tw-block mb-0">
                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("email")} *</div>

                    <Field
                      name="email"
                      className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                      placeholder={t("email")}
                    />
                  </label>
                  <ErrorMessage name={`email`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                </div>
              </div>
              <div className="tw-flex tw-items-center">
                <button
                  type="button"
                  onClick={() =>
                    validateForm(values).then((errors) => {
                      if (Object.keys(errors).length == 0) {
                        setHasSubmitted(false);
                        setStep(3);
                      } else {
                        handleSubmit();
                      }
                    })
                  }
                  className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-3 tw-px-16 tw-rounded tw-text-white"
                >
                  {t("enter")}
                </button>
              </div>
            </div>
          ) : survey.one_page ? (
            <div className="tw-space-y-4">
              {questions.map((question, index) => {
                const questionType = getQuestionType(question.type);
                return (
                  <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2">
                        <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600">
                          <div>{question.name}</div>
                          <div>{`(${t(`survey_types.${question.type}`)})`}</div>
                        </div>
                        <div className="tw-text-gray-400 sm:tw-text-2xl">
                          ({question.is_required ? t("survey.required") : t("survey.optional")})
                        </div>
                      </div>
                      <div className="tw-flex tw-items-center tw-space-s-2">
                        <div
                          className={`tw-font-bold ${
                            errors.questions &&
                            (Array.isArray(errors.questions)
                              ? getListErrorKeys(errors.questions).includes(index)
                              : question.is_required)
                              ? "tw-text-red-600"
                              : ""
                          }`}
                        >
                          {index + 1}
                        </div>
                        <div className="tw-text-gray-400">/</div>
                        <div className="tw-text-gray-400">{questions.length}</div>
                      </div>
                    </div>
                    <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
                      <questionType.responseComponent
                        question={question}
                        prefix={`questions.${index}.`}
                        values={values.questions[index]}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                );
              })}

              {/* <div className={`${preview || isVerified ? "tw-hidden" : ""}`}>
                <ReCAPTCHA className="tw-px-2 sm:tw-px-0 tw-w-full" sitekey={env.siteKey} onChange={verifyCallback} />
              </div> */}

              <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-flex tw-items-center tw-justify-center tw-space-s-6">
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="tw-bg-red-600 tw-py-3 tw-px-16 tw-rounded tw-text-white"
                >
                  {t("exit")}
                </button>
                <button
                  disabled={isSubmitting}
                  type="button"
                  onClick={() => {
                    return preview
                      ? setStep(1)
                      : validateForm(values).then((errors) => {
                          if (errors?.questions?.length) {
                            setUnComplitedQuestions(getListErrorKeys(errors.questions));
                            toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {t("You did not answer all the obligatory questions")}
                              </span>,
                              {
                                autoClose: 3000,
                              }
                            );
                          } else {
                            handleSubmit();
                          }
                        });
                  }}
                  className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-3 tw-px-16 tw-rounded tw-text-white"
                >
                  {t("submit")}
                </button>
              </div>
            </div>
          ) : (
            <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2">
                  <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600">
                    <div>{questions[questionIndex].name}</div>
                    <div>{`(${t(`survey_types.${questions[questionIndex].type}`)})`}</div>
                  </div>
                  <div className="tw-text-gray-400 sm:tw-text-2xl">
                    ({questions[questionIndex].is_required ? t("survey.required") : t("survey.optional")})
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="tw-bg-red-600 tw-p-2 tw-rounded-lg ltr:tw-rotate-180"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="log-out_2_" data-name="log-out (2)" transform="translate(-2 -2)">
                      <path
                        id="Path_204"
                        data-name="Path 204"
                        d="M9,21H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H9"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Path_205"
                        data-name="Path 205"
                        d="M16,17l5-5L16,7"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <line
                        id="Line_33"
                        data-name="Line 33"
                        x1="12"
                        transform="translate(9 12)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
                <questionType.responseComponent
                  question={questions[questionIndex]}
                  prefix={`questions.${questionIndex}.`}
                  values={values.questions[questionIndex]}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </div>
              {/* {preview ? null : (
                <div
                  className={`${
                    isVerified ||
                    (questionIndex < questions.length - 1 &&
                      (!unComplitedQuestions.length || unComplitedQuestions.at(-1) != questionIndex))
                      ? "tw-hidden"
                      : ""
                  }`}
                >
                  <ReCAPTCHA className="tw-px-2 sm:tw-px-0 tw-w-full" sitekey={env.siteKey} onChange={verifyCallback} />
                </div>
              )} */}

              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-flex tw-items-center tw-space-s-6">
                  <button
                    disabled={isSubmitting}
                    type="button"
                    onClick={() => {
                      return questionIndex < questions.length - 1 &&
                        (!unComplitedQuestions.length || unComplitedQuestions.at(-1) != questionIndex)
                        ? setQuestionIndex(
                            unComplitedQuestions.length && unComplitedQuestions.find((i) => i > questionIndex)
                              ? unComplitedQuestions.find((i) => i > questionIndex)
                              : questionIndex + 1
                          )
                        : preview
                        ? setStep(1)
                        : validateForm(values).then((errors) => {
                            if (errors?.questions?.length) {
                              let arr = [];
                              errors.questions.forEach((element, index) => {
                                arr.push(index);
                              });

                              setUnComplitedQuestions(arr);
                              setStep(4);
                            } else {
                              handleSubmit();
                            }
                          });
                    }}
                    className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-3 tw-px-16 tw-rounded tw-text-white"
                  >
                    {questionIndex < questions.length - 1 &&
                    (!unComplitedQuestions.length || unComplitedQuestions.at(-1) != questionIndex)
                      ? t("next")
                      : t("submit")}
                  </button>
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-font-bold">{questionIndex + 1}</div>
                    <div className="tw-text-gray-400">/</div>
                    <div className="tw-text-gray-400">{questions.length}</div>
                  </div>
                </div>
                {(questionIndex > 0 || !is_loggedIn) &&
                (!unComplitedQuestions.length || unComplitedQuestions.find((i) => i < questionIndex)) ? (
                  <button
                    type="button"
                    onClick={() =>
                      questionIndex > 0
                        ? setQuestionIndex(
                            unComplitedQuestions.length
                              ? [...unComplitedQuestions].reverse().find((i) => i < questionIndex)
                              : questionIndex - 1
                          )
                        : setStep(2)
                    }
                    className="tw-border-2 tw-rounded tw-border-teal-500 tw-p-3 tw-aspect-square ltr:tw-rotate-180"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.162 16.99" className="tw-h-3 tw-w-3">
                      <g
                        id="arrow-down_6_"
                        data-name="arrow-down (6)"
                        transform="matrix(-0.017, 1, -1, -0.017, 20.353, -3.236)"
                      >
                        <line
                          id="Line_42"
                          data-name="Line 42"
                          y2="14"
                          transform="translate(12 5)"
                          fill="none"
                          stroke="#24b3b9"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_215"
                          data-name="Path 215"
                          d="M19,12l-7,7L5,12"
                          fill="none"
                          stroke="#24b3b9"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
}
