import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCourseIntroVideo } from "../../../../Redux/Actions/courses/coursesList.action";
import { fromKbToMb } from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import Exit from "../../../../assets/icons/delete.svg";
import UploadIcon from "../../../../assets/icons/upload.svg";
import youtube from "../../../../assets/icons/youtube.svg";
import i18n from "../../../../i18n/i18n";
import CustomButton from "../CustomButton/CustomButton";
import Input from "../Input/Input";
import SkeletonCardOverlay from "../Spinner/SkeletonCardOverlay";
import styles from "./IntroVideoModal.module.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const IntroVideoModal = ({ openModal, setOpenModal, modalTitle, viewIntroVideo, loadingVideo, video, setVideo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [videoLink, setVideoLink] = useState(video ? video : "");
  const [videoLinkToView, setVideoLinkToView] = useState(video ? video : "");
  const [videoLinkError, setVideoLinkError] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [listTypeFile, setListTypeFile] = useState([]);

  const { addIntroVideoLoading } = useSelector((state) => state.coursesListReducer);

  // useEffect(() => {
  //   getListTypeFile();
  // }, []);

  // useEffect(() => {
  //   if (viewIntroVideo && video) {
  //     setVideoFile(video);
  //   }
  // }, [video]);

  useEffect(() => {
    if (videoLink && !videoLink.startsWith("https://") && !videoLink.startsWith("http://")) {
      setVideoLinkError(t("crud.errors.link"));
    } else if (videoLink && !videoLink.includes("youtube.com") && !videoLink.includes("youtu.be")) {
      setVideoLinkError(t("crud.errors.youtube_link"));
    } else {
      setVideoLinkError(null);
    }
  }, [videoLink]);

  // const getListTypeFile = () => {
  //   const myPromise = new Promise((resolve, reject) => {
  //     getListType().then(
  //       (res) => {
  //         setListTypeFile(res?.data?.data);
  //         resolve();
  //       },
  //       (error) => {
  //         toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
  //         reject();
  //       }
  //     );
  //   });

  //   return myPromise;
  // };

  // const getTypeFile = () => {
  //   return listTypeFile.filter((file) => file.type === "mp4");
  // };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.startsWith("video")) {
  //     const sizeInKB = (file.size / 1024).toFixed(2); // Convert file size to KB
  //     if (Number(sizeInKB) > getTypeFile()[0]?.max_size) {
  //       toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("validation.video_size_incorrect")}</span>);
  //     } else {
  //       setVideoFile(file);
  //     }
  //   } else {
  //     alert("Please select a valid video file");
  //   }
  // };

  const handleSubmit = () => {
    let linkToSubmit = videoLink.replace("watch?v=", "embed/").split("&")[0];
    const data = {
      link: linkToSubmit,
    };
    // example: https://www.youtube.com/watch?v=3JZ_D3ELwOQ&ab_channel=TheNetNinja
    dispatch(addCourseIntroVideo(data, setOpenModal));
    setVideo(linkToSubmit);
  };

  return loadingVideo ? (
    <SkeletonCardOverlay skeletonWidth="100" />
  ) : (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openModal}>
        <Box className={styles.modalContainer}>
          <div className={styles.modal_container}>
            <div className={styles.modal_header_row}>
              <h2 id="modal-modal-title">{modalTitle}</h2>
              <button type="button" onClick={() => setOpenModal(false)}>
                <div className={styles.modal_close_icon}>
                  <img src={Exit} alt="" />
                </div>
              </button>
            </div>

            <Divider className={styles.modal_header_divider} />
            {!viewIntroVideo && (
              <div className={`col-12`}>
                <Input
                  label={t(`trainer.course.video`)}
                  type="text"
                  name="video"
                  placeholder={t(`trainer.course.video`)}
                  onChange={(event) => {
                    setVideoLink(event.target.value);
                  }}
                  value={videoLink}
                  error={videoLinkError}
                  note={t("trainer.course.video_note")}
                />
              </div>
            )}

            {/* {!viewIntroVideo && (
              <div className={styles.upload_video_container}>
                <Button component="label" variant="contained" className={styles.uploadBtn}>
                  <img src={UploadIcon} alt="" className={styles.modal_upload_icon} />
                  <p className={styles.modal_upload_restriction_msg}>
                    {i18n.language === "ar"
                      ? `إختر فيديو وحجمه لا يتعدى ${Number(fromKbToMb(getTypeFile()[0]?.max_size)).toFixed(
                          2
                        )} ميجا بايت`
                      : `Choose video with maximum size of ${Number(fromKbToMb(getTypeFile()[0]?.max_size)).toFixed(
                          2
                        )} MB`}
                  </p>
                  <VisuallyHiddenInput type="file" accept="video/*" onChange={handleFileUpload} />
                </Button>
              </div>
            )} */}

            {/* {videoFile ? (
              <video width="100%" controls>
                <source src={viewIntroVideo ? videoFile : URL.createObjectURL(videoFile)} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className={styles.preview_video_container}>
                <img src={youtube} alt="" className={styles.modal_youtube_icon} />
              </div>
            )} */}

            {videoLinkToView && (
              // <video width="100%" controls>
              //   <source src={videoLinkToView} type="video/mp4" />
              //   Your browser does not support the video tag.
              // </video>
              <iframe
                width="100%"
                height="350"
                className={styles.video_iframe}
                src={videoLinkToView}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {!viewIntroVideo && (
              <div className={styles.done_btn_container}>
                <CustomButton
                  value={!addIntroVideoLoading ? t("done") : ""}
                  loading={addIntroVideoLoading}
                  action={handleSubmit}
                  disable={!videoLink || videoLinkError || addIntroVideoLoading}
                  colors="#046c77"
                  classes={styles.customButton}
                />
              </div>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default IntroVideoModal;
