import { useTheme } from "@material-ui/core/styles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import HeaderLogo from "../../../assets/icons/newHeader2024.svg";
import adminDoc from "../../../assets/image/admin-doc.png";
import dollar from "../../../assets/image/dollar.png";
import help from "../../../assets/image/help.png";
import not from "../../../assets/image/notification.png";
import ar from "../../../assets/image/saudi-arabia.png";
import settings from "../../../assets/image/settings.png";
import sup from "../../../assets/image/support.png";
import en from "../../../assets/image/united-kingdom.png";
import user from "../../../assets/image/user(2).png";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Sidebar from "../sidebar/sidebar";
import Notification from "./Notification";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import DropDown from "../../Shared/Components/dorpdown/Dropdown";
import BurgerMenuIcon from "../../../assets/icons/BurgerMenu.svg";
import Arrow from "../../../assets/icons/ArrowDownGreen.svg";
import { useHistory } from "react-router-dom";
import UserDropDown from "../../Shared/Components/Header/DynamicHeaderWithoutLogin/UserDropDwon/UserDropDown";
import classes from "./adminHeader.module.css";
function AdminHeader(props) {
  const history = useHistory();
  require("./adminHeader.css");
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const theme = useTheme();
  const type_login = localStorage.getItem("type_login");
  const authContext = useContext(AuthContext);
  const [sidebar, setSidebar] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("type");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("phone");
    localStorage.removeItem("type");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("type_login");
    localStorage.removeItem("userId");
    localStorage.removeItem("chatId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const sidebarHandler = (event) => {
    event.preventDefault();
    setSidebar(!sidebar);
  };

  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setSidebar(false);
    }, 300);
  };

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [sidebar]);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };
  const languageOptions = [
    {
      label: "English",
      onClick: () => changeLanguage("en"),
    },
    {
      label: "العربية",
      onClick: () => changeLanguage("ar"),
    },
  ];
  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  }

  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : []),

    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    {
      label: t("account_info"),
      onClick: () => history.push("/private-profile"),
    },
    {
      label: t("calendar"),
      onClick: () => history.push("/calendar"),
    },
    {
      label: t("admin.label.list_tickets"),
      onClick: () =>
        history.push(
          `${
            localStorage.getItem("type") === "admin"
              ? "/admin/tickets"
              : "/tickets"
          }`
        ),
    },

    {
      label: t("sidebar.label.live_chat"),
      onClick: () => history.push("/livechat"),
    },

    ...(type_login === "normal"
      ? [
          {
            label: t("accountde"),
            onClick: () => history.push("/update-password"),
          },
        ]
      : []),

    {
      label: t("logout"),
      onClick: logout,
    },
  ];

  return (
    <div className={classes["header_shadow"]}>
      {sidebar === true ? (
        <>
          <Sidebar
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={props.roles}
          />
          <div className={classes.backdrop_admin} onClick={onConfirm} />
        </>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className={classes.adminNav}>
              <div className={classes.navbar_logo_admin}>
                <div
                  className={classes["hamburger-pointer"]}
                  onClick={sidebarHandler}
                >
                  <img
                    src={BurgerMenuIcon}
                    alt=""
                    style={{
                      transform: `${
                        i18n.language === "ar" && "rotateY(180deg)"
                      }`,
                      width: 30,
                      minWidth: 30,
                      height: 40,
                      minHeight: 40,
                    }}
                  />
                </div>
                <NavLink exact to="/">
                  <img
                    src={HeaderLogo}
                    className={classes.image_logo_new}
                    alt="..."
                  />
                </NavLink>
              </div>

              <div className={classes.flex_menu}>
                <Notification isAdmin={true} />
                {buttonLang}
                <UserDropDown options={userOptions} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminHeader);

// <button
//   className="navbar-toggler"
//   type="button"
//   data-toggle="collapse"
//   data-target="#navbarSupportedContent"
//   aria-controls="navbarSupportedContent"
//   aria-expanded="false"
//   aria-label="Toggle navigation"
// >
//   <img
//     src={BurgerMenuIcon}
//     alt=""
//     style={{
//       transform: `${i18n.language === "en" && "rotateY(180deg)"}`,
//       width: 20,
//       height: 30,
//     }}
//   />
// </button>
// <div
//   className="collapse navbar-collapse navbarSmallDeviceForAdmin"
//   id="navbarSupportedContent"
// >
//   <hr />
//   <ul className="navbar-nav mr-auto ">
//     <li className="dropdown margin_en">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <PermIdentityOutlinedIcon />
//           {t("sidebar.label.user_management")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/roles">
//             {t("sidebar.label.user_management")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/message/send">
//             {t("sidebar.label.send_messages")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/create-account">
//             {t("sidebar.label.addUser")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/trainer-rate/list">
//             {t("sidebar.label.trainer_ratings")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <AttachMoneyIcon />
//           {t("sidebar.label.financial_management")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/coupon`}
//           >
//             {t("sidebar.label.coupon")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/offer`}
//           >
//             {t("sidebar.label.offer")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/tax`}
//           >
//             {t("sidebar.label.tax")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/financial-movement`}
//           >
//             {t("sidebar.label.financial_movement")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/refund`}
//           >
//             {t("sidebar.label.all_refunds")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/refund/exhibition-visitors`}
//           >
//             {t("sidebar.label.exhibition_visitors_refunds")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/refund/exhibition-booth`}
//           >
//             {t("sidebar.label.exhibition_booth_refunds")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/peyment-requests-courses`}
//           >
//             {t("sidebar.label.payment_requests_courses")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/peyment-requests-programs`}
//           >
//             {t("sidebar.label.payment_requests_programs")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/program-payment-date?program_payment_date=all`}
//           >
//             {t("refunds_for_trainers")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink
//             to={`/${
//               localStorage.getItem("type") === "accountant"
//                 ? "accountant"
//                 : "admin"
//             }/percentage-trainer/list`}
//           >
//             {t("Percentage_trainers_courses")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>

//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <FormatAlignCenterOutlinedIcon />
//           {t("sidebar.label.content_development")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/category">
//             {t("specialties_and_departments")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/courses">
//             {t("sidebar.label.courses")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/program/list">
//             {t("sidebar.label.program")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/certificateTemplates">
//             {t("sidebar.label.certificate_templates")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <InsertDriveFileOutlinedIcon />
//           {t("sidebar.label.list_of_survey")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/survey/add">
//             {t("survey.add")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/survey/list">
//             {t("survey.listSideBar")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/survey/trainer/list">
//             {t("survey.trainerListSideBar")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <BookmarkBorderOutlinedIcon />
//           {t("sidebar.label.Exhibition")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/create-exhibition">
//             {t("Exhibition.title_create")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/exhibitions-list">
//             {t("Exhibition.Exhibition")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <ShieldOutlinedIcon />
//           {t("sidebar.label.protection")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/ip-blocked">
//             {t("sidebar.label.list_blocked")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/ip-blocked/requests">
//             {t("sidebar.label.delete_list_blocked")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <HelpOutlineOutlinedIcon />
//           {t("sidebar.label.support")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/tickets">
//             {t("sidebar.label.support_tickets")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/livechat">
//             {t("sidebar.label.live_chat")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/faq-list">
//             {t("FAQ.list_more_common_questions")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/faq">
//             {t("FAQ.list_common_questions")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <ManageSearchIcon />
//           {t("search.search_reports")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/search/free">
//             {t("search.free_search")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/search">
//             {t("search.conduct_search")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/search/history">
//             {t("search.search_history")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <ScatterPlotOutlinedIcon />
//           {t("sidebar.label.learning_analysis")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/learning-analysis">
//             {t("sidebar.label.analysis")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/statistics">
//             {t("sidebar.label.statistics")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <DnsOutlinedIcon />
//           {t("sidebar.label.system_preferences")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/preferences">
//             {t("sidebar.label.enable_services")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/typefile/list">
//             {t("sidebar.label.files_system")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/webx/list">
//             {t("zoom.Webx_teams")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/logs">
//             {t("sidebar.label.history_move_system")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <NotesOutlinedIcon color="#046C77" />
//           {t("system_preferences.page_text")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/about-us-edit">
//             {t("sidebar.label.about-us")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/terms-and-conditions">
//             {t("sidebar.label.TermsAndConditions")}
//           </NavLink>
//         </li>
//         <li
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//         >
//           <NavLink to="/admin/privacy-policy">
//             {t("sidebar.label.PrivacyPolicy")}
//           </NavLink>
//         </li>
//       </ul>
//     </li>
//     {/* <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <img src={help} alt="" className="menu_icon" />
//           {t("sidebar.label.general_settings")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li>
//           <NavLink to="/admin/about-us">
//             {t("sidebar.label.about-us")}
//           </NavLink>
//         </li>
//       </ul>
//     </li> */}
//     {/* <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <img src={help} alt="" className="menu_icon" />
//           {t("sidebar.label.technical_support")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li>
//           <NavLink to="">
//             {t("sidebar.label.technical_support")}
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to="">
//             {t("sidebar.label.technical_support")}
//           </NavLink>
//         </li>
//       </ul>
//     </li> */}
//     {/* <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <img src={computer} alt="" className="menu_icon" />
//           {t("sidebar.label.virtual_galleries")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li>
//           <NavLink to="">
//             {t("sidebar.label.create_virtual_galleries")}
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to="">{t("sidebar.label.add_file")}</NavLink>
//         </li>
//         <li>
//           <NavLink to="">{t("sidebar.label.add_share")}</NavLink>
//         </li>
//         <li>
//           <NavLink to="">
//             {t("sidebar.label.add_prodect")}
//           </NavLink>
//         </li>
//       </ul>
//     </li> */}
//     {/* <li className="dropdown">
//       <a
//         href="#"
//         className="dropdown-toggle"
//         data-toggle="dropdown"
//         role="button"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <div className="">
//           <img src={help} alt="" className="menu_icon" />
//           {t("sidebar.label.path_restriction")}
//         </div>
//         <span className="caret"></span>
//       </a>
//       <ul className="dropdown-menu">
//         <li>
//           <NavLink to="">
//             {t("sidebar.label.path_restriction")}
//           </NavLink>
//         </li>
//         <li>
//           <NavLink to="">
//             {t("sidebar.label.path_restriction")}
//           </NavLink>
//         </li>
//       </ul>
//     </li> */}
//   </ul>
// </div>
