import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  getExhibitionById,
  payExhibitionBooth,
  payExhibitionVisiter,
} from "../../../Redux/Actions/exhibition/exhibition.action";
import { toServerTime } from "../../../Services/Timezone";
import ArrowIcon from "../../../assets/icons/arrow.png";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import ExhibitionPaymentDetails from "./ExhibitionPaymentDetails";
import ExhibitionPaymentForm from "./ExhibitionPaymentForm";
import classes from "./exhibitionPayment.module.css";

export default function ExhibitionPayment({ type }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const dispatch = useDispatch();

  const { exhibition, exhibitionDashboard, exhibitionLoading, payExhibitionVisiterLoading, payExhibitionBoothLoading } =
    useSelector((state) => state.exhibitionReducer);

  const [transferDateHours, setTransferDateHours] = useState(0);
  const [transferDateMinutes, setTransferDateMinutes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedBox, setSelectedBox] = useState({
    bankSelected: true,
    walletSelected: false,
  });

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    setTransferDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setTransferDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  useEffect(async () => {
    currentDate();

    if (roles.includes("trainee") && roles.includes("admin")) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("registration_not_allowed")}</span>);
      history.goBack();
    }

    await dispatch(getExhibitionById(id));
    setLoading(false);
  }, []);

  const handleSelectPaymentClick = (key) => {
    setSelectedBox({
      bankSelected: key === "bank",
      walletSelected: key === "wallet",
    });
  };

  return (
    <div className="container-fluid" style={{ marginTop: "2rem" }}>
      {payExhibitionVisiterLoading || payExhibitionBoothLoading || exhibitionLoading || loading ? (
        <SkeletonCardOverlay />
      ) : (
        <MainBox className="border-8">
          <div className={classes["payment-container"]}>
            <div className={classes["payment-back"]} onClick={() => history.goBack()}>
              <div className={classes["payment-back-icon"]}>
                <img src={ArrowIcon} alt="back" />
              </div>
              <div className={classes["payment-back-text"]}>{`${exhibition.name} ${
                type === "booth" ? ` - ${exhibition?.user_booths[exhibition?.user_booths?.length - 1]?.name}` : ""
              }`}</div>
            </div>
            <div className={classes["course_payment_notes_container"]}>
              {exhibition.taxes?.length > 0
                ? exhibition.taxes
                    .filter((tax) => tax.user_can_use_now && tax.type === type)
                    .map((tax) => (tax.note ? <div className={classes["course_payment_note"]}>{tax.notes}</div> : null))
                : null}
            </div>
            <div>
              <Formik
                initialValues={{
                  name: "",
                  phone_number: "",
                  phone_code: "",
                  bank: "",
                  iban: "",
                  payment_proof: "",
                  transfer_date: setHours(setMinutes(new Date(), transferDateMinutes), transferDateHours),
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const formData = new FormData();
                  if (type === "visitor") {
                    formData.append("exhibition_id", id);
                  } else {
                    formData.append("booth_id", exhibition?.user_booths[exhibition?.user_booths?.length - 1]?.id);
                  }

                  if (selectedBox.bankSelected) {
                    formData.append("payment_method", "Bank");
                    formData.append("name", values.name);
                    formData.append("mobile", values.phone_code + values.phone_number);
                    formData.append("bank", values.bank);
                    formData.append("iban", values.iban);
                    formData.append("payment_proof", values.payment_proof.file);

                    const transferDate = toServerTime(
                      formatDate(values.transfer_date) + " " + formatTime(values.transfer_date)
                    );
                    formData.append("transfer_date", transferDate.split(" ")[0]);
                  } else {
                    formData.append("payment_method", "Wallet");
                  }

                  await dispatch(
                    type === "visitor" ? payExhibitionVisiter(formData, history) : payExhibitionBooth(formData, history)
                  );

                  setSubmitting(false);
                }}
                validate={(values) => {
                  const errors = {};

                  if (selectedBox.bankSelected) {
                    if (!values.name) {
                      errors.name = t("crud.errors.required");
                    }
                    if (!values.phone_number || !values.phone_code) {
                      errors.mobile = t("crud.errors.required");
                    } else if (values.phone_number.length <= 7) {
                      errors.mobile = t("payments.invalid_mobile");
                    } else if (values.phone_number.length >= 15) {
                      errors.mobile = t("payments.invalid_mobile");
                    }
                    if (!values.bank) {
                      errors.bank = t("crud.errors.required");
                    }
                    if (!values.iban) {
                      errors.iban = t("crud.errors.required");
                    } else if (values.iban.length < 20) {
                      errors.iban = t("payments.invalid_iban");
                    }
                    if (!values.payment_proof) {
                      errors.payment_proof = t("crud.errors.required");
                    }
                  }

                  return errors;
                }}
                validateOnChange={true}
              >
                {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                  <form className={classes["course_payment_container"]}>
                    <div className={classes["course_payment_form_container"]}>
                      <ExhibitionPaymentForm
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        selectedBox={selectedBox}
                        handleSelectPaymentClick={handleSelectPaymentClick}
                        values={values}
                        errors={errors}
                        setTransferDateHours={setTransferDateHours}
                        setTransferDateMinutes={setTransferDateMinutes}
                        currentDate={currentDate}
                        dashboard={exhibitionDashboard}
                      />
                    </div>
                    <div className={classes["course_payment_form_separator"]} />
                    <div className={classes["course_payment_details_container"]}>
                      <ExhibitionPaymentDetails
                        exhibitionDetails={exhibition}
                        handleSubmit={handleSubmit}
                        type={type}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </MainBox>
      )}
    </div>
  );
}
