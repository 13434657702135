import { walletConstants } from "../../Constants/wallet/wallet.constants";

const initialState = {
  userWalletTransactions: [],
  userWalletMeta: {},
  userWalletDashboard: {},
  userWallet: {},
  userWalletFilter: {},

  walletTransactions: [],
  walletMeta: {},
  walletDashboard: {},
  wallet: {},
  walletFilter: {},
  isLoading: false,
  walletCredit: {},
  walletTransaction: {},
  walletIban: "",
};

const userWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case walletConstants.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case walletConstants.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case walletConstants.GET_USER_WALLET_BY_ID:
      return {
        ...state,
        isLoading: false,
        userWalletTransactions: action.payload.transactions,
        userWalletMeta: action.payload.meta,
        userWalletDashboard: Array.isArray(action.payload.dashboard) ? state.userWalletDashboard : action.payload.dashboard,
        userWalletFilter: Array.isArray(action.payload.filter) ? state.userWalletFilter : action.payload.filter,
        userWallet: action.payload.user,
        walletIban: action.payload.user.iban,
      };
    case walletConstants.GET_USER_WALLET:
      return {
        ...state,
        isLoading: false,
        wallet: action.payload.user,
        walletDashboard: Array.isArray(action.payload.dashboard) ? state.walletDashboard : action.payload.dashboard,
        walletMeta: action.payload.meta,
        walletTransactions: action.payload.transactions,
        walletFilter: Array.isArray(action.payload.filter) ? state.walletFilter : action.payload.filter,
        walletIban: action.payload.user.iban,
      };

    case walletConstants.POST_PAY:
      return {
        ...state,
        walletCredit: action.payload,
      };

    case walletConstants.POST_TRANSACTION:
      return {
        ...state,
        walletTransaction: action.payload,
        isLoading: false,
      };

    case walletConstants.POST_IBAN:
      return {
        ...state,
        walletIban: action.payload.iban,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default userWalletReducer;
