import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { addTax } from "../../../../../Services/api/Financial/FinancialProvider";
import ManageTax from "../edit/ManageTax";

function Add() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const submitHandler = async (values) => {
    try {
      let response = await addTax(values);
      if (response.status === 201 && response.data.status) {
        history.push(`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/tax`);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : t("coupon.messages.update_success")}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error?.data?.msg ? error?.data?.msg : "Failure in service"}
          {error?.data?.errors
            ? Object.entries(error?.data?.errors).map(([key, value]) => {
                return (
                  <span key={key} style={{ fontSize: 12, fontWeight: "normal" }}>
                    <br />
                    {key}: {value}
                  </span>
                );
              })
            : null}
        </span>
      );
    }
  };

  return <ManageTax type="add" submitHandler={submitHandler} />;
}

export default Add;
