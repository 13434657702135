import {LessonProvider} from "../../../../../Services/api/courses/LessonContext";
import ContentLayout from "../ContentLayout";
import ButtonsLayout from "../ButtonsLayout/ButtonsLayout";
import AddTextContentModal from "../AddTextContentModal/AddTextContentModal";
import {NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function LessonPage(props) {
  require("./lessonPage.css");
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      <div className="container-fluid min-hight-78">
        <div className="training_first_label mt-4">
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/training-courses">{t("Hometraining")}</NavLink>

            {
                location.state?
                    <>
                    <p style={{ padding: "0 5px" }}>/</p>
                        <NavLink to={`/trainer/course/${location.state.id}`}>
                        {t("Courseoutline")}
                        </NavLink>
                        <p style={{ padding: "0 5px" }}>/</p>
                        {location.state.title}
                    </>
                :
                null
            }
        </div>
        <LessonProvider>
          <div
            // style={{
            //     width:"100%",
            //     display:"flex",
            //     flexDirection:"row",
            //     justifyContent:"space-between",
            //     marginTop:35
            // }}
            className="content-flex"
            style={{ marginTop: 0 }}
          >
            <div
              style={{
                flex: 8,
                width: "100%",
              }}
            >
              <ContentLayout id={props.id} />
            </div>

            <div
              style={{
                flex: 2,
              }}
            >
              <ButtonsLayout />
            </div>
            <AddTextContentModal />
          </div>
        </LessonProvider>
      </div>
    </>
  );
}

export default LessonPage;
