import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { activityCommentListConstants } from "../../Constants/activityComment/activityComment.constant";
import i18n from "../../../i18n/i18n";

// GET activity LIST
export const fetchActivityCommentsList = ({
  id,
  page = 1,
  perPage = 10,
  filter,
  order = null,
}) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_REQUEST,
    });

    // params: {
    //   page,
    //   perPage,
    //   ...filter,
    // },
    await connector
      .get(URLBuilder(env.lesson_interactive_content, { id: id }), {
        params: {
          order,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_SUCCESS,
            payload: {
              activityComments: response?.data?.content,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};
// GET Chapterr LIST
export const fetchChapterCommentsList = ({
  id,
  courseId,
  page = 1,
  perPage = 10,
  filter,
  order = null,
}) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_REQUEST,
    });

    // params: {
    //   page,
    //   perPage,
    //   ...filter,
    // },
    await connector
      .get(
        URLBuilder(
          id ? env.chapter_interactive_content : env.course_interactive_content,
          { id: id ? id : courseId }
        ),
        {
          params: {
            order,
          },
        }
      )
      .then((response) => {
        if (response?.status == 200) {
          dispatch({
            type: activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_SUCCESS,
            payload: {
              chapterComments: response?.data?.data?.data[0],
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// DELETE comment
export const deleteActivityComment = (id, isReplay, parent_id) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.DELETE_ACTIVITYCOMMENT_REQUEST,
    });
    await connector
      .delete(URLBuilder(env.lesson_delete_comment, { id: id }))
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: activityCommentListConstants.DELETE_ACTIVITYCOMMENT_SUCCESS,
            payload: { id, isReplay, parent_id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.DELETE_ACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: activityCommentListConstants.DELETE_ACTIVITYCOMMENT_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

// is published
export const updateIsPublishedActivityComment = (id, isReplay, parent_id) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.lesson_update_is_published_comment, {
          id: id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_SUCCESS,
            payload: {
              id,
              isReplay,
              parent_id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// is bannned
export const updateIsBannedActivityComment = (id, isReplay, parent_id) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.lesson_update_is_banned_comment, {
          id: id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_SUCCESS,
            payload: {
              id,
              isReplay,
              parent_id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// DELETE comment CHAPTER
export const deleteChapterComment = (id, isReplay, parent_id, callback) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.DELETE_CHAPTERCOMMENT_REQUEST,
    });
    await connector
      .delete(URLBuilder(env.lesson_delete_comment, { id: id }))
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: activityCommentListConstants.DELETE_CHAPTERCOMMENT_SUCCESS,
            payload: { id, isReplay, parent_id },
          });

          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
          if (callback) callback();
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.DELETE_CHAPTERCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: activityCommentListConstants.DELETE_CHAPTERCOMMENT_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

// is published CHAPTER
export const updateIsPublishedChapterComment = (
  id,
  isReplay,
  parent_id,
  callback
) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.lesson_update_is_published_comment, {
          id: id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_SUCCESS,
            payload: {
              id,
              isReplay,
              parent_id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
          if (callback) callback();
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// is bannned CHAPTER
export const updateIsBannedChapterComment = (
  id,
  isReplay,
  parent_id,
  callback
) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.lesson_update_is_banned_comment, {
          id: id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_SUCCESS,
            payload: {
              id,
              isReplay,
              parent_id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
          if (callback) callback();
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// ADD COMMENT
export const addActivityComment = (payload, callback) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.ADD_ACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(env.lesson_add_comment, payload)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: activityCommentListConstants.ADD_ACTIVITYCOMMENT_SUCCESS,
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
          if (callback) {
            callback();
          }
          // callback();
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.ADD_ACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <>
            {err?.response?.data?.errors ? (
              Object.keys(err?.response?.data?.errors)?.map((key) => {
                return (
                  <span style={{ fontSize: 12 }}>
                    {err?.response?.data?.errors[key]}{" "}
                  </span>
                );
              })
            ) : (
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {err?.response?.data?.msg}
              </span>
            )}
          </>
        );
        dispatch({
          type: activityCommentListConstants.ADD_ACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// LIKE ACTIVITY
export const likeActivityComment = (id) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.LIKE_ACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(URLBuilder(env.lesson_like_comment, { id: id }))
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.LIKE_ACTIVITYCOMMENT_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.LIKE_ACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.LIKE_ACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// UNLIKE ACTIVITY
export const unlikeActivityComment = (id) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(URLBuilder(env.lesson_unlike_comment, { id: id }))
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// LIKE CHAPTER
export const likeChapterComment = (id, callback) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.LIKE_CHAPTERCOMMENT_REQUEST,
    });

    await connector
      .post(URLBuilder(env.lesson_like_comment, { id: id }))
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.LIKE_CHAPTERCOMMENT_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
          if (callback) callback();
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.LIKE_CHAPTERCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.LIKE_CHAPTERCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// UNLIKE CHAPTER
export const unlikeChapterComment = (id, callback) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_REQUEST,
    });

    await connector
      .post(URLBuilder(env.lesson_unlike_comment, { id: id }))
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
          if (callback) callback();
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};

// PUBLISH ALL

export const PublishAllActivityComment = (id, isChapter) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.lesson_publish_all_comment, {
          id: id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_SUCCESS,
            payload: {
              id,
              isChapter,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
// BAN ALL

export const BanAllActivityComment = (id, isChapter) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.lesson_ban_all_comment, {
          id: id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_SUCCESS,
            payload: {
              id,
              isChapter,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_REJECTED,
          payload: err,
        });
      });
  };
};
export const EnableActivity = (id, isChapter) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.ACTIVATE_ACTIVITY_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.enable_activity, {
          id: id,
        }),
        { _method: "put" }
      )
      .then((response) => {
        if (response?.status === 200 && response?.data) {
          dispatch({
            type: activityCommentListConstants.ACTIVATE_ACTIVITY_SUCCESS,
            payload: {
              isChapter,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language === "en"
                ? "Enabled Successfully"
                : "تم التفعيل بنجاح"}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.ACTIVATE_ACTIVITY_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.ACTIVATE_ACTIVITY_REJECTED,
          payload: err,
        });
      });
  };
};
export const disableActivity = (id, isChapter) => {
  return async (dispatch) => {
    dispatch({
      type: activityCommentListConstants.DEACTIVATE_ACTIVITY_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.disable_activity, {
          id: id,
        }),
        { _method: "put" }
      )
      .then((response) => {
        if (response?.status === 200 && response?.data) {
          dispatch({
            type: activityCommentListConstants.DEACTIVATE_ACTIVITY_SUCCESS,
            payload: {
              isChapter,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language === "en"
                ? "Disabled Successfully"
                : "تم التعطيل بنجاح"}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: activityCommentListConstants.DEACTIVATE_ACTIVITY_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: activityCommentListConstants.DEACTIVATE_ACTIVITY_REJECTED,
          payload: err,
        });
      });
  };
};
