import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../../utils";
import moment from "moment";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
// import { fetchDepartmentList } from "../../../Redux/Actions/departments/departmentList.action";
import { exportCoursesList } from "../../../../Services/api/CoursesList";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import {
  programsPartnerList,
  coursesPartnerList,
} from "../../../../Services/api/courses/courseProvider";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

// import CoursesListTable from "./CoursesListTable";
import { filterActions } from "./PartnerProgramCoursesAction";
import classes from "./PartnerProgramCourses.module.css";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";

const PartnerPogramCourses = ({ isProgram }) => {
  const { t, i18n } = useTranslation();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { id } = useParams();
  const [filterData, setFilterData] = useState({
    name: "",
    numberOfBooths: "",
    boothName: "",
    rating: "",
    sponsorName: "",
    startDate: "",
    endDate: "",
    status: "",
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.numberOfBooths && (filter.booths_no = filterData.numberOfBooths);
    filterData.startDate &&
      (filter.start_time = moment(filterData.startDate).format("YYYY-MM-DD"));
    filterData.endDate &&
      (filter.end_time = moment(filterData.endDate).format("YYYY-MM-DD"));
    filterData.status && (filter.status = filterData.status);
    filterData.rating && (filter.rating = filterData.rating);

    return filter;
  };
  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      numberOfBooths: "",
      boothName: "",
      rating: "",
      sponsorName: "",
      startDate: "",
      endDate: "",
      status: "",
    });
  };
  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `partnerTebx`,
      page: t("sidebar.label.partners_tebx"),
      pagePath: "/admin/partners-tebx",
    },
    {
      id: `${isProgram ? "programs" : "courses"}`,
      page: `${isProgram ? t("program") : t("sidebar.label.courses")}`,
      pagePath: "#",
      active: true,
    },
  ];

  const getCorsesProgramsList = async () => {
    setIsLoading(true);
    const filter = getFilterData();

    try {
      let res = isProgram
        ? await programsPartnerList({
            perPage: 10,
            page: currentPage,
            filter,
            id,
          })
        : await coursesPartnerList({
            perPage: 10,
            page: currentPage,
            filter,
            id,
          });
      if (res.status == 200) {
        if (isProgram) {
          setCoursesList(res.data.programs.data);
          setTotalPages(res.data.programs.meta);
        } else {
          setCoursesList(res.data.courses.data);
          setTotalPages(res.data.courses.meta);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  // const getDepartmentsListHandler = async () => {
  //   dispatch(
  //     fetchDepartmentList({
  //       filter: {},
  //     })
  //   );
  // };
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    getCorsesProgramsList();
    // if (!departmentList?.length) {
    //   // getDepartmentsListHandler();
  }, []);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportCoursesList({}, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const displayMode = (mode) => {
    if (mode === "Free") {
      return t("general.free");
    } else if (mode === "Paid") {
      return t("general.paid");
    }
  };

  const displayStatus = (status) => {
    if (status === "Draft") {
      return t("general.draft");
    } else if (status === "Published") {
      return t("general.published");
    } else if (status === "Closed") {
      return t("general.closed");
    } else if (status === "Archived") {
      return t("general.archived");
    }
  };

  const displayLanguage = (language) => {
    if (language === "ar") {
      return t("arabic");
    } else if (language === "en") {
      return t("english");
    }
  };

  const displayLevel = (level) => {
    if (level === "1") {
      return t("levels.beginner");
    } else if (level === "2") {
      return t("levels.medium");
    } else if (level === "3") {
      return t("levels.advanced");
    }
  };

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "name",
      name: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["courses-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
      sortable: false,
      align: "start",
    },
    {
      id: "code",
      name: t("general.code"),
      key: "code",
      cell: ({ rowData }) => <>{rowData?.code || "-"}</>,
      sortable: false,
    },
    {
      id: "serial_number",
      name: t("general.serial_number"),
      key: "serial_number",
      cell: ({ rowData }) => <>{rowData?.run_serial_number || "-"}</>,
      sortable: false,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData.status === "Published"
                ? "#46BB3E"
                : rowData.status === "Draft"
                ? "#8599B6"
                : rowData.status === "Archived"
                ? "#8599B6"
                : rowData.status === "Closed"
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData.status === "Published"
                ? "#C1E2C822"
                : rowData.status === "Draft"
                ? "#C1CEE222"
                : rowData.status === "Archived"
                ? "#C1CEE222"
                : rowData.status === "Closed"
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayStatus(rowData?.status) || "-"}
        </span>
      ),
      sortable: false,
    },
    {
      id: "mode",
      name: t("general.mode"),
      key: "mode",
      cell: ({ rowData }) => (
        <span
          style={{
            color: rowData?.mode === "Free" ? "#E62626" : "#12C689",
          }}
        >
          {displayMode(rowData?.mode) || "-"}
        </span>
      ),
      sortable: false,
    },
    {
      id: "price",
      name: t("general.price"),
      key: "price",
      cell: ({ rowData }) => (
        <>
          {rowData?.mode === "Paid"
            ? `${rowData?.price} ${t("general.rs")}`
            : null || "-"}
        </>
      ),
      sortable: false,
    },
    {
      id: "number_of_users",
      name: t("courses_list.number_of_users"),
      key: "number_of_users",
      cell: ({ rowData }) => <>{rowData?.number_of_users} </>,
      sortable: false,
    },
    {
      id: "participators",
      name: t("courses_list.participators"),
      key: "participators",
      cell: ({ rowData }) => <>{rowData?.participators || "-"} </>,
      sortable: false,
    },
    // {
    //   id: "creator_name",
    //   name: t("courses_list.creator_name"),
    //   key: "creator_name",
    //   cell: ({ rowData }) => (
    //     <>
    //       {i18n.language === Lang.AR
    //         ? rowData?.creator?.name_ar
    //         : rowData?.creator?.name_en || "-"}
    //     </>
    //   ),
    //   sortable: false,
    // },
    // {
    //   id: "trainer_manager",
    //   name: t("courses_list.trainer_manager"),
    //   key: "trainer_manager",
    //   cell: ({ rowData }) => <>{rowData?.trainer?.name || "-"} </>,
    //   sortable: false,
    // },
    {
      id: "specialty",
      name: t("courses_list.specialty"),
      key: "specialty",
      cell: ({ rowData }) => (
        <>
          {i18n.language === Lang.AR
            ? rowData?.category?.name_ar || "-"
            : rowData?.category?.name_en || "-"}{" "}
        </>
      ),
      sortable: false,
    },
    {
      id: "department",
      name: t("courses_list.department"),
      key: "department",
      cell: ({ rowData }) => (
        <>
          {i18n.language === Lang.AR
            ? rowData?.department_name_ar
            : rowData?.department_name_en || "-"}{" "}
        </>
      ),
      sortable: false,
    },
    {
      id: "language",
      name: t("general.language"),
      key: "language",
      cell: ({ rowData }) => (
        <span
          style={{
            color: rowData?.language === "ar" ? "#E62626" : "#12C689",
          }}
        >
          {displayLanguage(rowData?.language) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "level",
      name: t("general.level"),
      key: "level",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData.level === "1"
                ? "#46BB3E"
                : rowData.level === "2"
                ? "#8599B6"
                : rowData.level === "3"
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData.level === "1"
                ? "#C1E2C822"
                : rowData.level === "2"
                ? "#C1CEE222"
                : rowData.level === "3"
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayLevel(rowData?.level) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "number_hours",
      name: t("courses_list.number_hours"),
      key: "number_hours",
      cell: ({ rowData }) => <>{rowData?.number_hours || "-"} </>,
      sortable: false,
    },
    {
      id: "rating",
      name: t("general.rating"),
      key: "general",
      cell: ({ rowData }) => (
        <StarsRating rating={rowData.ratings_avg_stars || 0} />
      ),
      sortable: false,
    },
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.start_register_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.start_register_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.end_register_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.end_register_date)}
        </>
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",
      cell: ({ rowData }) =>
        rowData?.status == 1
          ? t("system_users.active")
          : t("system_users.inactive"),
      sortable: false,
    },
  ];

  const coursesListCategories = [];
  const coursesListModes = [];
  const coursesListTrainers = [];
  const departmentList = [];
  const coursesListStatuses = [];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t(`courses_list.${isProgram ? "program_name" : "course_name"}`),
      type: "text",
      placeholder: t("courses_list.course_name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    // {
    //   id: "description",
    //   component: "input",
    //   label: t("courses_list.description"),
    //   type: "text",
    //   placeholder: t("courses_list.description"),
    //   name: "description",
    //   value: filterData.description,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   onReset: resetHandler,
    // },
    {
      id: "owner",
      label: t(
        `courses_list.${isProgram ? "trainer_manager" : "trainer_name"}`
      ),
      component: "select",
      placeholder: t("courses_list.trainer_name"),
      initialValue: t("courses_list.trainer_name"),
      name: "owner",
      value: filterData.owner,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: coursesListTrainers?.length
        ? coursesListTrainers?.map((trainer) => ({
            id: trainer.id,
            value: trainer.id,
            label: trainer.name,
          }))
        : [],
    },
    {
      id: "mode",
      label: t("general.type"),
      component: "select",
      placeholder: t("general.type"),
      initialValue: t("general.type"),
      name: "mode",
      value: filterData.mode,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: coursesListModes?.length
        ? coursesListModes?.map((trainer) => ({
            id: trainer.value,
            value: trainer.value,
            label: trainer.text,
          }))
        : [],
    },
    {
      id: "cost",
      component: "input",
      label: t("general.cost"),
      type: "text",
      placeholder: t("general.cost"),
      name: "cost",
      value: filterData.cost,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "specialty",
      label: t("courses_list.specialty"),
      component: "select",
      placeholder: t("courses_list.specialty"),
      initialValue: t("courses_list.specialty"),
      name: "specialty",
      value: filterData.specialty,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: coursesListCategories?.length
        ? coursesListCategories?.map((trainer) => ({
            id: trainer.id,
            value: trainer.id,
            label:
              i18n.language === Lang.AR ? trainer.name_ar : trainer.name_en,
          }))
        : [],
    },
    {
      id: "department",
      label: t("courses_list.department"),
      component: "select",
      placeholder: t("courses_list.department"),
      initialValue: t("courses_list.department"),
      name: "department",
      value: filterData.department,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: departmentList?.length
        ? departmentList?.map((trainer) => ({
            id: trainer.id,
            value: trainer.id,
            label:
              i18n.language === Lang.AR ? trainer.name_ar : trainer.name_en,
          }))
        : [],
    },
    {
      id: "start_date",
      component: "input",
      label: t("general.start_date"),
      type: "date",
      placeholder: t("general.start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "end_date",
      component: "input",
      label: t("general.end_date"),
      type: "date",
      placeholder: t("general.end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: coursesListStatuses?.length
        ? coursesListStatuses?.map((trainer) => ({
            id: trainer.value,
            value: trainer.value,
            label: trainer.text,
          }))
        : [],
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          getCorsesProgramsList();
        }
      },
    },
  ];

  return (
    <div className={classes["courses-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["courses-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={isProgram ? t("program") : t("sidebar.label.courses")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && <FilterBox fields={filterFields} />}
                <MainTable
                  columns={columns}
                  data={coursesList}
                  isLoading={isLoading}
                />

                <Pagination
                  count={totalPages.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerPogramCourses;
