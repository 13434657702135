import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { LessonContentContext } from "../../../../../Providers/LessonContentProvider";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import FileBrowser from "./Browser/Browser";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import HomeIcon from "../../../../../assets/icons/home.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "../Canvas/Canvas.module.css";

function LessonContent(props) {
  require("./Content.css");
  const lessonContentContext = useContext(LessonContentContext);
  const location = useLocation();
  const { courseId } = useParams();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    lessonContentContext
      .fetchLessonContent(id)
      .then(() => {})
      .catch((err) => {});
  }, []);
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "course",
      page: t("Courseoutline"),
      pagePath: `${props.isAdmin ? "/admin" : "/trainer"}/course/${
        courseId || location?.state?.courseID || props.courseID
      }`,
    },
    {
      id: "lesson",
      page: lessonContentContext.lessonInfo.title,
      active: true,
    },
  ];

  const handleCloseModal = () => lessonContentContext.setIsModalOpen(false);

  return (
    <>
      <div
        className={
          classes["course-details-container"] + " container-fluid mt-4"
        }
      >
        <div className="row">
          <div className="col-12">
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <MainBox>
              <MainBoxHead
                title={lessonContentContext.lessonInfo.title || props.title}
              />
              <div
                className={
                  "tajah-lesson-content-browser" +
                  (lessonContentContext.initialLoading ? " is_loading" : "")
                }
              >
                {lessonContentContext.initialLoading ? (
                  <SkeletonCardOverlay />
                ) : (
                  <FileBrowser
                    content={lessonContentContext.content}
                    activities={lessonContentContext.activitiesTab}
                    role={props.role}
                    isTrainer={props.isTrainer}
                    isAdmin={props.isAdmin}
                    isManager={props.isManager}
                    isPartner={props.isPartner}
                  />
                )}
              </div>
            </MainBox>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={lessonContentContext.isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={lessonContentContext.isModalOpen}>
          <Box>{lessonContentContext.targetModalContent}</Box>
        </Fade>
      </Modal>
    </>
  );
}

export default LessonContent;
