import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {Formik} from "formik";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {toast} from "react-toastify";
import {refundSettingList, refundSettingUpdate,} from "../../../../Services/api/Financial/FinancialProvider";

function RefundSetting(props) {
  require("./RefundSetting.css");
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    refundSettingList()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
        //   setData(res.data.response);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("failed_fetching")}
          </span>
        );
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="admin_flex" id="content-header-bar">
          <div className="admin_label">
            <NavLink
              to={`${
                localStorage.getItem("type") == "accountant"
                  ? "/accountant"
                  : "/admin"
              }`}
            >
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("financial.label.refundSetting")}
          </div>
        </div>
        {isLoading == false ? (
          <>
            <div className="table_bank_tran">
              <div
                className="tajah_card_title_container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Formik
                  initialValues={{
                    minimum_charge:
                      data.minimum_charge == null ? 0 : data.minimum_charge,
                    grace_period:
                      data.grace_period == null ? 0 : data.grace_period,
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      let response = await refundSettingUpdate(values);
                      if (response.status === 200 && response.data.status) {
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {response.data.msg
                              ? response.data.msg
                              : t("financial.label.successSetting")}
                          </span>
                        );
                      } else {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {response.data.msg
                              ? response.data.msg
                              : "Failure in service"}
                          </span>
                        );
                        setSubmitting(false);
                      }
                    } catch (e) {
                      setSubmitting(false);
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {t("failed_fetching")}
                        </span>
                      );
                    }
                  }}
                  enableReinitialize
                  validateOnChange={hasSubmitted}
                  validate={(values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    if (!values.minimum_charge) {
                      errors.minimum_charge = t("crud.errors.required");
                    }

                    if (!values.grace_period) {
                      errors.grace_period = t("crud.errors.required");
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "90%",
                          height: 40,
                        }}
                      >
                        <h5>{t("financial.label.refundSetting")}</h5>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "90%",
                          marginTop: 30,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("financial.label.minimum_charge")}</label>
                          <input
                            id="minimum_charge"
                            type="number"
                            min="0"
                            step="0.1"
                            name="minimum_charge"
                            className="admin_filter_input"
                            onChange={(e) => {
                              setFieldValue("minimum_charge", e.target.value);
                            }}
                            placeholder={t("financial.label.minimum_charge")}
                            value={values.minimum_charge}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.minimum_charge
                              ? errors.minimum_charge
                              : null}
                          </p>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("financial.label.grace_period")}</label>
                          <input
                            id="grace_period"
                            type="number"
                            name="grace_period"
                            min="0"
                            className="admin_filter_input"
                            onChange={(e) => {
                              setFieldValue("grace_period", e.target.value);
                            }}
                            placeholder={t("financial.label.grace_period")}
                            value={values.grace_period}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.grace_period ? errors.grace_period : null}
                          </p>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="admin_add_button"
                        disabled={isSubmitting}
                      >
                        {t("crud.placeholders.save")}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "45vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default RefundSetting;
