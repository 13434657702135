import React, { useEffect, useRef } from "react";
import classes from "./CarosalHomePage.module.css";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

export default function CaroselSlide({ ad, adListLoading, key }) {
  const ref = useRef(null);

  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // console.log(ad.id, isInView);
    if (isInView && adListLoading) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView, adListLoading]);
  return (
    <div
      className={classes["main"]}
      style={{ backgroundImage: `url(${ad?.image})` }}
    >
      <div className={classes.backdropDiv}>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 120 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.6, delay: 0.3 }}
          className={classes["main__wrapper"]}
        >
          {ad?.show_content !== "0" && (
            <div className={classes["main__content"]}>
              <h2 className={classes["main__content-title"]}>{ad?.title}</h2>
              <p
                className={classes["main__content-desc"]}
                style={
                  ad?.description?.length > 710 ? { fontSize: "14px" } : {}
                }
              >
                {ad?.description}
              </p>
            </div>
          )}
          {ad?.has_button && (
            <div className={classes["search_container"]}>
              <CustomButton
                value={ad?.button_text}
                colors={"#fff"}
                classes={classes.btnRegister}
                action={() => window.open(ad?.url, "_blank")}
              />
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
}
