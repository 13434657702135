import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainerById,
  resetTrainer,
} from "../../Redux/Actions/trainerProfile/trainerProfile.action";
import { getSpecialties } from "../../Services/api/profile/profileProvider";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import TrainersHeader from "./TrainersHeader/TrainersHeader";
import classes from "./trainers.module.css";
import TrainersCard from "./trainersCard/TrainerCard";

function Trainers() {
  require("./trainers.css");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { get_trainer } = useSelector((state) => state.trainerProfileReducer);

  const [trainers, setTrainers] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [meta, setMeta] = useState([]);
  const [loadMoreButton, setLoadMoreButton] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    setSelectedDepartment(null);
    setSelectedClassification(e.target.value);
    setDepartments(
      specialties.filter(
        (specialty) => specialty.id === Number(e.target.value)
      )[0].departments
    );
  };

  const handleDepChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  useEffect(() => {
    dispatch(resetTrainer());
    setLoading(true);
    getSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSpecialties([
            {
              departments: [],
              description_ar: t("AllQualifiers"),
              description_en: t("AllQualifiers"),
              id: 0,
              name_ar: t("AllQualifiers"),
              name_en: t("AllQualifiers"),
            },
            ...res.data.data.specialties.data,
          ]);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  useEffect(() => {
    if (get_trainer.status === 200) {
      if (loadMoreButton) {
        setTrainers((trainer) => [
          ...trainer,
          ...get_trainer.data.response.data,
        ]);
      } else {
        setTrainers(get_trainer.data.response.data);
      }
      setMeta(get_trainer.data.response.meta);
      setLoadMoreButton(false);
      setLoading(false);
    }
  }, [get_trainer]);

  const LoadMore = () => {
    setLoadMoreButton(true);
    let params;
    if (selectedClassification == 0) {
      params = {
        perPage: 10,
        page: meta.currentPage + 1,
      };
    } else {
      params = {
        perPage: 10,
        page: meta.currentPage + 1,
        ...(selectedClassification && {
          specialty_id: selectedClassification,
        }),
        ...(selectedDepartment && { department_id: selectedDepartment }),
      };
    }

    dispatch(getTrainerById(params));
  };

  useEffect(() => {
    let params;
    if (selectedClassification == 0) {
      params = {
        perPage: 10,
      };
    } else {
      params = {
        perPage: 10,
        ...(selectedClassification && {
          specialty_id: selectedClassification,
        }),
        ...(selectedDepartment && { department_id: selectedDepartment }),
      };
    }
    if (selectedClassification == 0) {
      dispatch(getTrainerById(params));
      setLoadMoreButton(false);
    } else {
      dispatch(getTrainerById(params));
      setLoadMoreButton(false);
    }
  }, [selectedClassification, selectedDepartment]);

  return (
    <>
      {!loading ? (
        <div className="container-fluid">
          <div className="row">
            <div className={`col-12 ${classes.my25} ${classes.flexheader}`}>
              <TrainersHeader
                specialties={specialties}
                handleChange={handleChange}
                selectedClassification={selectedClassification}
                departments={departments}
                selectedDepartment={selectedDepartment}
                handleDepChange={handleDepChange}
              />
            </div>
            {!loading && (
              <div className="col-12">
                <TrainersCard trainers={trainers} loading={loading} />
                {meta.nextPage && (
                  <CustomButton
                    value={t("Exhibition.load_more")}
                    colors={"#26b3b9"}
                    type={"button"}
                    variant={"outlined"}
                    disable={loadMoreButton}
                    loading={loadMoreButton}
                    classes={classes.btn}
                    action={LoadMore}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}

export default Trainers;
