export const IMAGE_PNG = "image/png";
export const IMAGE_JPG = "image/jpg";
export const IMAGE_JPEG = "image/jpeg";
export const IMAGE_GIF = "image/gif";
export const AUDIO_MP3 = "audio/mp3";
export const AUDIO_MPEG = "audio/mpeg";
export const VIDEO_MP4 = "video/mp4";
export const DOCUMENT_PDF = "application/pdf";
export const DOCUMENT_DOC = "application/doc";
export const DOCUMENT_DOCX = "application/docx";

export const DOCUMENT_MS_WORD = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
];

export const DOCUMENT_MS_EXCEL = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
];

export const DOCUMENT_MS_POWERPOINT = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
];

export const IMAGE_SUPPORTED_FORMATS = [IMAGE_PNG];
export const AUDIO_SUPPORTED_FORMATS = [AUDIO_MP3, AUDIO_MPEG];
export const VIDEO_SUPPORTED_FORMATS = [VIDEO_MP4];
export const DOCUMENT_SUPPORTED_FORMATS = [DOCUMENT_PDF, DOCUMENT_DOC];

export const acceptImages = (supportedTypes = IMAGE_SUPPORTED_FORMATS) =>
  supportedTypes.join(",");

export const acceptAudios = (supportedTypes = AUDIO_SUPPORTED_FORMATS) =>
  supportedTypes.join(",");

export const acceptVideos = (supportedTypes = VIDEO_SUPPORTED_FORMATS) =>
  supportedTypes.join(",");

export const acceptDocuments = (supportedTypes = DOCUMENT_SUPPORTED_FORMATS) =>
  supportedTypes.join(",");

export const acceptExcel = (supportedTypes = DOCUMENT_MS_EXCEL) =>
  supportedTypes.join(",");

export const MAXIMUM_IMAGE_SIZE = 5 * 1024 * 1024;
export const MAXIMUM_AUDIO_SIZE = 5 * 1024 * 1024;
export const MAXIMUM_DOCUMENT_SIZE = 5 * 1024 * 1024;

export const validateImage = (
  file,
  maximumSize,
  supportedTypes = IMAGE_SUPPORTED_FORMATS
) => {
  // if (type) {
  //   validate(file, maximumSize, supportedTypes)
  // }
  return validate(file, maximumSize, supportedTypes);
};

export const validateArrayOfImage = (
  files,
  maximumSize,
  type,
  supportedTypes = IMAGE_SUPPORTED_FORMATS
) => {
  if (type) {
    for (let i = 0; i < files.length; i++) {
      if (!validate(files[i], maximumSize, supportedTypes)) {
        return false;
      }
    }
  } else
    for (let i = 0; i < files.length; i++) {
      if (!validate(files[i], maximumSize, supportedTypes)) {
        return false;
      }
    }

  return true;
};

export const validateAudio = (
  file,
  maximumSize,
  supportedTypes = AUDIO_SUPPORTED_FORMATS
) => validate(file, maximumSize, supportedTypes);

export const validateVideo = (
  file,
  maximumSize,
  supportedTypes = VIDEO_SUPPORTED_FORMATS
) => validate(file, maximumSize, supportedTypes);

export const validateDocument = (
  file,
  maximumSize,
  supportedTypes = DOCUMENT_SUPPORTED_FORMATS
) => validate(file, maximumSize, supportedTypes);

export const validateArrayOfDocument = (
  files,
  maximumSize,
  supportedTypes = DOCUMENT_SUPPORTED_FORMATS
) => {
  for (let i = 0; i < files.length; i++) {
    if (!validate(files[i], maximumSize, supportedTypes)) {
      return false;
    }
  }

  return true;
};

export const validateExcel = (
  file,
  maximumSize,
  supportedTypes = DOCUMENT_MS_EXCEL
) => validate(file, maximumSize, supportedTypes);

const validate = (file, maximumSize, supportedTypes) => {
  let sizeFile = file?.size * 0.001;

  if (
    file?.type &&
    sizeFile <= maximumSize &&
    supportedTypes.includes(file.type)
  ) {
    return true;
  } else {
    return false;
  }
};

export const PNG_TYPE = "png",
  MP3_TYPE = "mp3",
  DOC_PDF = "pdf",
  DOC_DOC = "doc",
  JPEG_TYPE = "jpeg",
  JPG_TYPE = "jpg",
  MP4_TYPE = "mp4",
  DOC_DOCX = "docx",
  DOC_XLSX = "xlsx";

export const fromKbToMb = (size) => {
  return (size * 0.0009765625).toFixed(4);
};
