import ActivitiesStatement from "../../Modules/Admin/ActivitesMarks/Statement";
import LearningAnalysis from "../../Modules/Admin/Analysis/LearningAnalysis";
import {
  AssessmentAdd,
  AssessmentAnswers,
  AssessmentEdit,
  AssessmentParticipants,
  AssessmentPreview,
  AssessmentStatistics,
  AssessmentsList,
  AssessmentsStatement,
} from "../../Modules/Admin/Assessments";
import CourseTraineeStatement from "../../Modules/Admin/Departments/DepartmentsTrainees/CourseTraineeStatement/CourseTraineeStatement";
import EvaluationList from "../../Modules/Admin/Evaluation/EvaluationList/EvaluationList";
import ExhibitionDetails from "../../Modules/Admin/Exhibition/ExhibitionDetails/ExhibitionDetails";
import Trainees from "../../Modules/Admin/survey/answers/Trainees";
import Statistics from "../../Modules/Admin/survey/statistics/Statistics";
import AddCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Add";
import EditCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Edit";
import CourseTeamsList from "../../Modules/Trainer/Course/CourseTeams/List";
import LessonCanvas from "../../Modules/Trainer/Course/NewLesson/Canvas/Canvas";
import DiscBoardParticipants from "../../Modules/Trainer/Course/discBoard/Participants/DiscBoardParticipants";
import DiscBoardStatement from "../../Modules/Trainer/Course/discBoard/Statement";
import Profile from "../../Modules/trainees/privateProfile/editProfile/profile/Profile";
import {
  AddSurvey,
  CoursesList,
  ListSurvey,
} from "../admins";
import {
  AddEduGoals,
  AddQuestionToQuestionBankWrapper,
  EditEduGoals,
  EduGoals,
  ListQuestionBank,
  SurveyAnswers,
  SurveyParticipants,
  SurveyResponse,
} from "../trainers";
import {
  AcrivitiesMark,
  AddCourse,
  AddPeymentRequest,
  CourseProfile,
  DiscBoard,
  EditCourse,
  PeymentsRequest,
  SurveyQuestions,
  SurveyView,
} from "./";

const routesTrainer = [
  { component: Profile, path: "/", exact: true },
  {
    component: () => <DiscBoard role="manager" />,
    path: "/course/:courseId/discussion-board/:id",
    exact: true,
  },
  {
    component: () => <DiscBoardParticipants role="manager" />,
    path: "/course/:courseId/discussion-board/:id/participants",
    exact: true,
  },
  {
    component: () => <DiscBoard role="manager" />,
    path: "/course/:courseId/discussion-board",
    exact: true,
  },
  {
    component: () => <AcrivitiesMark role="manager" />,
    path: "/course/lesson/:id/:activityId/activities-grades",
    exact: true,
  },
  {
    component: () => <DiscBoard role="manager" type="lesson" />,
    path: "/course/:courseId/activity/:id",
    exact: true,
  },
  {
    component: () => <ExhibitionDetails role={"manager"} />,
    path: "/course/:courseId/lesson/:lessonId/exhibition/:id",
    exact: true,
  },
  {
    component: () => (
      <LessonCanvas role={"manager"} isManager={true} isTrainer={true} />
    ),
    path: "/course/:courseId/lesson/:id",
    exact: true,
  },
  {
    component: () => <PeymentsRequest role="manager" />,
    path: "/payments-requests",
    exact: true,
  },
  {
    component: () => <AddPeymentRequest role="manager" />,
    path: "/payments-requests/add",
    exact: true,
  },
  {
    component: (props) => <LearningAnalysis {...props} role={"manager"} />,
    path: "/learning-analysis",
    exact: true,
  },

  {
    component: () => (
      <CoursesList
        role="manager"
        notClosed={false}
        status="Closed"
        notInProgram={true}
      />
    ),
    path: "/courses-history",
    exact: true,
  },
  {
    component: () => (
      <CoursesList role="manager" notClosed={true} notInProgram={true} />
    ),
    path: "/courses",
    exact: true,
  },
  {
    component: () => (
      <CoursesList
        isProgram={true}
        role="manager"
        status="Closed"
        notClosed={false}
      />
    ),
    path: "/programs-history",
    exact: true,
  },
  {
    component: () => (
      <CoursesList isProgram={true} role="manager" notClosed={true} />
    ),
    path: "/programs",
    exact: true,
  },
  {
    component: () => <AddCourse role="manager" />,
    path: "/course/add",
    exact: true,
  },
  {
    component: () => <CourseProfile role="manager" />,
    path: "/course/:id",
    exact: true,
  },
  {
    component: () => <CourseTeamsList isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/teams",
    exact: true,
  },
  {
    component: () => <AddCourseTeam isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/teams/add",
    exact: true,
  },
  {
    component: () => <EditCourseTeam isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/teams/:id/edit",
    exact: true,
  },
  {
    component: () => <EditCourse role="manager" />,
    path: "/course/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/survey/list",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyParticipants role="manager" />,
    path: "/survey/:id/participants",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse />,
    path: "/survey/:id/participants/:responseId",
    exact: true,
  },
  {
    component: (props) => <SurveyParticipants role="manager" />,
    path: "/course/:targetCourseId/survey/:id/participants",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse />,
    path: "/course/:targetCourseId/survey/:id/participants/:responseId",
    exact: true,
  },
  {
    component: Trainees,
    path: "/survey/trainee-trainer/answers",
    exact: true,
  },
  {
    component: () => (
      <AssessmentsList quizable={"chapter"} role={"manager"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsList quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => <ActivitiesStatement isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/activities",
    exact: true,
  },
  {
    component: () => <DiscBoardStatement role="manager" />,
    path: "/course/:courseId/discussions",
    exact: true,
  },
  {
    component: () => <AssessmentsStatement isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/assessments",
    exact: true,
  },
  {
    component: () => <EvaluationList quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <EvaluationList quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <EvaluationList role={"manager"} />,
    path: "/course/:courseId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <AssessmentAdd quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => <AssessmentAdd quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => (
      <AssessmentParticipants quizable={"chapter"} role={"manager"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => (
      <AssessmentParticipants quizable={"lesson"} role={"manager"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => (
      <AssessmentAnswers quizable={"chapter"} role={"manager"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentAnswers quizable={"lesson"} role={"manager"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentStatistics quizable={"chapter"} role={"manager"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => (
      <AssessmentStatistics quizable={"lesson"} role={"manager"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => (
      <AssessmentPreview quizable={"chapter"} role={"manager"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => (
      <AssessmentPreview quizable={"lesson"} role={"manager"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => <AssessmentEdit quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: () => <AssessmentEdit quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: (props) => <EduGoals role={"manager"} />,
    path: "/course/educational-goals/:id",
    exact: true,
  },
  {
    component: (props) => <AddEduGoals role={"manager"} />,
    path: "/course/educational-goals/add/:id",
    exact: true,
  },
  {
    component: (props) => <EditEduGoals role={"manager"} />,
    path: "/course/educational-goals/edit/:id",
    exact: true,
  },
  {
    component: (props) => (
      <AddQuestionToQuestionBankWrapper role={"manager"} />
    ),
    path: "/course/:courseId/question-bank/add",
    exact: true,
  },
  {
    component: () => (
      <AddQuestionToQuestionBankWrapper editMode={true} role={"manager"} />
    ),
    path: "/course/:courseId/question-bank/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListQuestionBank role={"manager"} />,
    path: "/course/:courseId/question-bank",
    exact: true,
  },
  {
    component: Statistics,
    path: "/survey/statistics/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyQuestions {...props} role="manager" />,
    path: "/survey/:id/questions",
    exact: true,
  },
  {
    component: SurveyAnswers,
    path: "/survey/:surveyId/questions/:questionId/answers",
    exact: true,
  },
  {
    component: (props) => <SurveyView role={"manager"} editor={true} preview={true} />,
    path: "/course/:targetCourseId/survey/preview/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyView role={"manager"} editor={true} preview={true} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/preview/:id",
    exact: true,
  },
  {
    component: () => <AddCourse isProgram={true} role="manager" />,
    path: "/program/add",
    exact: true,
  },
  {
    component: () => <AddCourse programCourses={true} role="manager" />,
    path: "/program/:id/course/add",
    exact: true,
  },
  {
    component: () => <EditCourse isProgram={true} role="manager" />,
    path: "/program/edit/:id",
    exact: true,
  },
  {
    component: () => <EditCourse programCourses={true} role="manager" />,
    path: "/program/:id/course/edit/:courseId",
    exact: true,
  },
  {
    component: (props) => (
      <CourseTraineeStatement {...props} role={"manager"} />
    ),
    path: "/course/:courseId/trainees/:id",
    exact: true,
  },
  {
    component: () => <CoursesList role="manager" programCourses={true} />,
    path: "/program/:id/courses",
    exact: true,
  },
];

export default routesTrainer;
