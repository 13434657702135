import { useEffect, useState } from "react";
import TrainersDetailsHeader from "./TrainerDetailsHeader/TrainerDetailsHeader";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import SkeletonSpinnerOverlay from "../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainerCourse,
  getTrainerDetailsById,
  resetTrainer,
} from "../../Redux/Actions/trainerProfile/trainerProfile.action";
import Title from "../Shared/Components/Title/Title";
import classes from "./TrainersInfoDetails.module.css";
import TrainersCourseDetails from "./TrainersCourseDetails/TrainersCourseDetails";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";

function TrainersDetails() {
  require("./TrainersInfoDetails.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { get_trainer_details, get_trainer_course } = useSelector(
    (state) => state.trainerProfileReducer
  );
  const [trainerDetails, setTrainerDetails] = useState([]);
  const [trainerCourses, setTrainerCourses] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loadMoreButton, setLoadMoreButton] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(resetTrainer());
    setLoading(true);
    setTrainerCourses([]);
  }, []);

  useEffect(() => {
    let params = {
      perPage: 10,
      page: 1,
    };
    dispatch(getTrainerDetailsById(id));
    dispatch(getTrainerCourse(id, params));
  }, [id]);

  useEffect(() => {
    if (get_trainer_details.status === 200) {
      setTrainerDetails(get_trainer_details.data.response.trainer);
    }
  }, [get_trainer_details]);

  useEffect(() => {
    if (get_trainer_course.status === 200) {
      if (loadMoreButton) {
        setTrainerCourses((course) => [
          ...course,
          ...get_trainer_course.data.response.data,
        ]);
      } else {
        setLoading(true);
        setTrainerCourses(get_trainer_course.data.response.data);
      }
      setMeta(get_trainer_course.data.response.meta);
      setLoadMoreButton(false);
      setLoading(false);
    }
  }, [get_trainer_course]);

  const LoadMore = () => {
    setLoadMoreButton(true);
    let params = {
      perPage: 10,
      page: meta.currentPage + 1,
    };

    dispatch(getTrainerCourse(id, params, false));
  };

  return (
    <>
      {!loading ? (
        <>
          <TrainersDetailsHeader
            trainerDetails={trainerDetails}
            loading={loading}
          />
          {(trainerDetails.description_ar || trainerDetails.description_en) && (
            <div className={`${classes.description}`}>
              <div className={`container-fluid`}>
                <div className="row">
                  <div className="col-12">
                    <div>
                      <Title title={t("OverView")} fontSize={24} />
                      <ul className={classes["description-content"]}>
                        {loading ? (
                          <SkeletonSpinnerOverlay
                            backdrop={true}
                            skeletonWidth="30"
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language === Lang.AR
                                  ? trainerDetails &&
                                    trainerDetails.description_ar
                                  : trainerDetails &&
                                    trainerDetails.description_en,
                            }}
                          ></div>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="trainer_head_card">
                  <Title title={t("Coursesoffered")} fontSize={24} />
                </div>
                <TrainersCourseDetails
                  trainerCourses={trainerCourses}
                  loading={loading}
                />
                {meta.nextPage && (
                  <CustomButton
                    value={t("Exhibition.load_more")}
                    colors={"#26b3b9"}
                    type={"button"}
                    variant={"outlined"}
                    disable={loadMoreButton}
                    loading={loadMoreButton}
                    classes={classes.btn}
                    action={LoadMore}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}

export default TrainersDetails;
